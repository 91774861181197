import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { rrppClientErrors } from "../../classes/rrppClass";
import {
  allFieldsOk,
  handleImageSelect,
  transformObjectWithUrls,
} from "../../components/shared/FormsValidator";
import ImageUpload from "../../components/shared/ImageUpload";
import { focusColor } from "../../components/shared/textFieldStyle";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import { getOrganizations } from "../../services/organizationsServices";
import { createRrpp, getAllRrpp } from "../../services/rrppsServices";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import { ROLES } from "../../constants/variables";

const RrppForm = (props) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(props.formData);
  const [imageDisable, setImageDisable] = useState(props.imageDisable);
  const [userError, setUserError] = useState(rrppClientErrors);
  const navigate = useNavigate();
  const [buttonClicked, setButtonClicked] = useState(false);
  const url = window.location.href;
  const path = url.split("/").slice(4).join("/");
  const [isSending, setIsSending] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo?.roles;
  const isSuperAdmin = roles.find((element) => element === ROLES.SUPER_ADMIN);
  const [organizations, setOrganizations] = useState([]);
  const [emailExistsError, setEmailExistsError] = useState(false);
  const [rrpps, setRrpps] = useState();

  const handleQuerySuccess = (data) => {
    setRrpps(data);
  };

  useQuery(["getRrpp"], () => getAllRrpp(), {
    onSuccess: handleQuerySuccess,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (path === "create_rrpp") {
      getOrganizations()
        .then((data) => {
          setOrganizations(data);
        })
        .catch((error) => {
          console.error("Error al obtener datos:", error);
        });
    }
  }, []);

  const handleChangeOrganization = async (event) => {
    formData.organization_id = event.target.value;
    setUserError((prevUserError) => ({
      ...prevUserError,
      organization_id: "",
    }));
  };

  const setFormErrors = (_, field, value) => {
    let object = userError;
    object[field] = value;
  };
  const setFormParamsNotEvent = (_, field, value) => {
    let object = formData;
    object[field] = value;
    setFormData((_) => ({ ...object }));
  };
  const handleFieldChange = (event, regex, helperText) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    setFormData((prevUser) => ({
      ...prevUser,
      [fieldName]: fieldValue,
    }));
    if (regex) {
      setUserError((prevUserError) => ({
        ...prevUserError,
        [fieldName]: regex.test(fieldValue) ? "" : helperText,
      }));
    } else if (fieldName === "born_at") {
      setUserError((prevUserError) => ({
        ...prevUserError,
        [fieldName]: "",
      }));
    }
  };
  const formatDate = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];
    return formattedDate;
  };

  const { data: premises } = useQueryPremisesList();

  const handleSelectPremise = (event) => {
    const selectedPremisesIds = event.target.value;
    setFormData((prevUser) => ({
      ...prevUser,
      premises_ids: selectedPremisesIds,
    }));
    setUserError((prevUserError) => ({ ...prevUserError, premises_ids: "" }));
  };

  const renderTextField = (
    label,
    fieldName,
    value,
    errorProperty,
    helperText,
    regex,
    disabled,
    type
  ) => (
    <>
      <TextField
        sx={focusColor}
        margin="normal"
        required
        fullWidth
        id={fieldName}
        name={fieldName}
        autoComplete={fieldName}
        label={label}
        value={value}
        type={type ? type : "text"}
        onChange={(e) => handleFieldChange(e, regex, helperText)}
        error={
          (errorProperty !== "" && errorProperty !== "empty") ||
          (errorProperty === "empty" && buttonClicked)
        }
        helperText={errorProperty === "empty" || errorProperty === "" ? "" : helperText}
        disabled={path !== "create_rrpp" ? true : disabled}
      />
    </>
  );
  const handleCreateRrppSubmit = async (e) => {
    e.preventDefault();
    setButtonClicked(true);
    if (allFieldsOk(userError)) {
      const formDataToSend = await transformObjectWithUrls(formData);
      try {
        setIsSending(true);

        // Verificar si el correo existe
        const rrppWithEmailExists = rrpps.data.some((rrpp) => rrpp.email === formData.email);
        if (rrppWithEmailExists) {
          toastMessageError(t("USER_EXISTS"));
          setEmailExistsError(true);
          setIsSending(false);
          return;
        }

        const response = await createRrpp(formDataToSend);
        if (response === "success" && response !== undefined) {
          toastMessageSuccess(t("CREATE_RRPP_SUCCESS"));
          setTimeout(() => {
            setFormData("");
            navigate("/rrpps");
          }, 1500);
        }
      } catch (error) {
        setIsSending(false);
        toastMessageError(t("EDIT_ERROR") + error.response.data.error);
      }
    }
  };
  return (
    <>
      <Grid
        container
        sx={{
          mt: 5,
          mb: 20,
          px: 1,
          color: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid item xs={11} md={9} lg={7.5}>
          <Box
            component={Paper}
            elevation={3}
            sx={{
              py: 2,
              px: 3,
              border: "1px solid #E4E4E4",
              borderRadius: "15px",
            }}
          >
            <Box component="form" noValidate sx={{ mt: 2 }} onSubmit={handleCreateRrppSubmit}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Grid item xs={12} md={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("NAME")}</InputLabel>
                  {renderTextField(
                    t("NAME"),
                    "name",
                    formData.name,
                    userError.name,
                    t("MAX_50"),
                    /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{1,50}$/
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("SURNAME")}</InputLabel>
                  {renderTextField(
                    t("SURNAME"),
                    "surname",
                    formData.surname,
                    userError.surname,
                    t("MAX_50"),
                    /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{1,50}$/
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("ALIAS")}</InputLabel>
                  {renderTextField(
                    t("ALIAS"),
                    "alias",
                    formData.alias,
                    userError.alias,
                    t("MAX_50"),
                    /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\d-]).{1,50}$/
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("BORN_AT")}</InputLabel>
                  <TextField
                    disabled={path !== "create_rrpp" && true}
                    sx={focusColor}
                    margin="normal"
                    required
                    fullWidth
                    id={"born_at"}
                    name={"born_at"}
                    autoComplete={"born_at"}
                    value={
                      formData.born_at !== "" ? formatDate(formData.born_at) : formData.born_at
                    }
                    type="date"
                    onChange={(e) => handleFieldChange(e, null, t("INCORRECT"))}
                    error={
                      (userError.born_at !== "" && userError.born_at !== "empty") ||
                      (userError.born_at === "empty" && buttonClicked)
                    }
                    helperText={
                      userError.born_at === "empty" || userError.born_at === ""
                        ? ""
                        : t("INCORRECT")
                    }
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("PHONE")}</InputLabel>
                  {renderTextField(
                    t("PHONE"),
                    "phone",
                    formData.phone,
                    userError.phone,
                    t("INCORRECT"),
                    /^\d{7,10}$/
                  )}
                </Grid>
                <Grid item xs={12} md={12} mb={2}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>{t("EMAIL")}</InputLabel>
                  {renderTextField(
                    t("EMAIL"),
                    "email",
                    formData.email,
                    userError.email,
                    t("INCORRECT_FORMAT"),
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                  )}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("AVATAR")}
                  </InputLabel>
                </Grid>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Grid item xs={12} md={8}>
                    <ImageUpload
                      error={formData.avatar_url === "" && buttonClicked}
                      url={formData.avatar_url}
                      selectedImagesInForm={formData.avatar_url}
                      onImageSelect={(url) =>
                        handleImageSelect(
                          formData,
                          url,
                          "avatar_url",
                          setFormParamsNotEvent,
                          setFormErrors,
                          true
                        )
                      }
                      multi={false}
                      disabled={imageDisable}
                    />
                  </Grid>
                </Grid>
                {isSuperAdmin && (
                  <Grid item xs={12}>
                    <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                      {t("ENTITY")}
                    </InputLabel>
                    <FormControl fullWidth variant="outlined" sx={focusColor}>
                      <InputLabel>{t("ENTITY")}</InputLabel>
                      <Select
                        disabled={path !== "create_rrpp" && true}
                        labelId="organization_id"
                        id="organization_id"
                        value={formData.organization_id}
                        onChange={handleChangeOrganization}
                        label={t("ENTITY")}
                        MenuProps={{
                          sx: {
                            top: "-50px",
                            bottom: "50px",
                          },
                        }}
                      >
                        {organizations.map((organization) => (
                          <MenuItem key={organization.id} value={organization.id}>
                            {organization.legal_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} mt={2}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("SITE")}
                  </InputLabel>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={focusColor}
                    error={formData.premises_ids === ""}
                  >
                    <InputLabel>{t("SITE")}</InputLabel>
                    <Select
                      disabled={path !== "create_rrpp" && true}
                      multiple
                      sx={{ mb: 5 }}
                      labelId="premises"
                      id="premises"
                      label={t("SITE")}
                      value={formData.premises_ids ? formData.premises_ids : []}
                      onChange={handleSelectPremise}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        sx: {
                          "&& .Mui-selected": {
                            backgroundColor: " #cceceb",
                          },
                          top: "-50px",
                          bottom: "50px",
                        },
                      }}
                      renderValue={(selected) =>
                        selected
                          .map((value) => premises?.find((premise) => premise.id === value)?.name)
                          .join(", ")
                      }
                    >
                      {premises?.map((premise) => (
                        <MenuItem key={premise.id} value={premise.id}>
                          <ListItemText primary={premise.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {!allFieldsOk(userError) && buttonClicked && (
                <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("REQUIRED_FIELD")}</Typography>
              )}
              {path === "create_rrpp" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    pt: 2,
                  }}
                >
                  {isSending ? (
                    <Grid container justifyContent="center">
                      <CircularProgress sx={{ color: "var(--secondary-color)" }} />
                    </Grid>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          setFormData("");
                          navigate("/rrpps");
                        }}
                        className="oniria-btn-cancel"
                        fullWidth
                        variant="contained"
                        sx={{ m: 2, fontSize: "20px", borderRadius: 3 }}
                      >
                        {" "}
                        {t("CANCEL")}{" "}
                      </Button>
                      <Button
                        type="submit"
                        className="oniria-btn"
                        fullWidth
                        variant="contained"
                        sx={{ m: 2, fontSize: "20px", borderRadius: 3 }}
                      >
                        {t("SAVE")}
                      </Button>
                    </>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default RrppForm;
