import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Header from "../../Header";
import { useParams } from "react-router-dom";
import usePagination from "../../../hooks/usePagination";
import { useQuery } from "react-query";
import { getUsersAdmins } from "../../../services/adminsServices";
import useDebounce from "../../../hooks/useDebouncing";
import { useEffect, useState } from "react";
import { focusColor } from "../../shared/textFieldStyle";
import SelectPerPage from "../../shared/SelectPerPage";
import { Loading } from "../../shared/Loading";
import {
  getDomainEditors,
  givePermissions,
  removePermissions,
} from "../../../services/domainsServices";
import CustomDialog from "../../shared/CustomDialog";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";
import r from "@editorjs/raw";
import { useQueryPremisesList } from "../../../hooks/queries/useQueryPremisesList";
import { useSelector } from "react-redux";

const DomainAdmins = () => {
  const { t } = useTranslation();
  const { domainId } = useParams();
  const { page, perPage, changePage, changePerPage } = usePagination();
  const [search, setSearch] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openPut, setOpenPut] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedPremise, setSelectedPremise] = useState("all");
  const [grouping, setGrouping] = useState(null);
  const [organization, setOrganization] = useState(null);
  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (userInfo.grouping_id) {
      setGrouping(userInfo.grouping_id);
    } else if (userInfo.organization_id) {
      setOrganization(userInfo.organization_id);
    }
  }, []);

  const breadcrumbs = [
    {
      name: t("DOMAINS_MANAGER"),
      link: "/domains",
    },
    {
      name: t("STYLES"),
      link: `/styles/${domainId}`,
    },
    {
      name: t("PERMISSIONS"),
    },
  ];

  const { data: premises } = useQueryPremisesList(organization, grouping);
  const handlePremiseChange = (event) => {
    if (event && event.target) {
      setSelectedPremise(event.target.value);
      changePage(null, 1);
    }
  };

  const {
    isLoading,
    isRefetching,
    refetch: refetchAdmins,
    data: adminsData,
  } = useQuery(
    ["admins", page, perPage, search, selectedPremise],
    () => getUsersAdmins(page, perPage, search, selectedPremise, true),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: domainEditors, refetch: refetchEditors } = useQuery(
    ["domainEditors", domainId],
    () => getDomainEditors(domainId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleSearchChange = (value) => {
    setSearch(value);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const totalPages = Math.ceil(Number(adminsData?.total ?? 0) / Number(adminsData?.perPage ?? 10));

  const handleOpenDelete = (e, userId) => {
    e.preventDefault();
    setSelectedUserId(userId);
    setOpenDelete(true);
  };

  const handleDeletePermissions = async (e) => {
    e.preventDefault();
    try {
      await removePermissions(selectedUserId, domainId);
      toastMessageSuccess(t("PERMISSIONS_DELETED_SUCCESSFULLY"));
    } catch (error) {
      console.log("error", error);
      toastMessageError(error.response.data.error ?? t("ERROR_DELETING_PERMISSIONS"));
    } finally {
      setOpenDelete(false);
      refetchAdmins();
      refetchEditors();
    }
  };

  const handlePutPermissions = async (e) => {
    e.preventDefault();
    try {
      await givePermissions(selectedUserId, domainId);
      toastMessageSuccess(t("PERMISSIONS_GIVEN_SUCCESSFULLY"));
    } catch (error) {
      console.log("error", error);
      toastMessageError(error.response.data.error ?? t("ERROR_GIVING_PERMISSIONS"));
    } finally {
      setOpenPut(false);
      refetchAdmins();
      refetchEditors();
    }
  };

  const handleOpenPut = (e, userId) => {
    e.preventDefault();
    setSelectedUserId(userId);
    setOpenPut(true);
  };

  return (
    <Grid mb={20}>
      <Header breadcrumbs={breadcrumbs} description={t("DOMAINS_EDITORS_DESCRIPTION")} />
      <Grid
        container
        spacing={1}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mt: 3 }}
      >
        <Grid item xs={11} sm={7} md={6} sx={{ mb: 2, display: "flex", flexDirection: "row" }}>
          <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
          <TextField
            label={t("SEARCH")}
            onChange={(event) => debounceSearch(event?.target?.value)}
            size="small"
            sx={[{ width: "75%" }, focusColor]}
          />
        </Grid>
        <Grid
          item
          container
          xs={11}
          sm={11}
          md={6}
          justifyContent={{ xs: "flex-start", md: "flex-end" }}
          mb={1}
        >
          <FormControl sx={[focusColor, { minWidth: 120, mr: 2 }]} size="small">
            <Select
              value={selectedPremise}
              onChange={handlePremiseChange}
              sx={{ fontSize: "12px", borderRadius: 3 }}
            >
              <MenuItem value="all">{t("ALL_SITES")}</MenuItem>
              {premises?.map((premise) => (
                <MenuItem key={premise.id} value={premise.id}>
                  {premise.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2, mt: 2 }} container justifyContent="center">
        <Grid item xs={12} md={4} container alignItems="center">
          <Typography>
            {t("TOTAL_ADMINS")}: {adminsData?.total ?? 0}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} container justifyContent="flex-end">
          <Grid item>
            <SelectPerPage text={t("USERS")} change={changePerPage} value={perPage} />
          </Grid>
          <Pagination
            count={totalPages}
            page={page}
            onChange={changePage}
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bolder",
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": {
                  background: "#3D3B38",
                  color: "#fff",
                  fontWeight: "bolder",
                },
                fontWeight: "600",
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mb={25}>
        {isLoading || isRefetching ? (
          <Loading />
        ) : adminsData && adminsData.data.length > 0 ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                    <TableCell>{t("NAME")}</TableCell>
                    <TableCell>{t("SURNAME")}</TableCell>
                    <TableCell>{t("EMAIL")}</TableCell>
                    <TableCell>{t("SITE")}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminsData.data?.map((admin, index) => (
                    <TableRow key={index}>
                      <TableCell>{admin.user_name}</TableCell>
                      <TableCell>{admin.user_surname}</TableCell>
                      <TableCell>{admin.user_email}</TableCell>
                      <TableCell>{admin.premise_name}</TableCell>
                      <TableCell>
                        {domainEditors?.find((editor) => editor.id === admin.user_id) ? (
                          <Tooltip title={t("DELETE_PERMISSIONS")} placement="top" arrow>
                            <IconButton
                              onClick={(e) => handleOpenDelete(e, admin.user_id)}
                              className="oniria-icon-btn-delete"
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title={t("GIVE_PERMISSIONS")} placement="top" arrow>
                            <IconButton
                              onClick={(e) => handleOpenPut(e, admin.user_id)}
                              className="oniria-icon-btn"
                            >
                              <AddModeratorIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid mt={3}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={changePage}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bolder",
                  "& .MuiPaginationItem-root": {
                    "&.Mui-selected": {
                      background: "#3D3B38",
                      color: "#fff",
                      fontWeight: "bolder",
                    },
                    fontWeight: "600",
                  },
                }}
              />
            </Grid>
          </>
        ) : (
          <Typography sx={{ textAlign: "center", fontWeight: "bolder", fontSize: "20px" }}>
            {t("NO_ADMINS")}
          </Typography>
        )}
      </Grid>
      <CustomDialog
        isOpen={openDelete}
        onClose={() => setOpenDelete(false)}
        title={t("DELETE_PERMISSIONS")}
        content={t("DELETE_PERMISSIONS_TEXT")}
        onAccept={handleDeletePermissions}
      />
      <CustomDialog
        isOpen={openPut}
        onClose={() => setOpenPut(false)}
        title={t("GIVE_PERMISSIONS")}
        content={t("PUT_PERMISSIONS_TEXT")}
        onAccept={handlePutPermissions}
      />
    </Grid>
  );
};

export default DomainAdmins;
