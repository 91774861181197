import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useQueryCategoriesPremiseDomain } from "../../../hooks/queries/useQueryCategoriesPremiseDomain";
import { useQueryPremisesList } from "../../../hooks/queries/useQueryPremisesList";
import { getCategoryPremise } from "../../../services/categoriesServices";
import ButtonsForms from "../../account/forms/ButtonsForms";
import {
  allFieldsOk,
  checkField,
  checkFieldValue,
  handleImageSelect,
} from "../../shared/FormsValidator";
import ImageUpload from "../../shared/ImageUpload";
import InfoToolTip from "../../shared/InfoToolTip";
import MapContent from "../../shared/Map/LeafletMap";
import { focusColor } from "../../shared/textFieldStyle";
import TextEditor from "../../textEditor/TextEditor";
import { checkRenderEditorJs } from "../../textEditor/tools";
import DoublePaymentInfo from "../DoublePaymentInfo";
import CategorySelect from "./CategoriesSelect";
import EventDates from "./EventDates";

const Details = (props) => {
  const { t } = useTranslation();
  const formController = props.formController;
  const { details: trip } = formController.formData;
  const { formParams, setFormErrors, setFormData } = formController;
  const { details: detailsErrors } = formController.validation;
  const setFormParamsNotEvent = formController.setFormParamsNotEvent;
  const editEvent = props.editEvent;
  //const [eventTypes, setEventTypes] = useState([]);
  const [premiseFilter, setPremiseFilter] = useState("");
  const { buttonClicked, setButtonClicked } = props;

  const { pathname } = useLocation();

  const typeTrip = props.typeTrip;
  // to set actual date and time when create event
  var offset = new Date().getTimezoneOffset() * 60000;
  var localISOTime = new Date(Date.now() - offset);

  const now = localISOTime;
  const [hours, minutes] = now.toISOString().split("T")[1].split(":");
  const time = `${hours}:${minutes}`;
  useEffect(() => {
    if (trip.start_sale_date === "") {
      trip.start_sale_date = now.toISOString().split("T")[0];
      trip.start_sale_date_time = time;
      trip.publication_date = now.toISOString().split("T")[0];
      trip.publication_date_time = time;
      setFormErrors("details", "start_sale_date", "");
      setFormErrors("details", "start_sale_date_time", "");
      setFormErrors("details", "publication_date", "");
      setFormErrors("details", "publication_date_time", "");
    }
  }, []);

  // to set next button form clicked
  const catchButtonClicked = (value) => {
    setButtonClicked(value);
  };

  const { data: premises } = useQueryPremisesList();

  // useQuery(["eventTypes", premiseFilter], () => getCategoryPremise(premiseFilter), {
  //   refetchOnWindowFocus: false,
  //   enabled: !!premiseFilter,
  //   onSuccess: (data) => {
  //     setEventTypes(data);
  //   },
  // });

  const { data: domainCategories } = useQueryCategoriesPremiseDomain(premiseFilter);

  const menuItems = premises?.map((premise) => (
    <MenuItem key={premise.id} value={premise.id}>
      {premise.name}
    </MenuItem>
  ));
  const handleChangeFilterPremise = (event) => {
    setPremiseFilter(event.target.value);
    checkField(
      "details",
      "premise_id",
      event,
      /.*/,
      t("CHOOSE_OPTION"),
      false,
      formParams,
      setFormErrors
    );
  };

  const checkDateField = (
    classForm,
    key,
    e,
    regex,
    errorMessage,
    canBeEmpty,
    formParams,
    setErrors
  ) => {
    //
    let errorValue = "";
    //
    const now = new Date();
    const dateToCheck = new Date(e.target.value);
    //console.log("Checking date field: ", key, now, dateToCheck)
    //
    if (dateToCheck.getDay < now.getDay) {
      errorValue = t("PREVIOUS_DATE_ERROR");
    }
    // Checkeos propios de fechas
    switch (key) {
      case "end_date":
        const startDate = new Date(trip.start_date);
        if (dateToCheck < startDate) errorValue = t("DATE_START_ERROR");
        if (trip.end_sale_date === "") {
          trip.end_sale_date = dateToCheck.toISOString().split("T")[0];
          trip.end_of_publication_date = dateToCheck.toISOString().split("T")[0];
          setErrors(classForm, "end_of_publication_date", errorValue);
          setErrors(classForm, "end_sale_date", errorValue);
        }
        break;
      case "end_sale_date":
        const endDate1 = new Date(trip.end_date);
        const startSaleDate = new Date(trip.start_sale_date);
        if (dateToCheck > endDate1) errorValue = t("DATE_END_ERROR");
        if (dateToCheck < startSaleDate) errorValue = t("DATE_SALE_ERROR");
        break;
      case "end_of_publication_date":
        const endDate2 = new Date(trip.end_date);
        const startPublicationDate = new Date(trip.publication_date);
        if (dateToCheck > endDate2) errorValue = t("DATE_END_ERROR");
        if (dateToCheck < startPublicationDate) errorValue = t("PUBLICATION_DATE_ERROR");
        break;
      case "second_payment_start_date":
        const startSaleDates = new Date(trip.start_sale_date);
        if (dateToCheck < startSaleDates) errorValue = t("SECOND_DATE_START_ERROR");
        break;
      case "second_payment_end_date":
        const startSecondDate = new Date(trip.second_payment_start_date);
        if (dateToCheck < startSecondDate) errorValue = t("SECOND_DATE_END_ERROR");
        break;
      default:
        break;
    }
    // Checkeos normales de campo
    setErrors(classForm, key, errorValue);
    formParams(classForm, key, e);
  };

  //check hour and put in end_sale_date_time and end_of_publication_date_time automatically
  const checkHourField = (
    classForm,
    key,
    e,
    regex,
    errorMessage,
    canBeEmpty,
    formParams,
    setErrors,
    parameter = "value"
  ) => {
    let errorValue = "";
    const newValue = e.target[parameter];
    if (newValue === "" && !canBeEmpty) {
      errorValue = "empty";
    } else if (regex.test(newValue)) {
      errorValue = "";
    } else {
      errorValue = errorMessage;
    }
    setErrors(classForm, key, errorValue);
    if (trip.end_sale_date_time === "") {
      trip.end_sale_date_time = newValue;
      trip.end_of_publication_date_time = newValue;
      setErrors(classForm, "end_of_publication_date_time", errorValue);
      setErrors(classForm, "end_sale_date_time", errorValue);
    }
    formParams(classForm, key, e, parameter);
  };

  const handleIncrement = () => {
    trip.min_age = trip.min_age + 1;
  };

  const handleDecrement = () => {
    trip.min_age > 0 ? (trip.min_age = trip.min_age - 1) : (trip.min_age = 0);
  };

  const isDisabled = () => {
    return props.isDisabled ? props.isDisabled : false;
  };

  //Set premise_name when edit event
  useEffect(() => {
    if (trip.premise_id !== "" && premises?.length > 0) {
      setPremiseFilter(trip.premise_id);
    }
  }, [trip.premise_id, premises]);

  //check si la sede seleccionada es internacional
  const isInternational = () => {
    const premiseFound = premises?.find((premise) => premise.id === premiseFilter);
    return premiseFound ? premiseFound.is_active === 1 : false;
  };

  const handleChangeDescription = (data) => {
    checkFieldValue(
      "details",
      "description",
      data,
      /^.[\s\S]{0,5000000}$/,
      t("MAX_5000"),
      true,
      setFormParamsNotEvent,
      setFormErrors
    );
  };

  const handleChangeLatLong = (lat, lng) => {
    checkFieldValue(
      "details",
      "latitude",
      lat,
      /^[-0-9]+(\.[-0-9]+)?$/,
      t("INCORRECT"),
      true,
      setFormParamsNotEvent,
      setFormErrors
    );
    checkFieldValue(
      "details",
      "longitude",
      lng,
      /^[-0-9]+(\.[-0-9]+)?$/,
      t("INCORRECT"),
      true,
      setFormParamsNotEvent,
      setFormErrors
    );
  };

  return (
    <Grid
      container
      sx={{
        mt: 5,
        mb: 20,
        px: 1,
        color: "black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid item xs={11} md={8}>
        <Box
          component={Paper}
          elevation={3}
          sx={{
            py: 2,
            px: 3,
            border: "1px solid #E4E4E4",
            borderRadius: "15px",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid item xs={11}>
                <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                  {typeTrip ? t("TRIP_NAME") : t("EVENT_NAME")}
                </InputLabel>
                <TextField
                  onChange={(e) =>
                    checkField(
                      "details",
                      "name",
                      e,
                      /^.{1,50}$/,
                      t("MAX_50"),
                      false,
                      formParams,
                      setFormErrors
                    )
                  }
                  margin="normal"
                  required
                  fullWidth
                  value={trip.name}
                  id="name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  label={typeTrip ? t("TRIP_NAME") : t("EVENT_NAME")}
                  error={
                    (detailsErrors.name !== "" && detailsErrors.name !== "empty") ||
                    (detailsErrors.name === "empty" && buttonClicked)
                  }
                  helperText={detailsErrors.name !== "empty" ? detailsErrors.name : ""}
                  disabled={isDisabled()}
                  sx={focusColor}
                />
                <TextField
                  onChange={(e) =>
                    checkField(
                      "details",
                      "subtitle",
                      e,
                      /^.{0,50}$/,
                      t("MAX_50"),
                      true,
                      formParams,
                      setFormErrors
                    )
                  }
                  value={trip.subtitle}
                  margin="normal"
                  fullWidth
                  id="subtitle"
                  name="subtitle"
                  label={t("SUBTITLE")}
                  autoComplete="subtitle"
                  error={detailsErrors.subtitle !== ""}
                  helperText={detailsErrors.subtitle}
                  disabled={isDisabled()}
                  sx={[focusColor, { mb: 2 }]}
                />
                <FormControl
                  fullWidth
                  sx={[focusColor, { mb: 2 }]}
                  error={
                    (detailsErrors.premise_id === "empty" && buttonClicked) ||
                    (detailsErrors.premise_id !== "" && detailsErrors.premise_id !== "empty")
                  }
                >
                  <InputLabel id="demo-select-small-label">{t("PREMISE_ORGANIZER")}</InputLabel>
                  <Select
                    value={premiseFilter}
                    onChange={handleChangeFilterPremise}
                    sx={{ fontSize: "16px" }}
                    label={t("PREMISE_ORGANIZER")}
                  >
                    {menuItems}
                  </Select>
                </FormControl>
                {/* CATEGORIES */}
                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                  <div
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      display: "flex",
                    }}
                  >
                    {t("CATEGORIES")}
                    <InfoToolTip text={t("CATEGORIES_DESCRIPTION")} />
                  </div>
                </InputLabel>
                <CategorySelect
                  trip={trip}
                  domainCategories={domainCategories}
                  //eventTypes={eventTypes}
                  setFormData={setFormData}
                  buttonClicked={buttonClicked}
                  detailsErrors={detailsErrors}
                />
                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                    <div
                      style={{
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        display: "flex",
                      }}
                    >
                      {t("SHORT_DESCRIPTION_TEXT")}
                      <InfoToolTip text={t("EVENT_CARD_SHORT_DESCRIPTION")} />
                    </div>
                  </InputLabel>
                  <TextField
                    onChange={(e) =>
                      checkField(
                        "details",
                        "short_description",
                        e,
                        /^.[\s\S]{0,80}$/,
                        t("MAX_80"),
                        false,
                        formParams,
                        setFormErrors
                      )
                    }
                    value={trip.short_description}
                    margin="normal"
                    required
                    multiline
                    fullWidth
                    id="short_description"
                    name="short_description"
                    autoComplete="short_description"
                    error={
                      (detailsErrors.short_description !== "" &&
                        detailsErrors.short_description !== "empty") ||
                      (detailsErrors.short_description === "empty" && buttonClicked)
                    }
                    placeholder={t("SHORT_DESCRIPTION")}
                    helperText={
                      detailsErrors.short_description !== "empty"
                        ? detailsErrors.short_description
                        : ""
                    }
                    disabled={isDisabled()}
                    sx={focusColor}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {typeTrip ? t("DESCRIPTION_TRIP") : t("DESCRIPTION_EVENT")}
                  </InputLabel>
                  {/* EDITOR */}
                  {checkRenderEditorJs(pathname, trip.description) ? (
                    <TextEditor
                      data={trip.description}
                      setData={handleChangeDescription}
                      id="description"
                    />
                  ) : (
                    <CKEditor
                      editor={ClassicEditor}
                      data={trip.description}
                      onChange={(_, editor) => {
                        const data = editor.getData();
                        handleChangeDescription(data);
                      }}
                      config={{
                        removePlugins: [
                          "CKFinderUploadAdapter",
                          "CKFinder",
                          "EasyImage",
                          "Image",
                          "ImageCaption",
                          "ImageStyle",
                          "ImageToolbar",
                          "ImageUpload",
                          "MediaEmbed",
                        ],
                      }}
                    />
                  )}
                  {detailsErrors.description !== "" && buttonClicked && (
                    <Typography sx={{ color: "#d32f2f" }}>*{t("REQUIRED_FIELD")}</Typography>
                  )}
                </Grid>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Grid item xs={12} lg={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black", mb: 1, pb: 1 }}>
                      <div
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          display: "flex",
                        }}
                      >
                        {typeTrip ? t("TRIP_PRINCIPAL_IMAGE") : t("EVENT_PRINCIPAL_IMAGE")}
                        <InfoToolTip
                          text={typeTrip ? t("MAIN_TRIP_IMAGE") : t("MAIN_EVENT_IMAGE")}
                        />
                      </div>
                    </InputLabel>
                    <ImageUpload
                      url={trip.principal_url}
                      selectedImagesInForm={trip.principal_url}
                      onImageSelect={(url) =>
                        handleImageSelect(
                          "details",
                          url,
                          "principal_url",
                          setFormParamsNotEvent,
                          setFormErrors
                        )
                      }
                      error={detailsErrors.principal_url !== "" && buttonClicked}
                      editEvent={editEvent}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel
                      sx={{
                        fontSize: "18px",
                        color: "black",
                        paddingBottom: 2,
                        ml: 2,
                      }}
                    >
                      {typeTrip ? t("TRIP_IMAGES") : t("EVENT_IMAGES")}
                    </InputLabel>
                    <ImageUpload
                      url={trip.slider_urls}
                      selectedImagesInForm={trip.slider_urls}
                      onImageSelect={(url) =>
                        handleImageSelect(
                          "details",
                          url,
                          "slider_urls",
                          setFormParamsNotEvent,
                          setFormErrors
                        )
                      }
                      multi={typeTrip ? true : false}
                      editEvent={editEvent}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("LOCATION_ADDRESS")}
                    <InfoToolTip text={t("INFO_MAP_EVENT")} />
                  </InputLabel>
                  <TextField
                    onChange={(e) =>
                      checkField(
                        "details",
                        "location",
                        e,
                        /^(?!.*[|\\#·$~%&¬()^[\]+*{}_]).{1,250}$/,
                        t("MAX_250"),
                        false,
                        formParams,
                        setFormErrors
                      )
                    }
                    value={trip.location}
                    margin="normal"
                    required
                    fullWidth
                    id="location"
                    name="location"
                    label={t("LOCATION")}
                    autoComplete="location"
                    error={
                      (detailsErrors.location !== "" && detailsErrors.location !== "empty") ||
                      (detailsErrors.location === "empty" && buttonClicked)
                    }
                    helperText={detailsErrors.location !== "empty" ? detailsErrors.location : ""}
                    disabled={isDisabled()}
                    sx={focusColor}
                  />
                  <Grid sx={{ height: "250px", width: "100%" }}>
                    <MapContent
                      marker
                      handleChangeLatLong={handleChangeLatLong}
                      isDisabled={isDisabled()}
                      lat={trip.latitude}
                      lng={trip.longitude}
                    />
                  </Grid>
                </Grid>
                <EventDates
                  checkDateField={checkDateField}
                  formParams={formParams}
                  setFormErrors={setFormErrors}
                  trip={trip}
                  detailsErrors={detailsErrors}
                  buttonClicked={buttonClicked}
                  isDisabled={isDisabled}
                  checkField={checkField}
                  checkHourField={checkHourField}
                />
                <Grid container columnSpacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} md={6}>
                    <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2, mt: 1.8 }}>
                      {t("MIN_AGE")}
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkField(
                          "details",
                          "min_age",
                          e,
                          /^(?:100|[1-9][0-9]?|0)$/,
                          t("INCORRECT"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      value={trip.min_age}
                      margin="normal"
                      type="number"
                      required
                      fullWidth
                      id="min_age"
                      name="min_age"
                      label={t("AGE")}
                      autoComplete="min_age"
                      error={
                        (detailsErrors.min_age !== "" && detailsErrors.min_age !== "empty") ||
                        (detailsErrors.min_age === "empty" && buttonClicked)
                      }
                      helperText={detailsErrors.min_age !== "empty" ? detailsErrors.min_age : ""}
                      disabled={isDisabled()}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleIncrement} edge="end" />
                            <IconButton onClick={handleDecrement} edge="end" />
                          </InputAdornment>
                        ),
                      }}
                      sx={focusColor}
                      onWheel={(e) => e.target.blur()}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel
                      sx={{
                        fontSize: "18px",
                        color: "black",
                        mb: 2,
                        mt: 1.8,
                        whiteSpace: "unset",
                      }}
                    >
                      {t("LIMIT_TICKETS_ORDER")}
                    </InputLabel>
                    <TextField
                      onChange={(e) =>
                        checkField(
                          "details",
                          "limit_tickets_order",
                          e,
                          /^[1-9]\d*$/,
                          t("INCORRECT"),
                          false,
                          formParams,
                          setFormErrors
                        )
                      }
                      value={trip.limit_tickets_order}
                      margin="normal"
                      type="number"
                      required
                      fullWidth
                      id="limit_tickets_order"
                      name="limit_tickets_order"
                      label={t("LIMIT_TICKETS_ORDER")}
                      autoComplete="limit_tickets_order"
                      error={
                        (detailsErrors.limit_tickets_order !== "" &&
                          detailsErrors.limit_tickets_order !== "empty") ||
                        (detailsErrors.limit_tickets_order === "empty" && buttonClicked)
                      }
                      helperText={
                        detailsErrors.limit_tickets_order !== "empty"
                          ? detailsErrors.limit_tickets_order
                          : ""
                      }
                      disabled={isDisabled()}
                      sx={focusColor}
                      onWheel={(e) => e.target.blur()}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel
                    sx={{
                      fontSize: "18px",
                      color: "black",
                      mb: 2,
                      mt: 1.8,
                      whiteSpace: "unset",
                    }}
                  >
                    {t("OUTFIT")}
                  </InputLabel>
                  <TextField
                    onChange={(e) =>
                      checkField(
                        "details",
                        "outfit",
                        e,
                        /^(?!.*[|\\#·$~%¬=^[\]*{}_-]).{0,250}$/s,
                        t("MAX_250"),
                        true,
                        formParams,
                        setFormErrors
                      )
                    }
                    value={trip.outfit}
                    margin="normal"
                    multiline
                    rows={2}
                    fullWidth
                    id="outfit"
                    name="outfit"
                    label={t("OUTFIT")}
                    autoComplete="outfit"
                    error={
                      (detailsErrors.outfit !== "" && detailsErrors.outfit !== "empty") ||
                      (detailsErrors.outfit === "empty" && buttonClicked)
                    }
                    helperText={detailsErrors.outfit !== "empty" ? detailsErrors.outfit : ""}
                    disabled={isDisabled()}
                    sx={focusColor}
                  />
                </Grid>
                {typeTrip && (
                  <Grid container columnSpacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12} md={6}>
                      <InputLabel
                        sx={{
                          fontSize: "18px",
                          color: "black",
                          mt: 1.8,
                          whiteSpace: "unset",
                        }}
                      >
                        {t("NUMBER_COUNTRIES")}
                      </InputLabel>
                      <TextField
                        onChange={(e) =>
                          checkField(
                            "details",
                            "countries",
                            e,
                            /^[1-9]\d*$/,
                            t("INCORRECT"),
                            true,
                            formParams,
                            setFormErrors
                          )
                        }
                        value={trip.countries}
                        margin="normal"
                        type="number"
                        fullWidth
                        id="countries"
                        name="countries"
                        label={t("COUNTRIES")}
                        autoComplete="countries"
                        error={
                          (detailsErrors.countries !== "" && detailsErrors.countries !== "empty") ||
                          (detailsErrors.countries === "empty" && buttonClicked)
                        }
                        helperText={
                          detailsErrors.countries !== "empty" ? detailsErrors.countries : ""
                        }
                        disabled={isDisabled()}
                        sx={focusColor}
                        onWheel={(e) => e.target.blur()}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel
                        sx={{
                          fontSize: "18px",
                          color: "black",
                          mt: 1.8,
                          whiteSpace: "unset",
                        }}
                      >
                        {t("NUMBER_CITIES")}
                      </InputLabel>
                      <TextField
                        onChange={(e) =>
                          checkField(
                            "details",
                            "cities",
                            e,
                            /^[1-9]\d*$/,
                            t("INCORRECT"),
                            true,
                            formParams,
                            setFormErrors
                          )
                        }
                        value={trip.cities}
                        margin="normal"
                        type="number"
                        fullWidth
                        id="cities"
                        name="cities"
                        label={t("CITIES")}
                        autoComplete="cities"
                        error={
                          (detailsErrors.cities !== "" && detailsErrors.cities !== "empty") ||
                          (detailsErrors.cities === "empty" && buttonClicked)
                        }
                        helperText={detailsErrors.cities !== "empty" ? detailsErrors.cities : ""}
                        disabled={isDisabled()}
                        sx={focusColor}
                        onWheel={(e) => e.target.blur()}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InputLabel
                        sx={{
                          fontSize: "18px",
                          color: "black",
                          mt: 1.8,
                          whiteSpace: "unset",
                        }}
                      >
                        {t("RATING")}
                      </InputLabel>
                      <TextField
                        onChange={(e) => {
                          checkField(
                            "details",
                            "rating",
                            e,
                            /(^$|^[0-5](\.[05])?$)/,
                            t("INCORRECT_RATING"),
                            true,
                            formParams,
                            setFormErrors
                          );
                        }}
                        value={trip.rating}
                        margin="normal"
                        type="number"
                        fullWidth
                        id="rating"
                        name="rating"
                        label={t("RATING")}
                        autoComplete="rating"
                        error={
                          (detailsErrors.rating !== "" && detailsErrors.rating !== "empty") ||
                          (detailsErrors.rating === "empty" && buttonClicked)
                        }
                        helperText={detailsErrors.rating !== "empty" ? detailsErrors.rating : ""}
                        disabled={isDisabled()}
                        sx={focusColor}
                        onWheel={(e) => e.target.blur()}
                        inputProps={{ step: 0.5, min: 0, max: 5 }}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_visible"
                        checked={trip.is_visible}
                        onChange={(e) =>
                          checkField(
                            "details",
                            "is_visible",
                            e,
                            /^(true|false)$/,
                            t("CHOOSE_OPTION"),
                            true,
                            formParams,
                            setFormErrors,
                            "checked"
                          )
                        }
                        disabled={isDisabled()}
                        value={trip.is_visible}
                        sx={{
                          color: "var(--secondary-color)",
                          "&.Mui-checked": {
                            color: "var(--secondary-color)",
                          },
                        }}
                      />
                    }
                    label={typeTrip ? t("VISIBLE_TRIP") : t("VISIBLE_EVENT")}
                  />
                  <InfoToolTip text={t("VISIBLE_EVENT_INFO")} />
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="autovalidation"
                        checked={trip.autovalidation}
                        onChange={(e) =>
                          checkField(
                            "details",
                            "autovalidation",
                            e,
                            /^(true|false)$/,
                            t("CHOOSE_OPTION"),
                            true,
                            formParams,
                            setFormErrors,
                            "checked"
                          )
                        }
                        disabled={isDisabled()}
                        value={trip.autovalidation}
                        sx={{
                          color: "var(--secondary-color)",
                          "&.Mui-checked": {
                            color: "var(--secondary-color)",
                          },
                        }}
                      />
                    }
                    label={t("SELFT_VALIDATION")}
                  />
                  <InfoToolTip text={t("SELFT_VALIDATION_INFO")} />
                </Grid>
                {isInternational() && (
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="is_international"
                          checked={trip.is_international}
                          onChange={(e) =>
                            checkField(
                              "details",
                              "is_international",
                              e,
                              /^(true|false)$/,
                              t("CHOOSE_OPTION"),
                              true,
                              formParams,
                              setFormErrors,
                              "checked"
                            )
                          }
                          disabled={isDisabled()}
                          value={trip.is_international}
                          sx={{
                            color: "var(--secondary-color)",
                            "&.Mui-checked": {
                              color: "var(--secondary-color)",
                            },
                          }}
                        />
                      }
                      label={t("IS_INTERNATIONAL")}
                    />
                    <InfoToolTip text={t("IS_INTERNATIONAL_INFO")} />
                  </Grid>
                )}
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="has_double_payment"
                        checked={
                          trip.has_double_payment === 1 ||
                          trip.has_double_payment === "1" ||
                          trip.has_double_payment === true
                        }
                        onChange={(e) =>
                          checkField(
                            "details",
                            "has_double_payment",
                            e,
                            /^(true|false)$/,
                            t("CHOOSE_OPTION"),
                            true,
                            formParams,
                            setFormErrors,
                            "checked"
                          )
                        }
                        disabled={isDisabled()}
                        value={trip.has_double_payment}
                        sx={{
                          color: "var(--secondary-color)",
                          "&.Mui-checked": { color: "var(--secondary-color)" },
                        }}
                      />
                    }
                    label={t("IS_DOUBLE_PAYMENT")}
                  />
                  <Typography>*{t("IS_DOUBLE_PAYMENT_INFO")}</Typography>
                </Grid>
                {trip.has_double_payment && (
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <DoublePaymentInfo
                      isDisabled={isDisabled}
                      data={trip}
                      checkField={checkField}
                      checkDateField={checkDateField}
                      formParams={formParams}
                      setFormErrors={setFormErrors}
                      detailsErrors={detailsErrors}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
          {!allFieldsOk(detailsErrors) && buttonClicked && (
            <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("REQUIRED_FIELD")}</Typography>
          )}
          {!isDisabled() && (
            <ButtonsForms
              formController={formController}
              allFieldsOk={allFieldsOk}
              errors={detailsErrors}
              sendButtonClicked={catchButtonClicked}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Details;
