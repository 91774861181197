import CloseIcon from "@mui/icons-material/Close";
import { Box, Checkbox, FormControlLabel, Grid, IconButton, Paper, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { extraErrorsAllOk } from "../../classes/tripClass";
import InfoToolTip from "../shared/InfoToolTip";
import { focusColor } from "../shared/textFieldStyle";

const ExtraCard = (props) => {
  const { t } = useTranslation();
  const formController = props.formController;
  const extras = formController.formData.extras;
  const { index, errors, checkExtraField, removeExtra, sendButtonClicked } = props;

  if (!errors[index]) errors[index] = extraErrorsAllOk;

  const isDisabled = () => {
    return props.isDisabled ? props.isDisabled : false;
  };

  return (
    <Box
      component={Paper}
      elevation={0}
      sx={{
        py: 2,
        px: 3,
        mb: 2,
        border: "1px solid #E4E4E4",
        borderRadius: "15px",
      }}
    >
      <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton onClick={() => removeExtra(index)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
          <Grid item xs={12} md={8}>
            <TextField
              onChange={(e) =>
                checkExtraField(
                  "name",
                  e,
                  /^(?!.*[|\\#·$~%¬=^[\]*{}_-]).{1,50}$/,
                  t("MAX_50"),
                  false,
                  index
                )
              }
              margin="normal"
              fullWidth
              value={extras[index].name}
              id="name"
              name="name"
              autoComplete="name"
              autoFocus
              label={t("EXTRA_NAME")}
              error={
                (errors[index].name !== "" && errors[index].name !== "empty") ||
                (errors[index].name === "empty" && sendButtonClicked)
              }
              helperText={errors[index].name !== "empty" ? errors[index].name : ""}
              disabled={isDisabled()}
              sx={focusColor}
            />
          </Grid>
          <Grid item xs={12} container columnSpacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                onChange={(e) =>
                  checkExtraField("initial_stock", e, /^[0-9]\d*$/, t("INCORRECT"), true, index)
                }
                value={extras[index].initial_stock}
                margin="normal"
                type="number"
                fullWidth
                id="initial_stock"
                name="initial_stock"
                label={t("STOCK_TOTAL")}
                autoComplete="initial_stock"
                error={
                  (errors[index].initial_stock !== "" && errors[index].initial_stock !== "empty") ||
                  (errors[index].initial_stock === "empty" && sendButtonClicked)
                }
                helperText={
                  errors[index].initial_stock !== "empty" ? errors[index].initial_stock : ""
                }
                disabled={isDisabled()}
                sx={focusColor}
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                onChange={(e) =>
                  checkExtraField(
                    "times_can_be_consumed",
                    e,
                    /^[0-9]*$/,
                    t("INCORRECT"),
                    true,
                    index
                  )
                }
                value={extras[index].times_can_be_consumed}
                margin="normal"
                type="number"
                fullWidth
                id="times_can_be_consumed"
                name="times_can_be_consumed"
                label={t("TIMES_CAN_BE_CONSUMED")}
                autoComplete="times_can_be_consumed"
                error={
                  errors[index].times_can_be_consumed !== "" &&
                  errors[index].times_can_be_consumed !== "empty"
                }
                helperText={
                  errors[index].times_can_be_consumed !== "empty"
                    ? errors[index].times_can_be_consumed
                    : ""
                }
                disabled={isDisabled()}
                sx={focusColor}
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
          </Grid>
          <TextField
            onChange={(e) =>
              checkExtraField(
                "description",
                e,
                /^(?!.*[|\\#·$~%¬^[\]*{}_])[\s\S]{1,1000}$/,
                t("MAX_1000"),
                true,
                index
              )
            }
            value={extras[index].description}
            margin="normal"
            fullWidth
            multiline
            rows={4}
            id="description"
            name="description"
            label={t("DESCRIPTION")}
            autoComplete="description"
            error={
              (errors[index].description !== "" && errors[index].description !== "empty") ||
              (errors[index].description === "empty" && sendButtonClicked)
            }
            helperText={errors[index].description !== "empty" ? errors[index].description : ""}
            disabled={isDisabled()}
            sx={focusColor}
          />
        </Grid>
        <Grid
          container
          columnSpacing={2}
          sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
        >
          <Grid item xs={12} md={8}>
            <TextField
              onChange={(e) =>
                checkExtraField("stock_by_ticket_max", e, /^[0-9]\d*$/, t("INCORRECT"), true, index)
              }
              value={extras[index].stock_by_ticket_max}
              margin="normal"
              type="number"
              fullWidth
              id="stock_by_ticket_max"
              name="stock_by_ticket_max"
              label={t("LIMIT_EXTRA")}
              autoComplete="stock_by_ticket_max"
              error={
                (errors[index].stock_by_ticket_max !== "" &&
                  errors[index].stock_by_ticket_max !== "empty") ||
                (errors[index].stock_by_ticket_max === "empty" && sendButtonClicked)
              }
              helperText={
                errors[index].stock_by_ticket_max !== "empty"
                  ? errors[index].stock_by_ticket_max
                  : ""
              }
              disabled={isDisabled()}
              sx={focusColor}
              onWheel={(e) => e.target.blur()}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              onChange={(e) =>
                checkExtraField("price", e, /^\d+(?:\.\d+)?$/, t("INCORRECT"), true, index)
              }
              value={extras[index].price}
              margin="normal"
              type="number"
              fullWidth
              id="price"
              name="price"
              label={t("PRICE")}
              autoComplete="price"
              error={
                (errors[index].price !== "" && errors[index].price !== "empty") ||
                (errors[index].price === "empty" && sendButtonClicked)
              }
              helperText={errors[index].price !== "empty" ? errors[index].price : ""}
              disabled={isDisabled()}
              sx={focusColor}
              onWheel={(e) => e.target.blur()}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 2, display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                name="is_visible"
                checked={extras[index].is_visible}
                onChange={(e) =>
                  checkExtraField(
                    "is_visible",
                    e,
                    /^(true|false)$/,
                    t("CHOOSE_OPTION"),
                    true,
                    index,
                    "checked"
                  )
                }
                disabled={isDisabled()}
                value={extras[index].is_visible}
                sx={{
                  color: "var(--secondary-color)",
                  "&.Mui-checked": {
                    color: "var(--secondary-color)",
                  },
                }}
              />
            }
            label={t("IS_VISIBLE")}
          />
          <InfoToolTip text={t("IS_VISIBLE_INFO")} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 2, display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                name="only_international"
                checked={extras[index].only_international}
                onChange={(e) =>
                  checkExtraField(
                    "only_international",
                    e,
                    /^(true|false)$/,
                    t("CHOOSE_OPTION"),
                    true,
                    index,
                    "checked"
                  )
                }
                disabled={isDisabled()}
                value={extras[index].only_international}
                sx={{
                  color: "var(--secondary-color)",
                  "&.Mui-checked": {
                    color: "var(--secondary-color)",
                  },
                }}
              />
            }
            label={t("ONLY_INTERNATIONAL_CARD")}
          />
          <InfoToolTip text={t("ONLY_INTERNATIONAL_CARD_INFO")} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExtraCard;
