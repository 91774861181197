import { Grid, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextEditor from "../../textEditor/TextEditor";

const AdditionalInfo = ({ additionalInfo, setFormParamsValue }) => {
  const { t } = useTranslation();

  const handleSetData = (data) => {
    setFormParamsValue("moreInformation", "additional_info", data);
  };

  return (
    <Grid item xs={11} sx={{ mt: 2, mb: 2, display: "flex", flexDirection: "column" }}>
      <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
        {t("ADDITIONAL_INFO")}
      </InputLabel>
      <TextEditor data={additionalInfo} setData={handleSetData} id="additional_info" />
    </Grid>
  );
};

export default AdditionalInfo;
