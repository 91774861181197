import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { getEventStatics } from "../../../services/eventsServices";
import { getMonthStatistics } from "../../../services/utilsServices";
import DataCard from "./DataCard";
import { formatCurrency } from "../../../constants/utils";

const Statistics = (props) => {
  const { t } = useTranslation();

  const { eventId, month, fromHome } = props;
  const [statistics, setStatistics] = useState([]);
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === "super-admin");

  const {
    netIncome,
    numberOrders,
    totalSales,
    totalTickets,
    extras,
    tickets,
    salesByGender,
    salesByCard,
    ticketsByAge,
    assistantsByNationality,
    salesByPaymentMethod,
    salesByPromoCode = {},
  } = statistics;

  const isXsScreen = useMediaQuery("(max-width:863px)");

  const showByAge = ticketsByAge?.map((ticket) => {
    const ages = ticket.age.split(" - ");
    return {
      ...ticket,
      age: ages[0] === ages[1] ? ages[0] : ticket.age,
    };
  });

  //ventas por método de pago
  const paymentMethod = salesByPaymentMethod
    ?.filter((sale) => sale.type != null)
    .map((sale) => ({
      ...sale,
      type: t(sale.type?.toUpperCase()),
    }));

  //Get estadísticas por evento
  const handleStatistics = (data) => {
    setStatistics(data);
  };
  const { isLoading, isRefetching } = useQuery(
    ["statistics", month],
    async () => {
      if (eventId) {
        return await getEventStatics(eventId);
      } else if (month) {
        return await getMonthStatistics(month);
      }
    },
    {
      onSuccess: handleStatistics,
      refetchOnWindowFocus: false,
    }
  );

  //Gráfico ventas por genero
  const gender = [
    { name: t("MAN"), value: salesByGender?.men, fill: "var(--secondary-color)" },
    { name: t("WOMAN"), value: salesByGender?.women, fill: "var(--oniria-gold)" },
    { name: t("OTHER"), value: salesByGender?.others, fill: "var(--oniria-sky)" },
  ];
  const renderColorfulLegendText = (value, entry, index) => {
    return (
      <span
        style={{
          color: "#596579",
          fontWeight: 400,
          padding: "5px",
          display: "inline-flex",
          justifyContent: "space-between",
          width: "85%",
        }}
      >
        <div>{value}</div>
        <div>
          {(() => {
            if (index === 0) {
              return Math.floor(salesByGender?.men * 100) + "%";
            }
            if (index === 1) {
              return Math.floor(salesByGender?.women * 100) + "%";
            }
            if (index === 2) {
              return Math.floor(salesByGender?.others * 100) + "%";
            }
          })()}{" "}
        </div>
      </span>
    );
  };
  //Gráfico miembros con/sin tarjeta
  const card = [
    { name: t("WITH_CARD"), value: salesByCard?.withCard, fill: "var(--secondary-color)" },
    { name: t("NO_CARD"), value: salesByCard?.noCard, fill: "var(--oniria-gold)" },
  ];
  const renderMemberCardLegendText = (value, entry, index) => {
    return (
      <span
        style={{
          color: "#596579",
          fontWeight: 400,
          padding: "5px",
          display: "inline-flex",
          justifyContent: "space-between",
          width: "85%",
        }}
      >
        <div>{value}</div>
        <div>
          {(() => {
            if (index === 0) {
              return Math.floor(salesByCard?.withCard * 100) + "%";
            }
            if (index === 1) {
              return Math.floor(salesByCard?.noCard * 100) + "%";
            }
          })()}{" "}
        </div>
      </span>
    );
  };

  //check de si todas las nacionalidades son nulas
  const checkNationality = () => {
    return assistantsByNationality?.some((element) => {
      return element.nationality !== null;
    });
  };
  return isLoading || isRefetching ? (
    <Box display="flex" justifyContent="center" alignItems="center" height={"50vh"} width="100%">
      <CircularProgress sx={{ color: "var(--secondary-color)" }} />
    </Box>
  ) : numberOrders == 0 ? (
    <Typography paddingTop={4}>{t("EMPTY_STATICS")}</Typography>
  ) : (
    <Grid container mb={eventId ? 10 : 0} rowGap={5}>
      <Grid
        item
        container
        sx={{ height: "fit-content", justifyContent: { xs: "center", sm: "space-around" } }}
      >
        <DataCard value={`${formatCurrency(totalSales)}`} label={t("TOTAL_SALES")} icon_url="/media/homeIcons/dollar.svg" />
        {isSuperAdmin && <DataCard value={`${formatCurrency(netIncome)}`} label={t("NET_INCOME")} icon_url="/media/homeIcons/sells.svg" />}
        <DataCard value={`${numberOrders}`} label={t("ORDERS")} icon_url="/media/homeIcons/order.svg"/>
        <DataCard value={`${totalTickets}`} label={t("PERCENTAGE_TICKETS_SOLD")} icon_url="/media/homeIcons/order.svg"/>
      </Grid>
      <Grid item container columnGap={7}>
        {!fromHome && tickets?.length > 0 ? (
          <Grid item container flexDirection="column" xs={12} sm={8} lg={5} padding={3}>
            <Typography variant="p" sx={{ mb: 0 }}>
              {t("CATEGORY_SALES")}
            </Typography>
            {tickets.map(
              (ticket, index) =>
                ticket.type && (
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    key={index}
                    sx={{ borderBottom: "1px solid lightgrey" }}
                  >
                    <Typography className="oniria-title">{ticket.type}</Typography>
                    <Typography className="oniria-title">
                      {ticket.quantity} / {ticket.total} {t("SOLD")}
                    </Typography>
                  </Grid>
                )
            )}
          </Grid>
        ) : null}
        {!fromHome && extras?.length > 0 ? (
          <Grid item container flexDirection="column" xs={12} sm={8} lg={5} padding={3}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("EXTRAS_SALES")}
            </Typography>
            {extras.map(
              (extra, index) =>
                extra.type && (
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    key={index}
                    sx={{ borderBottom: "1px solid lightgrey" }}
                  >
                    <Typography>{extra.type}</Typography>
                    <Typography>
                      {extra.quantity} / {extra.total} {t("SOLD")}
                    </Typography>
                  </Grid>
                )
            )}
          </Grid>
        ) : null}
        {salesByPromoCode && (
          <Grid item container flexDirection="column" xs={12} sm={8} lg={5} padding={3}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("SALES_PROMO_CODE")}
            </Typography>
            {Object.keys(salesByPromoCode ?? "")
              ?.filter((code) => code !== "not-used" && code != "no-promo-code")
              .map((code, index) => (
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  key={index}
                  sx={{ borderBottom: "1px solid lightgrey" }}
                >
                  <Typography sx={{ textTransform: "uppercase" }}>{code}</Typography>
                  <Typography>
                    {salesByPromoCode[code]} {t("USED")}
                  </Typography>
                </Grid>
              ))}
          </Grid>
        )}
      </Grid>

      <Grid
        item
        container
        xs={12}
        columnGap={{ sm: 2, xl: 4 }}
        rowGap={4}
        sx={{ height: "fit-content" }}
      >
        {/* PAYMENT METHOD */}
        {paymentMethod?.length > 0 && (
          <Grid
            item
            xs={12}
            lg={11.8}
            xl={7.6}
            component={Paper}
            elevation={3}
            sx={{
              padding: "10px",
              border: "1px solid #E4E4E4",
              borderRadius: "15px",
              height: "100%",
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("PAYMENT_METHOD")}
            </Typography>
            <ResponsiveContainer width="100%" height={450}>
              <BarChart data={paymentMethod} margin={{ top: 30, bottom: 20, right: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="type"
                  angle={isXsScreen ? - 45 : 0}
                  height={isXsScreen ? 140 : 50}
                  tickMargin={isXsScreen && 50}
                  padding={isXsScreen && { right: 50 }}
                >
                  <Label value={t("PAYMENT_METHOD")} offset={-5} position="insideBottomRight" />
                </XAxis>
                <YAxis domain={[0, "dataMax + 10"]} tickCount={10}>
                  <Label position="top" width={200} offset={15} dx={40}>
                    {t("QUANTITY")}
                  </Label>
                </YAxis>
                <Legend />
                <Bar dataKey="amount" fill="var(--secondary-color)" legendType="none" />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        )}
        <Grid item container xs={12} lg={11.8} xl={4} rowGap={4} justifyContent="space-between">
          {/* GENDER */}
          {gender?.length > 0 && (
            <Grid
              item
              xs={12}
              sm={5.7}
              xl={12}
              component={Paper}
              elevation={3}
              sx={{
                padding: "10px",
                border: "1px solid #E4E4E4",
                borderRadius: "15px",
                width: "100%",
              }}
            >
              <Typography variant="h6">{t("CUSTOMER_GENDER")}</Typography>
              <ResponsiveContainer width="100%" height={isXsScreen ? 374 : "85%"}>
                <PieChart width={300} height={isXsScreen ? 400 : 200}>
                  <Pie
                    data={gender}
                    cx={isXsScreen ? "50%" : "45%"}
                    cy={"50%"}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"
                  ></Pie>
                  <Legend
                    layout="vertical"
                    iconType="circle"
                    iconSize={15}
                    formatter={renderColorfulLegendText}
                    align={isXsScreen ? "center" : "right"}
                    verticalAlign="bottom"
                    height={130}
                    width={isXsScreen ? "90%" : "40%"}
                  />
                </PieChart>
              </ResponsiveContainer>
            </Grid>
          )}
          {/* CARD MEMBERS */}
          {card?.length > 0 && (
            <Grid
              item
              xs={12}
              sm={5.7}
              xl={12}
              component={Paper}
              elevation={3}
              sx={{
                padding: "10px",
                border: "1px solid #E4E4E4",
                borderRadius: "15px",
                width: "100%",
              }}
            >
              <Typography variant="h6">{t("MEMBERS_WITH_CARD")}</Typography>
              <ResponsiveContainer width="100%" height={isXsScreen ? 374 : 260} maxHeight={"420px"}>
                <PieChart width={350} height={isXsScreen ? 400 : 200}>
                  <Pie
                    data={card}
                    cx={isXsScreen ? "50%" : "45%"}
                    cy={"50%"}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"
                  ></Pie>
                  <Legend
                    layout="vertical"
                    iconType="circle"
                    iconSize={15}
                    formatter={renderMemberCardLegendText}
                    align={isXsScreen ? "center" : "right"}
                    verticalAlign="bottom"
                    height={130}
                    width={isXsScreen ? "80%" : "40%"}
                  />
                </PieChart>
              </ResponsiveContainer>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* ASSISTANTS AGE & NATIONALITY */}
      <Grid
        item
        container
        xs={12}
        paddingBottom={15}
        sx={{ width: "100%" }}
        columnGap={{ sm: 3, xl: 4 }}
        rowGap={4}
      >
        {ticketsByAge?.length > 0 && (
          <Grid
            item
            component={Paper}
            elevation={3}
            xs={12}
            lg={7.6}
            sx={{
              padding: "10px",
              border: "1px solid #E4E4E4",
              borderRadius: "15px",
              height: "fit-content",
            }}
          >
            <Typography variant="h6">{t("ASSISTANTS_AGE")}</Typography>
            <ResponsiveContainer width="100%" height={500}>
              <BarChart data={showByAge} margin={{ top: 40 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="age" height={40}>
                  <Label value={t("ASSISTANTS_AGE")} offset={0} position="insideBottomRight" />
                </XAxis>
                <YAxis domain={[0, "dataMax + 20"]} tickCount={10}>
                  <Label position="top" width={200} offset={15} dx={40}>
                    {t("PERCENTAGE_TICKETS_SOLD")}
                  </Label>
                </YAxis>
                <Legend />
                <Bar dataKey="number" fill="var(--secondary-color)" legendType="none" />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        )}
        {checkNationality() > 0 && (
          <Grid
            item
            component={Paper}
            elevation={3}
            xs={12}
            lg={4}
            sx={{ padding: "10px", border: "1px solid #E4E4E4", borderRadius: "15px" }}
          >
            <Typography variant="h6">{t("NATIONALITIES")}</Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t("CUSTOMER_NATIONALITY")}</TableCell>
                  <TableCell>{t("ASSISTANTS_NUMBER")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ width: "fit-content", maxWidth: "100%" }}>
                {assistantsByNationality?.map(
                  (row, index) =>
                    row.nationality && (
                      <TableRow key={index}>
                        <TableCell>{row.nationality}</TableCell>
                        <TableCell>{row.number}</TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Statistics;
