import AddIcon from "@mui/icons-material/Add";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import { Grid, IconButton, ListItem, ListItemText, Paper, Tooltip } from "@mui/material";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import AddCategoryDialog from "./AddCategoryDialog";
import { Subcategories } from "./Subcategories";

export const CategoryItem = ({
  item,
  index,
  handleOpenRemove,
  handleSetSubcategories,
  handleEditCategory,
  handleAddSubcategory,
  handleDeleteSubcategory,
  handleEditSubcategory,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [openAddSubcategory, setOpenAddSubcategory] = useState(false);
  const [image, setImage] = useState("");

  const handleOpenAddCategory = (item) => {
    setName(item.name);
    setDescription(item.description);
    setSelectedCategory(item);
    setOpenEditCategory(true);
  };

  const handleAcceptDialog = () => {
    setOpenEditCategory(false);
    handleEditCategory(selectedCategory.id, name, description);
  };

  const handleOpenAddSubcategory = (item) => {
    setSelectedCategory(item);
    setOpenAddSubcategory(true);
  };

  const handleAcceptAddSubcategory = () => {
    setOpenAddSubcategory(false);
    handleAddSubcategory(selectedCategory.id, name, description, image);
  };

  return (
    <>
      <Draggable draggableId={item.id || "index"} index={index}>
        {(provided, snapshot) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            component={Paper}
            sx={{
              backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
              display: "flex",
              flexDirection: "column",
              padding: 2,
            }}
          >
            <Grid container>
              <Grid item container xs={6} alignItems="center">
                <DragIndicatorIcon sx={{ marginRight: 2 }} />
                <ListItemText
                  primary={item.name}
                  sx={{ margin: 0, display: "flex", alignItems: "center" }}
                />
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end">
                <Tooltip title={t("EDIT")} placement="top">
                  <IconButton
                    onClick={() => handleOpenAddCategory(item)}
                    sx={{ color: "var(--secondary-color)" }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("DELETE")} placement="top">
                  <IconButton
                    onClick={() => handleOpenRemove(item.id)}
                    sx={{
                      color: "var(--secondary-color)",
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("ADD_SUBCATEGORY")} placement="top">
                  <IconButton
                    onClick={() => handleOpenAddSubcategory(item)}
                    sx={{
                      color: "var(--secondary-color)",
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            {item.subcategories && item.subcategories.length > 0 ? (
              <Subcategories
                id={item.id}
                subcategories={item.subcategories}
                handleSetSubcategories={handleSetSubcategories}
                handleDeleteSubcategory={handleDeleteSubcategory}
                handleEditSubcategory={handleEditSubcategory}
              />
            ) : null}
          </ListItem>
        )}
      </Draggable>
      <AddCategoryDialog
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        isOpen={openEditCategory}
        onClose={() => {
          setOpenEditCategory(false);
          setName("");
          setDescription("");
        }}
        onAccept={handleAcceptDialog}
        title={t("EDIT_CATEGORY")}
      />
      <AddCategoryDialog
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        image={image}
        setImage={setImage}
        isSubcategory
        isOpen={openAddSubcategory}
        onClose={() => {
          setOpenAddSubcategory(false);
          setName("");
          setDescription("");
          setImage("");
        }}
        onAccept={handleAcceptAddSubcategory}
        title={t("ADD_SUBCATEGORY")}
      />
    </>
  );
};
