import { transformObjectWithUrls } from "../components/shared/FormsValidator";
import { SERVICE } from "../constants/api";
import http from "../constants/axios";

export async function getCategories() {
  const { data } = await http.get(SERVICE.CATEGORIES);
  return data;
}

export async function createCategory(body) {
  const { data } = await http.post(SERVICE.CATEGORIES, body);
  return data;
}

export async function updateCategory(id, name) {
  const { data } = await http.put(`${SERVICE.CATEGORIES}/${id}`, name);
  return data;
}

//Premises
export async function getCategoryPremise(id) {
  const { data } = await http.get(`${SERVICE.CATEGORIES}/premise/${id}`);
  return data;
}

export async function updateCategoriesPremise(id, body) {
  const bodyToSend = await transformObjectWithUrls(body);
  const { data } = await http.put(`${SERVICE.CATEGORIES}/premise/${id}`, bodyToSend);
  return data;
}

export async function getCategoriesDomainByPremise(premiseId) {
  const { data } = await http.get(`${SERVICE.CATEGORIES}/premise-domains/${premiseId}`);
  return data;
}

//Domains
export async function getCategoriesDomain(id) {
  const { data } = await http.get(`${SERVICE.CATEGORIES_DOMAIN}/${id}`);
  return data;
}

export async function updateCategoriesDomain(id, body) {
  const bodyToSend = await transformObjectWithUrls(body);
  const { data } = await http.put(`${SERVICE.CATEGORIES_DOMAIN}/${id}`, bodyToSend);
  return data;
}
