import { Grid, InputLabel } from "@mui/material";
import TextEditor from "../../textEditor/TextEditor";
import { useTranslation } from "react-i18next";

const NotIncludedOptions = ({ notIncludedOptions, setFormParamsValue }) => {
  const { t } = useTranslation();

  const handleSetData = (data) => {
    setFormParamsValue("moreInformation", "not_included_options", data);
  };

  console.log("no incluye", notIncludedOptions);

  return (
    <Grid item xs={11} sx={{ mt: 2, mb: 2, display: "flex", flexDirection: "column" }}>
      <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>{t("NOT_INCLUDING")}</InputLabel>
      <TextEditor data={notIncludedOptions} setData={handleSetData} id="not_included" />
    </Grid>
  );
};

export default NotIncludedOptions;
