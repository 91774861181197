import { Check, Close, CurrencyExchange, Visibility } from "@mui/icons-material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SearchIcon from "@mui/icons-material/Search";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { Fragment, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { formatDate } from "../../constants/utils";
import { EVENTS_TYPE, ROLES } from "../../constants/variables";
import useDebounce from "../../hooks/useDebouncing";
import { getAllOrders } from "../../services/eventsServices";
import RefundDialog from "../refunds/RefundDialog";
import { Loading } from "../shared/Loading";
import { focusColor } from "../shared/textFieldStyle";
import OrderDetail from "./OrderDetail";
import { renderExtrasCellOrders, renderTicketCell } from "./orderDetails/IndividualCells";

const Orders = (props) => {
  const { t } = useTranslation();
  const eventId = props.eventId;
  const [search, setSearch] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showDetailOrder, setShowDetailOrder] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);

  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === ROLES.SUPER_ADMIN);
  const isManager = roles.find((element) => element === ROLES.MANAGER_ADMIN);
  const isAdmin = roles.find((element) => element === ROLES.ADMIN);

  //Calcular precio para la devolución
  const maxPrice = () => {
    if (selectedOrder) {
      const amount = parseFloat(selectedOrder.amount);
      const firstPaymentAmount = parseFloat(selectedOrder.first_payment_amount || 0);
      const extras =
        selectedOrder?.only_extra_orders?.reduce(
          (acc, item) => acc + parseFloat(item.amount || 0),
          0
        ) || 0;
      if (isNaN(firstPaymentAmount)) return amount + extras;
      return selectedOrder.payed_at
        ? amount + firstPaymentAmount + extras
        : selectedOrder.first_payment_amount;
    }
  };

  const handleQuerySuccess = (data) => {
    const uniqOrders = data?.reduce(
      (acc, order) => {
        if (!acc.seen[order.order_id]) {
          acc.seen[order.order_id] = true;
          acc.result.push(order);
        }
        return acc;
      },
      { seen: {}, result: [] }
    ).result;
    setOrders(uniqOrders);
  };
  const { isLoading, refetch } = useQuery("orders", () => getAllOrders(eventId), {
    onSuccess: handleQuerySuccess,
    refetchOnWindowFocus: false,
  });
  const handleSearchChange = (event) => {
    const search = event.target.value;
    setSearch(search);
    const ordersFilter = orders.filter((order) =>
      order.client_name !== null
        ? order.client_name.toLowerCase().includes(search.toLowerCase())
        : order.buyer_first_name.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredOrders(ordersFilter);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleRowClick = (order) => {
    setSelectedOrder(order);
    setShowDetailOrder(true);
  };

  //download options
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenPopover = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClosePopover = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  //CSV download
  const keys = Object.getOwnPropertyNames(orders[0] || {});
  const headers = keys.map((key) => ({
    label: key,
    key: key,
  }));

  //PDF download
  const headersPdf = keys;
  const generatePDF = () => {
    const doc = new jsPDF();
    const tableData = orders.map((item) => keys.map((key) => item[key]));

    doc.autoTable({
      head: [headersPdf],
      body: tableData,
    });

    doc.save("orders.pdf");
  };

  //Refunds
  const handleOpenRefund = (order) => {
    setSelectedOrder(order);
    setOpenRefund(true);
  };
  const handleCloseRefund = () => {
    setOpenRefund(false);
    refetch();
  };

  const isIncompleteClientInfo = (order) => {
    if (order.type !== EVENTS_TYPE.TRIP) return false;
    return order.tickets.some((ticket) => {
      return ticket.client_email === null;
    });
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Grid
        container
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 3 }}
      >
        <Grid item xs={11} sx={{ display: "flex", flexDirection: "column" }}>
          {showDetailOrder ? (
            <OrderDetail
              order={selectedOrder.order_id}
              transactionId={selectedOrder.transaction_id}
              firstPaymentTransactionId={selectedOrder.first_payment_transaction_id}
              onBack={() => setShowDetailOrder(false)}
            />
          ) : (
            <>
              <Grid container sx={{ display: "flex", flexDirection: "row-reverse", mb: 5, mt: 2 }}>
                <Grid item xs={9} md={2.1} mb={2}>
                  <Button
                    className="oniria-btn"
                    variant="contained"
                    size="medium"
                    sx={{
                      fontSize: "12px",
                      borderRadius: 3,
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                    onClick={handleOpenPopover}
                  >
                    <CloudDownloadIcon />
                    {t("DOWNLOAD_LIST")}
                  </Button>
                  <Popover
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    id={popoverId}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <ButtonGroup
                      variant="contained"
                      orientation="vertical"
                      aria-label="vertical button group"
                    >
                      <Button
                        disableElevation
                        fullWidth
                        variant="contained"
                        className="popover-btn"
                        aria-label="download csv"
                      >
                        <CSVLink
                          data={orders}
                          filename={"orders.csv"}
                          target="_blank"
                          separator={";"}
                          headers={headers}
                          className="csv-link"
                        >
                          {t("CSV_FORMAT")}
                        </CSVLink>
                      </Button>
                      <Button
                        disableElevation
                        fullWidth
                        variant="contained"
                        className="popover-btn"
                        aria-label="download pdf"
                        onClick={generatePDF}
                      >
                        {t("PDF_FORMAT")}
                      </Button>
                    </ButtonGroup>
                  </Popover>
                </Grid>
                <Grid item xs={12} md={5} mb={2}>
                  <SearchIcon
                    sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }}
                  />
                  <TextField
                    label={t("SEARCH")}
                    size="small"
                    onChange={(event) => debounceSearch(event)}
                    sx={[focusColor, { width: "75%" }]}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                {t("TOTAL_ORDERS")}: {orders?.length}
              </Grid>
              <Grid item xs={11} md={12} mb={25}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                        <TableCell>{t("NAME")}</TableCell>
                        <TableCell>{t("SURNAME")}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{t("TRANSACTION_ID")}</TableCell>
                        <TableCell>{t("TICKETS")}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{t("PAYMENT_DATE")}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{t("PRICE")}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {t("COMPLETE_PAYMENTS")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(search === "" ? orders : filteredOrders)?.map((order, index) => (
                        <Fragment key={index}>
                          <TableRow>
                            <TableCell
                              sx={{
                                borderBottom: order.only_extra_orders ? "none" : "",
                                paddingBottom: order.only_extra_orders && 0,
                              }}
                            >
                              {order.buyer_first_name ? order.buyer_first_name : order.client_name}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: order.only_extra_orders ? "none" : "",
                                paddingBottom: order.only_extra_orders && 0,
                              }}
                            >
                              {order.buyer_last_name ? order.buyer_last_name : order.client_surname}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: order.only_extra_orders ? "none" : "",
                                paddingBottom: order.only_extra_orders && 0,
                              }}
                            >
                              {order.transaction_id
                                ? order.transaction_id
                                : order.first_payment_transaction_id}
                            </TableCell>
                            {renderTicketCell(order.tickets, order.only_extra_orders)}
                            <TableCell
                              sx={{
                                borderBottom: order.only_extra_orders ? "none" : "",
                                paddingBottom: order.only_extra_orders && 0,
                              }}
                            >
                              {order.payed_at
                                ? formatDate(order.payed_at)
                                : order.first_payment_payed_at
                                ? formatDate(order.first_payment_payed_at)
                                : ""}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: order.only_extra_orders ? "none" : "",
                                paddingBottom: order.only_extra_orders && 0,
                              }}
                            >
                              {order.payed_at
                                ? parseFloat(order.amount) +
                                  (order.first_payment_amount
                                    ? parseFloat(order.first_payment_amount)
                                    : 0)
                                : order.first_payment_amount}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: order.only_extra_orders ? "none" : "",
                                paddingBottom: order.only_extra_orders && 0,
                              }}
                            >
                              {!!order.payed_at ? (
                                <Check
                                  sx={{
                                    color: "var(--secondary-color)",
                                    width: "100%",
                                    margin: "0 auto",
                                    fontSize: "2rem",
                                  }}
                                />
                              ) : (
                                <Close
                                  sx={{
                                    color: "var(--oniria-red)",
                                    width: "100%",
                                    margin: "0 auto",
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: order.only_extra_orders ? "none" : "",
                                paddingBottom: order.only_extra_orders && 0,
                              }}
                            >
                              <Grid container alignItems="center" flexWrap="nowrap" columnGap={1}>
                                {isIncompleteClientInfo(order) && (
                                  <Tooltip title={t("PENDING_TICKET_INFO")}>
                                    <IconButton onClick={() => handleRowClick(order)}>
                                      <WarningAmberIcon sx={{ color: "var(--oniria-red)" }} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title={t("SEE")}>
                                  <IconButton onClick={() => handleRowClick(order)}>
                                    <Visibility sx={{ color: "var(--secondary-color)" }} />
                                  </IconButton>
                                </Tooltip>
                                {(isSuperAdmin || isManager || isAdmin) &&
                                order.amount > 0 &&
                                !order.refund_status ? (
                                  <Tooltip title={t("REFUND_APPLY")}>
                                    <Button onClick={() => handleOpenRefund(order)}>
                                      <CurrencyExchange sx={{ color: "var(--secondary-color)" }} />
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  <Typography>
                                    {order.refund_status
                                      ? t(order.refund_status.toUpperCase())
                                      : ""}
                                  </Typography>
                                )}
                              </Grid>
                            </TableCell>
                          </TableRow>
                          {/** Extra orders */}
                          {Array.isArray(order?.only_extra_orders) &&
                            order.only_extra_orders.length > 0 &&
                            order.only_extra_orders.map((item, index) => {
                              const isLastRow = index === order.only_extra_orders.length - 1;
                              const borderBottomStyle = { borderBottom: isLastRow ? "" : "none" };
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    //backgroundColor: "var(--grey-cancelled)",
                                    ...borderBottomStyle,
                                  }}
                                >
                                  <TableCell sx={{ padding: 0, ...borderBottomStyle }}></TableCell>
                                  <TableCell sx={{ padding: 0, ...borderBottomStyle }}></TableCell>
                                  <TableCell
                                    sx={{ padding: 0, paddingLeft: "36px", ...borderBottomStyle }}
                                  >
                                    {item.transaction_id}
                                  </TableCell>
                                  {renderExtrasCellOrders(item.extras, isLastRow)}
                                  <TableCell
                                    sx={{ padding: 0, paddingLeft: "36px", ...borderBottomStyle }}
                                  >
                                    {item.payed_at
                                      ? formatDate(item.payed_at)
                                      : item.first_payment_payed_at
                                      ? formatDate(item.first_payment_payed_at)
                                      : ""}
                                  </TableCell>
                                  <TableCell
                                    sx={{ padding: 0, paddingLeft: "36px", ...borderBottomStyle }}
                                  >
                                    {item.amount}
                                  </TableCell>
                                  <TableCell sx={{ padding: 0, ...borderBottomStyle }}></TableCell>
                                  <TableCell
                                    sx={{ padding: 0, paddingLeft: "16px", ...borderBottomStyle }}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      flexWrap="nowrap"
                                      columnGap={1}
                                    >
                                      <Tooltip title={t("SEE")}>
                                        <IconButton onClick={() => handleRowClick(item)}>
                                          <Visibility sx={{ color: "var(--secondary-color)" }} />
                                        </IconButton>
                                      </Tooltip>
                                      {(isSuperAdmin || isManager || isAdmin) &&
                                      item.amount > 0 &&
                                      !item.refund_status ? (
                                        <Tooltip title={t("REFUND_APPLY")}>
                                          <Button onClick={() => handleOpenRefund(item)}>
                                            <CurrencyExchange
                                              sx={{ color: "var(--secondary-color)" }}
                                            />
                                          </Button>
                                        </Tooltip>
                                      ) : (
                                        <Typography>
                                          {item.refund_status
                                            ? t(item.refund_status.toUpperCase())
                                            : ""}
                                        </Typography>
                                      )}
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {/* Refund dialog */}
      <RefundDialog
        openRefund={openRefund}
        handleCloseRefund={handleCloseRefund}
        maxPrice={maxPrice}
        user={userInfo}
        order_id={selectedOrder?.order_id}
      />
    </>
  );
};

export default Orders;
