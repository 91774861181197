import { Box, Card, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Loading } from "../../components/shared/Loading";
import {
  convertDate,
  formatCurrency,
  formatHours,
  getOneWeekFromToday,
} from "../../constants/utils";
import { getBills } from "../../services/billingServices";

const BillingHome = () => {
  const { t } = useTranslation();
  const [bills, setBills] = useState([]);
  const fromDate = getOneWeekFromToday();

  const handleQuerySuccess = (data) => {
    setBills(data.data);
  };

  const { isLoading, isRefetching: refetchingBills } = useQuery(
    [
      "bills",
      "",
      1,
      10,
      "",
      "",
      { from: fromDate, to: "" },
      { asc: [], desc: ["fechaEmision"] },
      "",
      "",
    ],
    () =>
      getBills(
        "",
        1,
        10,
        "",
        "",
        { from: fromDate, to: "" },
        { asc: [], desc: ["fechaEmision"] },
        "",
        ""
      ),
    {
      onSuccess: handleQuerySuccess,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Card elevation={2}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {isLoading ? (
          <Loading />
        ) : bills?.length > 0 ? (
          <>
            <Grid container>
              {bills.map((order, index) => (
                <Paper
                  key={`${order.transaction_id}-${index}`}
                  sx={{
                    p: 2,
                    m: 2,
                    margin: "auto",
                    width: "100%",
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    columns={12}
                    sx={{ borderBottom: "1px solid #ededed" }}
                  >
                    <Grid item xs={3} container direction="column" spacing={2}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          mb: 1,
                          textAlign: "left",
                          fontSize: { xs: "1rem", md: "0.8rem" },
                        }}
                        component="div"
                      >
                        {convertDate(order.payed_at)} {formatHours(order.payed_at)}
                      </Typography>
                      <Typography
                        sx={{
                          mb: 1,
                          textAlign: "left",
                          fontSize: { xs: "0.8rem", md: "0.8rem" },
                        }}
                        color="text.secondary"
                      >
                        ID: {order.transaction_id}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} container direction="column" spacing={2}>
                      <Typography
                        gutterBottom
                        component="div"
                        sx={{
                          mb: 1,
                          textAlign: "left",
                          fontSize: { xs: "0.8rem", md: "0.8rem" },
                        }}
                      >
                        {order.email}
                      </Typography>
                      <Typography
                        sx={{
                          mb: 1,
                          textAlign: "left",
                          fontSize: { xs: "0.7rem", md: "0.7rem" },
                        }}
                        gutterBottom
                      >
                        {order.event_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          mb: 1,
                          textAlign: "right",
                          fontSize: { xs: "0.9rem", md: "0.9rem" },
                        }}
                        component="div"
                      >
                        {formatCurrency(order.amount)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </Grid>
          </>
        ) : (
          !isLoading &&
          !refetchingBills && (
            <Grid container justifyContent="center">
              <Typography>{t("NO_RESULTS")}</Typography>
            </Grid>
          )
        )}
      </Box>
    </Card>
  );
};
export default BillingHome;
