import { Box, Button, Grid, InputLabel, Paper } from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { block, blockErrors } from "../../../classes/tripClass";
import ButtonsForms from "../../account/forms/ButtonsForms";
import { allFieldsOk } from "../../shared/FormsValidator";
import MultiSelect from "../../shared/MultiSelect";
import Block from "../trip/Block";
import Itineraries from "../trip/itineraries/Itineraries";
import AdditionalInfo from "./AdditionalInfo";
import NotIncludedOptions from "./NotIncludedOptions";
import { Loading } from "../../shared/Loading";

const MoreInformation = (props) => {
  const { t } = useTranslation();
  const formController = props.formController;
  const { formData, setFormErrors } = formController;
  const { moreInformation: trip } = formController.formData;
  const { moreInformation: moreInformationErrors } = formController.validation;
  const setFormParamsValue = formController.setFormParamsValue;
  const { buttonClicked, setButtonClicked } = props;
  const editEvent = props.editEvent;
  const [forceRender, setForceRender] = useState(false);

  const forceUpdate = () => {
    setForceRender((prev) => !prev);
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 10);
  };

  const isDisabled = () => {
    return props.isDisabled ? props.isDisabled : false;
  };

  const setBlocksErrors = (updatedBlocksErrors) => {
    setFormErrors("moreInformation", "blocks", updatedBlocksErrors);
  };

  // Function to add a block to the state
  const addBlock = () => {
    const newBlock = JSON.parse(JSON.stringify(block));
    const newBlockErrors = JSON.parse(JSON.stringify(blockErrors));
    const blocksNewState = [...trip.blocks, newBlock];
    setBlocksErrors([...moreInformationErrors.blocks, newBlockErrors]);

    setFormParamsValue("moreInformation", "blocks", blocksNewState);
  };

  // Function to remove a block from the state
  const removeBlock = (index) => {
    const updatedBlocks = trip.blocks.filter((_, blockIndex) => blockIndex !== index);
    setFormParamsValue("moreInformation", "blocks", updatedBlocks);
    //
    const updatedBlocksErrors = moreInformationErrors.blocks.filter(
      (_, blockIndex) => blockIndex !== index
    );
    setBlocksErrors(updatedBlocksErrors);
  };

  const handleBlockChange = (index, key, e) => {
    const blocksUpdated = formData.moreInformation.blocks;
    blocksUpdated[index][key] = e.target.value;
    setFormParamsValue("moreInformation", "blocks", blocksUpdated);
  };

  const handleBlockImageChange = (index, key, url) => {
    //
    let images;
    if (Array.isArray(url)) {
      const imageArray = [];
      for (let i = 0; i < url.length; i++) {
        const urlParams = url[i].split(";");
        const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
        imageArray.push(imageObject);
      }
      images = imageArray;
    } else {
      const urlParams = url.split(";");
      const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
      images = [imageObject];
    }
    //
    const blocksUpdated = formData.moreInformation.blocks;
    blocksUpdated[index][key] = images;
    setFormParamsValue("moreInformation", "blocks", blocksUpdated);
  };

  //To set next button form clicked
  const catchButtonClicked = (value) => {
    setButtonClicked(value);
  };

  //For the drag and drop
  const handleSetBlocksPosition = ({ destination, source }) => {
    if (!destination) return;
    const newBlocks = [...(trip.blocks || [])];
    const [removed] = newBlocks.splice(source.index, 1);
    newBlocks.splice(destination.index, 0, removed);

    const updatedBlocks = newBlocks.map((block, index) => ({
      ...block,
      position: index,
    }));

    setFormParamsValue("moreInformation", "blocks", updatedBlocks);
    forceRender();
  };

  return (
    <Grid
      container
      sx={{
        mt: 5,
        mb: 20,
        px: 1,
        color: "black",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid item xs={11} md={8}>
        <Box
          component={Paper}
          elevation={3}
          sx={{
            py: 2,
            px: 3,
            border: "1px solid #E4E4E4",
            borderRadius: "15px",
          }}
        >
          <Box component="form" noValidate sx={{ mt: 2 }}>
            <Grid
              container
              sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
            >
              <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                  {t("INCLUDING")}
                </InputLabel>
                <MultiSelect formController={formController} />
              </Grid>
              <NotIncludedOptions
                notIncludedOptions={trip.not_included_options}
                setFormParamsValue={setFormParamsValue}
              />
              <AdditionalInfo
                additionalInfo={trip.additional_info}
                setFormParamsValue={setFormParamsValue}
              />
              {forceRender ? (
                <Loading />
              ) : (
                <Itineraries
                  formController={formController}
                  buttonClicked={buttonClicked}
                  forceUpdate={forceUpdate}
                />
              )}
              <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                  {t("MORE_INFO")}
                </InputLabel>
                {forceRender ? (
                  <Loading />
                ) : (
                  <DragDropContext onDragEnd={handleSetBlocksPosition}>
                    <Droppable droppableId="droppable-list">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            width: "100%",
                          }}
                        >
                          {trip.blocks.map(
                            (block, i) =>
                              block && (
                                <Block
                                  key={i}
                                  index={i}
                                  removeBlock={removeBlock}
                                  formController={formController}
                                  blocksErrors={moreInformationErrors.blocks}
                                  setBlocksErrors={setBlocksErrors}
                                  handleBlockChange={handleBlockChange}
                                  buttonClicked={buttonClicked}
                                  handleBlockImageChange={handleBlockImageChange}
                                  editEvent={editEvent}
                                />
                              )
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
                <Button
                  fullWidth
                  variant="outlined"
                  size="large"
                  sx={{
                    color: "var(--secondary-color)",
                    borderColor: "var(--secondary-color)",
                    "&:hover": {
                      color: "var(--secondary-color)",
                      borderColor: "var(--secondary-color)",
                      backgroundColor: "white",
                    },
                    mt: 2,
                  }}
                  onClick={addBlock}
                >
                  {t("ADD_BLOCK")}
                </Button>
              </Grid>
            </Grid>
          </Box>
          {!isDisabled() ? (
            <ButtonsForms
              formController={formController}
              allFieldsOk={allFieldsOk}
              errors={moreInformationErrors}
              sendButtonClicked={catchButtonClicked}
            />
          ) : (
            ""
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default MoreInformation;
