import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList.jsx";
import { getOrganizations } from "../../services/organizationsServices";
import { getCountries } from "../../services/utilsServices";
import { allFieldsOk, checkField, handleImageSelect } from "../shared/FormsValidator";
import ImageUpload from "../shared/ImageUpload";
import { focusColor } from "../shared/textFieldStyle.jsx";
import Address from "./forms/Address";
import ButtonsForms from "./forms/ButtonsForms";
import Contact from "./forms/Contact";
import HeaderForms from "./forms/HeaderForms";

const LegalPerson = (props) => {
  const { t } = useTranslation();
  const formController = props.formController;
  const { user } = formController.formData;
  const { user: userErrors } = formController.validation;
  const { formParams, setFormParamsNotEvent, setFormErrors, formAddRolesAdminParams } =
    formController;
  const documents = ["NIE", "NIF", t("Passport")];
  const [buttonClicked, setButtonClicked] = useState(false);
  const url = window.location.href;
  const path = url.split("/").slice(3).join("/");
  const [organizations, setOrganizations] = useState([]);
  const [selectedPremises, setSelectedPremises] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountryNationality, setSelectedCountryNationality] = useState("");
  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isAdmin = roles.find((element) => element === "admin");
  const isSuperAdmin = roles.find((element) => element === "super-admin");
  const isManager = roles.find((element) => element === "manager-admin");
  const isAdminNoAffiliate = roles.includes("admin") && !roles.includes("affiliated");

  useEffect(() => {
    if (path === "admins/create_admin") {
      getOrganizations()
        .then((data) => {
          setOrganizations(data);
        })
        .catch((error) => {
          console.error("Error al obtener datos:", error);
        });
    }
  }, []);

  const { data: premises } = useQueryPremisesList(user.organization_id, "all");

  useEffect(() => {
    if (user?.nationality !== "" && countries.length > 0) {
      const result = countries.find((obj) => obj.country_code === user.nationality);
      setSelectedCountryNationality(
        result?.country_code.toLowerCase() + "-" + result?.official_name_en
      );
    }
  }, [user.nationality, countries]);

  useEffect(() => {
    if (isAdmin === "admin" && organizations.length > 0 && path === "admins/create_admin") {
      user.organization_id = organizations[0].id;
      setFormErrors("user", "organization_id", "");
    }
  }, [organizations]);

  const handleQueryCountriesSuccess = (data) => {
    setCountries(data);
  };
  useQuery("countries", () => getCountries(), {
    onSuccess: handleQueryCountriesSuccess,
  });

  const handleChangeCountryNationality = (event) => {
    setSelectedCountryNationality(event.target.value);
    user.nationality = event.target.value?.split("-")[0].toUpperCase();
    setFormErrors("user", "nationality", "");
  };

  const handleBornAtChange = (event) => {
    checkField(
      "user",
      "born_at",
      event,
      /^(\d{4})-(\d{2})-(\d{2})$/,
      t("INCORRECT"),
      false,
      formParams,
      setFormErrors
    );
  };
  const handleExpiringDateChange = (event) => {
    checkField(
      "user",
      "doc_expiration_date",
      event,
      /^(\d{4})-(\d{2})-(\d{2})$/,
      t("INCORRECT"),
      false,
      formParams,
      setFormErrors
    );
  };
  const handleCheckboxUserGender = (event) => {
    formParams("user", "genre", event);
  };
  const handleCheckboxRoles = (event) => {
    formAddRolesAdminParams("user", "roles", event);
    setFormErrors("user", "roles", "");
  };
  const handleCheckboxNationals = (event) => {
    formAddRolesAdminParams("user", "roles", event);
  };
  const handleChangeDocument = (event) => {
    checkField(
      "user",
      "type_document_identity",
      event,
      /^[A-Za-záéíóúüñç0-9\s]{1,20}$/i,
      t("INCORRECT"),
      false,
      formParams,
      setFormErrors
    );
  };
  const handleChangeOrganization = (event) => {
    formParams("user", "organization_id", event);
    setFormErrors("user", "organization_id", "");
  };
  const handleChangePremises = (event) => {
    const selectedValuesPremises = event.target.value;
    setSelectedPremises(selectedValuesPremises);
    formParams("user", "premises_id", event);
    setFormErrors("user", "premises_id", "");
  };
  const isDisabled = () => {
    return props.isDisabled ? props.isDisabled : false;
  };
  // to set next button form clicked
  const catchButtonClicked = (value) => {
    setButtonClicked(value);
  };

  //calculate 18 year before today
  const dateOlderAge = () => {
    var today = new Date();
    var olderAge = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    olderAge.setDate(olderAge.getDate() + 1);
    return olderAge;
  };
  return (
    <>
      <HeaderForms title={props.title} description={props.description} />
      <Grid
        container
        sx={{
          mb: 20,
          px: 1,
          color: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid item xs={11} md={9} lg={7.5}>
          <Box
            component={Paper}
            elevation={3}
            sx={{
              py: 2,
              px: 3,
              border: "1px solid #E4E4E4",
              borderRadius: "15px",
            }}
          >
            <Box component="form" sx={{ mt: 2 }}>
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid item xs={11} md={12}>
                  <Grid
                    container
                    columnSpacing={2}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    {path === "admins/create_admin" && (
                      <>
                        {(isManager || isSuperAdmin) && (
                          <Grid item xs={12} mb={2}>
                            <FormControl
                              fullWidth
                              sx={focusColor}
                              error={userErrors.organization_id === "" && buttonClicked}
                            >
                              <InputLabel id="demo-select-small-label">
                                {t("ORGANIZATION")}
                              </InputLabel>
                              <Select
                                label={t("ORGANIZATION")}
                                value={user.organization_id}
                                onChange={handleChangeOrganization}
                              >
                                {organizations.map((organization) => (
                                  <MenuItem key={organization.id} value={organization.id}>
                                    {organization.legal_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          <FormControl
                            fullWidth
                            sx={focusColor}
                            error={premises?.length === 0 && buttonClicked}
                          >
                            <InputLabel id="demo-select-small-label">{t("SITE")}</InputLabel>
                            <Select
                              multiple
                              label={t("SITE")}
                              value={user.premises_id}
                              onChange={handleChangePremises}
                            >
                              {premises?.map((premise) => (
                                <MenuItem key={premise.id} value={premise.id}>
                                  {premise.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={6}>
                      <TextField
                        onChange={(e) =>
                          checkField(
                            "user",
                            "name",
                            e,
                            /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\-0-9]).{1,50}$/,
                            t("MAX_50"),
                            false,
                            formParams,
                            setFormErrors
                          )
                        }
                        margin="normal"
                        required
                        fullWidth
                        value={user.name}
                        id="name"
                        name="name"
                        label={t("NAME")}
                        autoComplete="name"
                        autoFocus
                        error={
                          (userErrors.name !== "" && userErrors.name !== "empty") ||
                          (userErrors.name === "empty" && buttonClicked)
                        }
                        helperText={userErrors.name !== "empty" ? userErrors.name : ""}
                        sx={focusColor}
                        disabled={isDisabled()}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        onChange={(e) =>
                          checkField(
                            "user",
                            "surname",
                            e,
                            /^(?!.*[|\\#·$~%&¬/()=^[\]+*{}_\-0-9]).{1,50}$/,
                            t("MAX_50"),
                            false,
                            formParams,
                            setFormErrors
                          )
                        }
                        value={user.surname}
                        margin="normal"
                        required
                        fullWidth
                        id="surname"
                        name="surname"
                        label={t("SURNAME")}
                        autoComplete="surname"
                        error={
                          (userErrors.surname !== "" && userErrors.surname !== "empty") ||
                          (userErrors.surname === "empty" && buttonClicked)
                        }
                        helperText={userErrors.surname !== "empty" ? userErrors.surname : ""}
                        sx={focusColor}
                        disabled={isDisabled()}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                        {t("BORN_AT")}
                      </InputLabel>
                      <TextField
                        onChange={handleBornAtChange}
                        type="date"
                        value={user.born_at}
                        required
                        margin="normal"
                        fullWidth
                        id="born_at"
                        name="born_at"
                        autoComplete="born_at"
                        error={
                          (userErrors.born_at !== "" && userErrors.born_at !== "empty") ||
                          (userErrors.born_at === "empty" && buttonClicked)
                        }
                        helperText={userErrors.born_at !== "empty" ? userErrors.born_at : ""}
                        sx={focusColor}
                        inputProps={{
                          min: "1900-01-01",
                          max: dateOlderAge().toISOString().split("T")[0],
                        }}
                        disabled={isDisabled()}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel sx={{ fontSize: "18px", color: "black", mb: 1 }}>
                        {t("NATIONALITY")}
                      </InputLabel>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        sx={focusColor}
                        error={selectedCountryNationality === "" && buttonClicked}
                      >
                        <InputLabel id="demo-select-small-label">{t("NATIONALITY")}</InputLabel>
                        <Select
                          disabled={isDisabled()}
                          label={t("NATIONALITY")}
                          value={selectedCountryNationality ? selectedCountryNationality : ""}
                          onChange={handleChangeCountryNationality}
                          renderValue={(selected) => (
                            <Grid container sx={{ display: "flex", flexDirection: "row" }}>
                              <img
                                style={{
                                  height: "20px",
                                  width: "35px",
                                  objectFit: "cover",
                                  marginRight: 10,
                                }}
                                alt="Flag"
                                src={`/media/flags/${selected.split("-")[0]}.svg`}
                              />
                              <Typography>{selected.split("-")[1]}</Typography>
                            </Grid>
                          )}
                        >
                          {countries.map((country) => (
                            <MenuItem
                              key={country.country_code}
                              value={
                                country.country_code.toLowerCase() + "-" + country.official_name_en
                              }
                            >
                              <img
                                style={{
                                  height: "20px",
                                  width: "35px",
                                  objectFit: "cover",
                                  marginRight: 10,
                                }}
                                alt="Flag"
                                src={`/media/flags/${country.country_code.toLowerCase()}.svg`}
                              />
                              {country.official_name_en}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {path !== "createaccount" && (
                    <>
                      <InputLabel sx={{ fontSize: "18px", color: "black", mt: 2 }}>
                        {t("AVATAR")}
                      </InputLabel>
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Grid item xs={12}>
                          <ImageUpload
                            url={user.avatar_url}
                            selectedImagesInForm={user.avatar_url}
                            onImageSelect={(url) =>
                              handleImageSelect(
                                "user",
                                url,
                                "avatar_url",
                                setFormParamsNotEvent,
                                setFormErrors
                              )
                            }
                            multi={false}
                            disabled={isDisabled()}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid
                    container
                    columnSpacing={2}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                        {t("DOCUMENT")}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl
                        fullWidth
                        sx={focusColor}
                        error={user.type_document_identity === "" && buttonClicked}
                      >
                        <InputLabel id="demo-select-small-label">{t("TYPE")}</InputLabel>
                        <Select
                          label={t("TYPE")}
                          value={user.type_document_identity}
                          onChange={handleChangeDocument}
                          disabled={isDisabled()}
                        >
                          {documents.map((document) => (
                            <MenuItem key={document} value={document}>
                              {document}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={8} sx={{ mt: -1 }}>
                      <TextField
                        onChange={(e) =>
                          checkField(
                            "user",
                            "identity_number",
                            e,
                            /^(?:[0-9XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]|[A-Z0-9]{3,20})$/i,
                            t("INCORRECT"),
                            false,
                            formParams,
                            setFormErrors
                          )
                        }
                        value={user.identity_number}
                        margin="normal"
                        required
                        fullWidth
                        id="identity_number"
                        name="identity_number"
                        label={t("IDENTIFICATION_NUMBER")}
                        autoComplete="identity_number"
                        error={
                          (userErrors.identity_number !== "" &&
                            userErrors.identity_number !== "empty") ||
                          (userErrors.identity_number === "empty" && buttonClicked)
                        }
                        helperText={
                          userErrors.identity_number !== "empty" ? userErrors.identity_number : ""
                        }
                        sx={focusColor}
                        disabled={isDisabled()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                        {t("EXPIRY_DATE")}
                      </InputLabel>
                      <TextField
                        onChange={handleExpiringDateChange}
                        type="date"
                        value={user.doc_expiration_date}
                        margin="normal"
                        fullWidth
                        id="doc_expiration_date"
                        name="doc_expiration_date"
                        autoComplete="doc_expiration_date"
                        sx={focusColor}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0], // Establece la fecha mínima como el día de hoy
                          max: "2100-12-31", // Establece la fecha máxima como el 31 de diciembre de 2100
                        }}
                        error={userErrors.doc_expiration_date === "empty" && buttonClicked}
                        disabled={isDisabled()}
                      />
                    </Grid>
                  </Grid>
                  {path !== "createaccount" && (
                    <>
                      <InputLabel sx={{ fontSize: "18px", color: "black", mt: 2 }}>
                        {t("PHOTOCOPY_DOCUMENT")}
                        <Tooltip title={t("PHOTOCOPY_DOCUMENT_INFO")} sx={{ color: "black" }}>
                          <IconButton>
                            <InfoOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </InputLabel>
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Grid item xs={12}>
                          <ImageUpload
                            url={user.identity_document}
                            selectedImagesInForm={user.identity_document}
                            onImageSelect={(url) =>
                              handleImageSelect(
                                "user",
                                url,
                                "identity_document",
                                setFormParamsNotEvent,
                                setFormErrors
                              )
                            }
                            multi={false}
                            disabled={isDisabled()}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Address
                    classForm={"user"}
                    formParams={formParams}
                    formData={user}
                    checkField={checkField}
                    errors={userErrors}
                    setErrors={setFormErrors}
                    buttonClicked={buttonClicked}
                    isDisabled={isDisabled()}
                  />
                  <Contact
                    classForm={"user"}
                    formParams={formParams}
                    formData={user}
                    checkField={checkField}
                    errors={userErrors}
                    setErrors={setFormErrors}
                    buttonClicked={buttonClicked}
                    hasToCheckMail={true}
                    isDisabled={isDisabled()}
                  />
                  {path.includes("admins/") && (
                    <Grid
                      container
                      columnSpacing={2}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Grid item xs={12}>
                        <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                          {t("ADMIN_ROL")}*
                        </InputLabel>
                        <Grid container sx={{ mt: 2, mb: 2 }}>
                          <Grid item xs={6}>
                            <FormControlLabel
                              disabled={isDisabled()}
                              control={
                                <Checkbox
                                  name="roles"
                                  checked={user.roles.includes("admin")}
                                  onChange={handleCheckboxRoles}
                                  value="admin"
                                  sx={{
                                    color: "var(--secondary-color)",
                                    "&.Mui-checked": {
                                      color: "var(--secondary-color)",
                                    },
                                  }}
                                />
                              }
                              label={t("ADMIN_ROLE")}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlLabel
                              disabled={isDisabled()}
                              control={
                                <Checkbox
                                  name="roles"
                                  checked={user.roles.includes("delegate")}
                                  onChange={handleCheckboxRoles}
                                  value="delegate"
                                  sx={{
                                    color: "var(--secondary-color)",
                                    "&.Mui-checked": {
                                      color: "var(--secondary-color)",
                                    },
                                  }}
                                />
                              }
                              label={t("REPRESENTATIVE")}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {!isAdminNoAffiliate && path.includes("admins/") && (
                    <Grid
                      container
                      columnSpacing={2}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Grid item xs={12}>
                        <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                          {t("CLIENTS_INFO")}*
                        </InputLabel>
                        <Grid container sx={{ mt: 2, mb: 2 }}>
                          <Grid item xs={6}>
                            <FormControlLabel
                              disabled={isDisabled()}
                              control={
                                <Checkbox
                                  name="roles"
                                  checked={!user.roles.includes("affiliated")}
                                  onChange={handleCheckboxNationals}
                                  value=""
                                  sx={{
                                    color: "var(--secondary-color)",
                                    "&.Mui-checked": {
                                      color: "var(--secondary-color)",
                                    },
                                  }}
                                />
                              }
                              label={t("NATIONALS")}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlLabel
                              disabled={isDisabled()}
                              control={
                                <Checkbox
                                  name="roles"
                                  checked={user.roles.includes("affiliated")}
                                  onChange={handleCheckboxNationals}
                                  value="affiliated"
                                  sx={{
                                    color: "var(--secondary-color)",
                                    "&.Mui-checked": {
                                      color: "var(--secondary-color)",
                                    },
                                  }}
                                />
                              }
                              label={t("INTERNATIONALS")}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    columnSpacing={2}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                        {t("IDENTIFY")}
                      </InputLabel>
                      <Grid container sx={{ mt: 2, mb: 2 }}>
                        <Grid item xs={4}>
                          <FormControlLabel
                            disabled={isDisabled()}
                            control={
                              <Checkbox
                                name="genre"
                                checked={user.genre === "woman"}
                                onChange={handleCheckboxUserGender}
                                value="woman"
                                sx={{
                                  color: "var(--secondary-color)",
                                  "&.Mui-checked": {
                                    color: "var(--secondary-color)",
                                  },
                                }}
                              />
                            }
                            label={t("WOMAN")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel
                            disabled={isDisabled()}
                            control={
                              <Checkbox
                                name="genre"
                                checked={user.genre === "man"}
                                onChange={handleCheckboxUserGender}
                                value="man"
                                sx={{
                                  color: "var(--secondary-color)",
                                  "&.Mui-checked": {
                                    color: "var(--secondary-color)",
                                  },
                                }}
                              />
                            }
                            label={t("MAN")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel
                            disabled={isDisabled()}
                            control={
                              <Checkbox
                                name="genre"
                                checked={user.genre === "other"}
                                onChange={handleCheckboxUserGender}
                                value="other"
                                sx={{
                                  color: "var(--secondary-color)",
                                  "&.Mui-checked": {
                                    color: "var(--secondary-color)",
                                  },
                                }}
                              />
                            }
                            label={t("OTHER")}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    columnSpacing={2}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                        {t("OCCUPATION")}
                      </InputLabel>
                      <TextField
                        onChange={(e) =>
                          checkField(
                            "user",
                            "profession",
                            e,
                            /^(?!.*[|\\#·$~%&¬/()^[\]+*{}_]).{1,250}$/,
                            t("MAX_250"),
                            true,
                            formParams,
                            setFormErrors
                          )
                        }
                        margin="normal"
                        fullWidth
                        value={user.profession}
                        id="profession"
                        name="profession"
                        autoComplete="profession"
                        error={userErrors.profession !== ""}
                        helperText={userErrors.profession}
                        sx={focusColor}
                        disabled={isDisabled()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {!allFieldsOk(userErrors) && buttonClicked && (
              <Typography sx={{ color: "#d32f2f", pt: 2 }}>*{t("REQUIRED_FIELD")}</Typography>
            )}
            {!isDisabled() ? (
              <ButtonsForms
                formController={formController}
                allFieldsOk={allFieldsOk}
                errors={userErrors}
                sendButtonClicked={catchButtonClicked}
              />
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default LegalPerson;
