import { FormControl } from "@mui/base";
import { CurrencyExchange, ExpandLess, ExpandMore } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormControl as FormControlM,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import useDebounce from "../../hooks/useDebouncing";
import { getAllAssistants, validateExtra, validateTicket } from "../../services/eventsServices";
import { focusColor } from "../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../shared/toastMessage";
import AssistantDetail from "./AssistantDetail";

const ListAssistants = (props) => {
  const { t } = useTranslation();
  const eventId = props.eventId;
  const isDoublePayment = props.isDoublePayment;
  const [search, setSearch] = useState("");
  const [filteredAssistants, setFilteredAssistants] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [showDetailAssistant, setShowDetailAssistant] = useState(false);
  const [openCheck, setOpenCheck] = useState(false);
  const [totalSales, setTotalSales] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [validateAssistan, setValidateAssistan] = useState("");
  const [numberValidated, setNumberValidated] = useState(0);
  const [ticketsTypes, setTicketsTypes] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState("");
  const [filter, setFilter] = useState({ active: false, last: "", alphabetical: "" });
  const [incompletePayment, setIncompletePayment] = useState(0);
  const [numberRefunds, setNumberRefunds] = useState(0);

  const handleQuerySuccess = (data) => {
    const assistantsData = data.data;
    const sortedAssistants = assistantsData.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );

    setNumberRefunds(data.refunded_tickets);
    setAssistants(sortedAssistants);
    setTicketsTypes(data.tickets);
    setNumberValidated(0);
    setTotalSales(data.sold_tickets);
    for (let i = 0; i < assistantsData.length; i++) {
      //number of assistants validated
      if (assistantsData[i].consumed_at !== null) {
        setNumberValidated((prev) => prev + 1);
      }
    }
  };
  const { isLoading, refetch: refetchAssistants } = useQuery(
    "assistants",
    () => getAllAssistants(eventId),
    {
      onSuccess: handleQuerySuccess,
    }
  );

  const handleSearchChange = (event) => {
    const search = event.target.value;
    setSearch(search);
    applyFilters(search, selectedTicket);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  //type ticket
  const handleChangeTypeTicket = (e) => {
    const value = e.target.value;
    applyFilters(search, value);
    setSelectedTicket(value);
  };
  //Select and search filters
  const applyFilters = (search, ticketId) => {
    //search
    const assistantFilter = assistants.filter((assistant) =>
      assistant.client_name !== null
        ? assistant.client_name.toLowerCase().includes(search.toLowerCase())
        : assistant.buyer_name.toLowerCase().includes(search.toLowerCase())
    );
    //type of ticket
    if (ticketId !== "" && ticketId !== "0") {
      const assistantsFilter = assistantFilter.filter(
        (assistant) => assistant.ticket_name === ticketId
      );
      setFilteredAssistants(assistantsFilter);
    } else {
      setFilteredAssistants(assistantFilter);
    }
    setFilter({ active: false, last: "", alphabetical: "" });
  };

  const handleRowClick = (assistant) => {
    setSelectedAssistant(assistant);
    setShowDetailAssistant(true);
  };

  const handleClickOpenCheck = (assistant) => {
    setValidateAssistan(assistant);
    setOpenCheck(true);
  };

  const handleCloseCheck = () => {
    setSelectedOption("");
    setOpenCheck(false);
  };

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    const optionValue = e.target.value;
    if (optionValue === "all") {
      if (selectedOption.includes("all")) {
        setSelectedOption([]);
      } else {
        const allOptions = [
          "all",
          "ticket",
          ...validateAssistan.extras.map((_, index) => `extra${index}`),
          ...validateAssistan.extras.flatMap((extra, index2) => {
            if (extra.extra_consumitions != null && extra.times_can_be_consumed > 1) {
              return [...Array(extra.times_can_be_consumed)]?.map(
                (_, i) => `extra${index2}-item${i}`
              );
            } else {
              return [];
            }
          }),
        ];
        setSelectedOption(allOptions);
      }
    } else {
      setSelectedOption((prevSelectedOption) => {
        //Para bonos copas
        let relatedItems = [];
        if (prevSelectedOption.includes(optionValue)) {
          return prevSelectedOption.filter(
            (option) => option !== optionValue && !option?.includes(optionValue)
          );
        } else {
          if (optionValue.includes("extra") && !optionValue.includes("-item")) {
            const indexExtra = parseInt(optionValue.replace("extra", ""), 10);
            relatedItems = validateAssistan.extras.flatMap((extra, index) => {
              if (
                extra.extra_consumitions != null &&
                index === indexExtra &&
                extra.times_can_be_consumed > 1
              ) {
                return [...Array(extra.times_can_be_consumed)]?.map(
                  (_, i) => `extra${index}-item${i}`
                );
              } else {
                return [];
              }
            });
          }
          return [...prevSelectedOption, optionValue, ...relatedItems];
        }
      });
    }
  };
  const handleRegisterButtonClick = async (e, selectedOptions) => {
    setOpenCheck(false);
    e.stopPropagation();
    try {
      let response = null;
      if (selectedOptions.includes("ticket") && validateAssistan.consumed_at == null) {
        response = await validateTicket(validateAssistan.order_ticket_id);
      }
      //limpiamos los bonos para que no haya peticiones de mas
      const prefixes = new Set(
        selectedOptions
          .filter((option) => option.includes("-item"))
          .map((option) => option.split("-")[0])
      );
      const filteredOptions = selectedOptions.filter((option) => !prefixes.has(option));

      for (let i = 0; i < filteredOptions.length; i++) {
        if (filteredOptions[i].startsWith("extra")) {
          const index = parseInt(filteredOptions[i].replace("extra", ""), 10);
          const { extra_consumed, extra_id } = validateAssistan.extras[index];
          if (extra_consumed == null) {
            const extraResponse = await validateExtra(extra_id);
            if (response === null) {
              response = extraResponse;
            }
          }
        }
      }
      toastMessageSuccess(t("EDIT_SUCCESS"));
      setTimeout(() => {
        refetchAssistants();
      }, 1500);
    } catch (error) {
      toastMessageError(error.response?.data?.error ?? t("EDIT_ERROR"));
    } finally {
      setSelectedOption("");
      setOpenCheck(false);
    }
  };

  //download options
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpenPopover = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClosePopover = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  //PDF with essential information
  const headerPdf = [
    t("NAME"),
    t("SURNAME"),
    t("CUSTOMER_PHONECODE"),
    t("CUSTOMER_PHONE"),
    t("EMAIL"),
    t("PHONE_CODE"),
    t("PHONE"),
    t("TICKET"),
    t("EXTRAS"),
  ];

  const tableDataPdf = assistants.map((item) => [
    item.client_name ?? item.buyer_name ?? "",
    item.client_surname ?? item.buyer_surname ?? "",
    item.customer_phone_code ?? item.customer_phone_code ?? "",
    item.customer_phone ?? item.customer_phone ?? "",
    item.client_email ? item.client_email : "",
    item.client_phone_code ? item.client_phone_code : "",
    item.client_phone ? item.client_phone : "",
    item.ticket_name ?? "",
    item.extras
      .map((extra) => {
        return extra.extra_name + "\n";
      })
      .join(""),
  ]);
  const generatePDF = () => {
    const doc = new jsPDF();

    doc.autoTable({
      head: [headerPdf],
      body: tableDataPdf,
    });
    doc.save("assistants.pdf");
  };
  //CSV WITH ALL INFORMATION
  const tableHeadersCsv = [
    t("NAME"),
    t("SURNAME"),
    t("BIRTHDAY"),
    t("CUSTOMER_PHONECODE"),
    t("CUSTOMER_PHONE"),
    t("NUMBER_ORDER"),
    t("TICKET_ID"),
    t("TICKET_NAME"),
    t("TICKET_PRICE"),
    t("CREATED_AT"),
    t("CONSUMED_AT"),
    t("PAYMENT_TYPE"),
    t("CUSTOMER_EMAIL"),
    t("CUSTOMER_DNI_TYPE"),
    t("CUSTOMER_DNI"),
    t("CUSTOMER_DNI_EXPIRATION"),
    t("CUSTOMER_NATIONALITY"),
    t("CUSTOMER_GENDER"),
    t("AFFILIATED_STATUS"),
    t("ORDER_TICKET_ID"),
    t("EMAIL"),
    t("PHONE_CODE"),
    t("PHONE"),
    t("NATIONALITY"),
    t("GENDER"),
    t("DOCUMENT_TYPE"),
    t("NIE"),
    t("EXPIRATION_NIE"),
    t("EXPEDITION_NIE"),
    t("TICKET_PRICE"),
  ];

  const numberExtras = assistants.map((item) => item.extras.length);
  const numberExtrasMax = Math.max(...numberExtras);
  for (let i = 0; i < numberExtrasMax; i++) {
    const num = i + 1;
    tableHeadersCsv.push("Extra" + num + ": " + t("EXTRA_NAME"));
    tableHeadersCsv.push("Extra" + num + ": " + t("PRICE"));
    tableHeadersCsv.push("Extra" + num + ": " + t("CONSUMED_AT"));
    tableHeadersCsv.push("Extra" + num + ": " + t("ID"));
  }

  const tableDataCsv = assistants.map((item) => {
    const row = [
      item.client_name ?? item.buyer_name,
      item.client_surname ?? item.buyer_surname,
      item.client_birth_date ? new Date(item.client_birth_date).toLocaleDateString() : "",
      item.customer_phone_code,
      item.customer_phone,
      item.order_id,
      item.ticket_id,
      item.ticket_name,
      item.ticket_price,
      item.created_at ? new Date(item.created_at).toLocaleDateString() : "",
      item.consumed_at ? new Date(item.consumed_at).toLocaleDateString() : "",
      item.payment_type,
      item.customer_email,
      item.customer_dni_type,
      item.customer_dni,
      item.customer_dni_expiration
        ? new Date(item.customer_dni_expiration).toLocaleDateString()
        : "",
      item.customer_nationality,
      item.customer_gender,
      item.affiliated_status,
      item.order_ticket_id,
      item.client_email,
      item.client_phone_code,
      item.client_phone,
      item.client_nationality,
      item.client_gender,
      item.client_document_type,
      item.client_document_id,
      item.client_document_expiry_date
        ? new Date(item.client_document_expiry_date).toLocaleDateString()
        : "",
      item.client_document_expedition_date
        ? new Date(item.client_document_expedition_date).toLocaleDateString()
        : "",
      item.final_payed_price,
    ];
    item.extras.forEach((extra) => {
      row.push(extra.extra_name);
      row.push(extra.extra_price);
      row.push(
        extra.extra_consumed_at
          ? new Date(extra.extra_consumed_at).toLocaleDateString()
          : "No consumido"
      );
      row.push(extra.extra_id);
    });
    return row;
  });
  //END DOWNLOAD PDF & CSV OPTIONS

  const soldTicketsPercent = (soldTickets, totalSales) => {
    let percentage = (soldTickets * 100) / totalSales;
    return parseFloat(percentage.toFixed(2));
  };

  useEffect(() => {
    refetchAssistants();
  }, [props.qr]);

  //SORT ASSISTANTS BY NAME, SURNAME, TICKET, EXTRAS, CREATED_AT
  const compareFunctions = {
    name: (a, b) =>
      a.client_name !== null
        ? a.client_name.localeCompare(b.client_name)
        : a.buyer_name.localeCompare(b.buyer_name),
    surname: (a, b) =>
      a.client_surname !== null
        ? a.client_surname.localeCompare(b.client_surname)
        : a.buyer_surname.localeCompare(b.buyer_surname),
    ticket: (a, b) => a.ticket_name.localeCompare(b.ticket_name),
    extras: (a, b) => a.extras.length - b.extras.length,
    created_at: (a, b) => new Date(a.created_at) - new Date(b.created_at),
  };

  const handleSortAssistants = (name, alphabetical) => {
    const arrayToSort = filteredAssistants.length > 0 ? [...filteredAssistants] : [...assistants];
    const compareFunction = compareFunctions[name];
    const sortedAssistants = arrayToSort.sort((a, b) => {
      let compareResult = compareFunction(a, b);
      return alphabetical ? compareResult : -compareResult;
    });
    setFilteredAssistants(sortedAssistants);
    setFilter({ active: true, last: name, alphabetical: alphabetical ? "asc" : "desc" });
  };

  //Comprobamos si el pago esta completo
  const isCompletePayment = (assistant) => !!assistant.payed_at;

  //Calculamos el numero de pagos incompletos
  useEffect(() => {
    if (isDoublePayment && assistants) {
      const numberOfIncompletePayments = assistants.filter(
        (assistant) => !isCompletePayment(assistant)
      ).length;
      setIncompletePayment(numberOfIncompletePayments);
    }
  }, [assistants]);
  //Lista a mostrar
  const currentAssistants =
    search === "" && (selectedTicket === "" || selectedTicket === "0") && !filter.active
      ? assistants
      : filteredAssistants;

  //BUTTONS TO SORT THE ASSISTANTS
  const renderArrowButtons = (id) => {
    return (
      <ButtonGroup sx={{ fontSize: "5px" }}>
        <IconButton
          aria-label="sort"
          size="small"
          name={"asc"}
          onClick={() => handleSortAssistants(id, true)}
          className={
            id === filter.last && filter.alphabetical === "asc" && filter.active
              ? "oniria-btn-check-validated"
              : "oniria-btn-check"
          }
          sx={{ height: "20px", width: "20px" }}
        >
          <ExpandLess />
        </IconButton>
        <IconButton
          aria-label="sort"
          size="small"
          name={"desc"}
          onClick={() => handleSortAssistants(id)}
          className={
            id === filter.last && filter.alphabetical === "desc" && filter.active
              ? "oniria-btn-check-validated"
              : "oniria-btn-check"
          }
          sx={{ height: "20px", width: "20px" }}
        >
          <ExpandMore />
        </IconButton>
      </ButtonGroup>
    );
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress sx={{ color: "var(--secondary-color)" }} />
      </Box>
    );
  }
  return (
    <>
      <Grid
        container
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mt: 3 }}
      >
        <Grid item xs={11} sx={{ display: "flex", flexDirection: "column" }}>
          {/* TICKETS SOLD */}
          <Box
            component={Paper}
            elevation={3}
            sx={{
              py: 2,
              px: 1,
              mb: 2,
              border: "1px solid #E4E4E4",
              borderRadius: "15px",
              maxWidth: "100%",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mb: 1 }}
            >
              <Typography variant="subtitle2">
                {numberValidated}/{totalSales} {t("REGISTER_ASSISTANTS_STOCK")}
              </Typography>
              <Typography variant="subtitle2">
                {soldTicketsPercent(numberValidated, totalSales)}% {t("PERCENTAGE_ARRIVED")}
              </Typography>
            </Grid>
            <LinearProgress
              variant="determinate"
              value={soldTicketsPercent(numberValidated, totalSales)}
              sx={{
                width: "100%",
                height: 8,
                mb: 1,
                backgroundColor: "var(--oniria-sky)",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "var(--secondary-color)",
                },
              }}
            />
          </Box>
          {ticketsTypes && ticketsTypes?.length > 0 && (
            <Grid container justifyContent="center">
              <Grid
                container
                item
                justifyContent="space-between"
                alignItems="center"
                rowGap={1}
                xs={12}
                sm={10}
                md={7}
                lg={5}
                mb={1}
                component={Paper}
                sx={{
                  padding: "10px",
                  border: "1px solid #E4E4E4",
                  borderRadius: "15px",
                  maxWidth: "100%",
                }}
                elevation={3}
              >
                {ticketsTypes?.map((ticket, index) => (
                  <Grid item key={index} container sx={{ borderBottom: "1px solid lightgrey" }}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">{ticket.ticket_name}: </Typography>
                    </Grid>
                    <Grid item xs={3} sm={4} sx={{ textAlign: "end" }}>
                      <Typography variant="subtitle2">
                        {ticket.sold_tickets}/{ticket.total_stock}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sm={2} sx={{ textAlign: "center" }}>
                      <Typography variant="subtitle2">{t("SOLD")}</Typography>
                    </Grid>
                  </Grid>
                ))}
                {isDoublePayment() && (
                  <Grid item container sx={{ borderBottom: "1px solid lightgrey" }}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">{t("INCOMPLETE_PAYMENTS")}: </Typography>
                    </Grid>
                    <Grid item xs={5} sx={{ textAlign: "end" }}>
                      <Typography variant="subtitle2">{incompletePayment}</Typography>
                    </Grid>
                  </Grid>
                )}
                {numberRefunds > 0 && (
                  <Grid item container sx={{ borderBottom: "1px solid lightgrey" }}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">{t("REFUNDS")}: </Typography>
                    </Grid>
                    <Grid item xs={5} sx={{ textAlign: "end" }}>
                      <Typography variant="subtitle2">{numberRefunds}</Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {showDetailAssistant ? (
            <AssistantDetail
              ticketId={selectedAssistant.order_ticket_id}
              onBack={() => setShowDetailAssistant(false)}
            />
          ) : (
            <>
              <Grid
                container
                gap={{ xs: 1, lg: 2 }}
                sx={{ flexDirection: "row-reverse", alignItems: "space-between", mb: 5, mt: 2 }}
              >
                <Grid item mb={2}>
                  <Button
                    className="oniria-btn"
                    variant="contained"
                    size="medium"
                    sx={{
                      fontSize: "12px",
                      borderRadius: 3,
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                    onClick={handleOpenPopover}
                  >
                    <CloudDownloadIcon />
                    {t("DOWNLOAD_LIST")}
                  </Button>
                  <Popover
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    id={popoverId}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <ButtonGroup
                      variant="contained"
                      orientation="vertical"
                      aria-label="vertical button group"
                    >
                      <Button
                        disableElevation
                        fullWidth
                        variant="contained"
                        className="popover-btn"
                        aria-label="download csv"
                      >
                        <CSVLink
                          data={tableDataCsv}
                          filename={"assistants.csv"}
                          target="_blank"
                          separator={";"}
                          headers={tableHeadersCsv}
                          className="csv-link"
                          style={{ textAlign: "center" }}
                        >
                          {t("CSV_FORMAT")}
                          <p style={{ fontSize: "10px" }}>{t("INFO_FULL")}</p>
                        </CSVLink>
                      </Button>
                      <Button
                        disableElevation
                        fullWidth
                        variant="contained"
                        className="popover-btn"
                        aria-label="download pdf"
                        onClick={generatePDF}
                        style={{ textAlign: "center", display: "flex", flexDirection: "column" }}
                      >
                        {t("PDF_FORMAT")}
                        <p style={{ fontSize: "10px" }}>{t("INFO_BASE")}</p>
                      </Button>
                    </ButtonGroup>
                  </Popover>
                </Grid>
                <Grid item xs={5.3} sm={3} lg={2} mb={2}>
                  <FormControlM fullWidth sx={focusColor}>
                    <InputLabel id="demo-simple-select-label" size="small">
                      {t("TICKET_TYPE")}
                    </InputLabel>
                    <Select
                      value={selectedTicket}
                      onChange={handleChangeTypeTicket}
                      label={t("TICKET_TYPE")}
                      sx={{ fontSize: "14px", borderRadius: 3, height: "40px" }}
                    >
                      <MenuItem value="0">{t("ALLS")}</MenuItem>
                      {ticketsTypes?.map((ticket, index) => (
                        <MenuItem key={index} value={ticket.ticket_name}>
                          {ticket.ticket_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControlM>
                </Grid>
                <Grid item mb={2} xs={12} md={5} container>
                  <SearchIcon sx={{ height: 35, width: 35, color: "var(--secondary-color)" }} />
                  <TextField
                    label={t("SEARCH")}
                    size="small"
                    onChange={(event) => debounceSearch(event)}
                    sx={[focusColor, { width: "85%" }]}
                  />
                </Grid>
              </Grid>
              <Grid item mb={25}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                        <TableCell>
                          <Grid
                            container
                            flexDirection="row"
                            wrap="nowrap"
                            alignItems="center"
                            columnGap={1}
                          >
                            {t("NAME")}
                            {renderArrowButtons("name")}
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid
                            container
                            flexDirection="row"
                            wrap="nowrap"
                            alignItems="center"
                            columnGap={1}
                          >
                            {t("SURNAME")}
                            {renderArrowButtons("surname")}
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid
                            container
                            flexDirection="row"
                            wrap="nowrap"
                            alignItems="center"
                            columnGap={1}
                            whiteSpace="nowrap"
                          >
                            {t("TICKET_TYPE")}
                            {renderArrowButtons("ticket")}
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid
                            container
                            flexDirection="row"
                            wrap="nowrap"
                            alignItems="center"
                            columnGap={1}
                          >
                            {t("EXTRAS")}
                            {renderArrowButtons("extras")}
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid
                            container
                            flexDirection="row"
                            wrap="nowrap"
                            alignItems="center"
                            columnGap={1}
                            whiteSpace="nowrap"
                          >
                            {t("CREATED_AT")}
                            {renderArrowButtons("created_at")}
                          </Grid>
                        </TableCell>
                        <TableCell>{t("VALIDATE")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentAssistants.map((assistant, index) => (
                        <TableRow key={index} sx={{ cursor: "pointer" }}>
                          <TableCell onClick={() => handleRowClick(assistant)}>
                            {assistant.client_name ? assistant.client_name : assistant.buyer_name}
                          </TableCell>
                          <TableCell onClick={() => handleRowClick(assistant)}>
                            {assistant.client_surname
                              ? assistant.client_surname
                              : assistant.buyer_surname}
                          </TableCell>
                          <TableCell onClick={() => handleRowClick(assistant)}>
                            {assistant.ticket_name}
                          </TableCell>
                          <TableCell onClick={() => handleRowClick(assistant)}>
                            {assistant.extras !== null &&
                              assistant.extras.map((extra, index) => (
                                <React.Fragment key={index}>
                                  {extra.extra_name}
                                  {index < assistant.extras.length - 1 && <br />}
                                </React.Fragment>
                              ))}
                          </TableCell>
                          <TableCell onClick={() => handleRowClick(assistant)}>
                            {new Date(assistant.created_at).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            {assistant.refunded_at ? (
                              <Grid container sx={{ cursor: "default" }}>
                                <CurrencyExchange
                                  sx={{ color: "var(--secondary-color)", fontSize: "2rem" }}
                                />
                              </Grid>
                            ) : isCompletePayment(assistant) ? (
                              <IconButton
                                disabled={
                                  assistant.extras.every(
                                    (extra) => extra.extra_consumed_at !== null
                                  ) && assistant.consumed_at !== null
                                }
                                aria-label="check"
                                className={
                                  assistant.extras.every(
                                    (extra) => extra.extra_consumed_at !== null
                                  ) && assistant.consumed_at !== null
                                    ? "oniria-btn-check-validated"
                                    : (assistant.extras.some(
                                        (extra) => extra.extra_consumed_at !== null
                                      ) &&
                                        assistant.extras.length > 0) ||
                                      assistant.consumed_at !== null
                                    ? "oniria-btn-icon-gold"
                                    : "oniria-btn-check"
                                }
                                onClick={() => handleClickOpenCheck(assistant)}
                              >
                                <CheckIcon />
                              </IconButton>
                            ) : (
                              <Typography>{t("INCOMPLETE_PAYMENT")}</Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openCheck}
        onClose={handleCloseCheck}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="oniria-colorText" sx={{ fontWeight: "bolder" }}>
          {t("REGISTER_ATTENDANT")}
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "var(--secondary-color)",
                      "&.Mui-checked": {
                        color: "var(--secondary-color)",
                      },
                    }}
                    value="ticket"
                    disabled={validateAssistan.consumed_at !== null}
                    checked={
                      validateAssistan.consumed_at !== null || selectedOption.includes("ticket")
                    }
                    onChange={handleCheckboxChange}
                  />
                }
                label={validateAssistan.ticket_name}
              />
              {validateAssistan.extras &&
                validateAssistan.extras.map((extra, index) => (
                  <>
                    <FormControlLabel
                      key={`extra${index}`}
                      control={
                        <Checkbox
                          sx={{
                            ml: 2,
                            color: "var(--secondary-color)",
                            "&.Mui-checked": {
                              color: "var(--secondary-color)",
                            },
                          }}
                          key={extra.extra_id}
                          disabled={
                            extra.extra_consumed_at !== null ||
                            (extra.extra_consumitions && extra.extra_consumitions.length > 0)
                          }
                          checked={
                            extra.extra_consumed_at !== null ||
                            selectedOption.includes(`extra${index}`)
                          }
                          value={`extra${index}`}
                          onChange={(e) => handleCheckboxChange(e, extra)}
                        />
                      }
                      label={extra.extra_name}
                    />
                    <Grid sx={{ ml: 4 }} container flexDirection="column">
                      {extra.extra_consumitions && extra.times_can_be_consumed > 1
                        ? [...Array(extra.times_can_be_consumed)].map((_, i) => (
                            <FormControlLabel
                              key={`extra-item-${i}`}
                              control={
                                <Checkbox
                                  sx={{
                                    ml: 2,
                                    color: "var(--secondary-color)",
                                    "&.Mui-checked": {
                                      color: "var(--secondary-color)",
                                    },
                                  }}
                                  key={index}
                                  disabled={extra.extra_consumitions[i]?.consumed_at != null}
                                  checked={
                                    extra.extra_consumitions[i]?.consumed_at != null ||
                                    selectedOption.includes(`extra${index}-item${i}`)
                                  }
                                  value={`extra${index}-item${i}`}
                                  onChange={(e) => handleCheckboxChange(e, extra)}
                                />
                              }
                              label={extra.extra_name}
                            />
                          ))
                        : null}
                    </Grid>
                  </>
                ))}
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "var(--secondary-color)",
                      "&.Mui-checked": {
                        color: "var(--secondary-color)",
                      },
                    }}
                    value="all"
                    checked={selectedOption.includes("all")}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Validar todo"
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
          >
            <Button
              className="oniria-btn-cancel"
              variant="contained"
              size="medium"
              sx={{ fontSize: "12px", borderRadius: 3, mr: 2 }}
              onClick={handleCloseCheck}
            >
              {t("CANCEL")}
            </Button>
            <Button
              type="submit"
              className="oniria-btn"
              variant="contained"
              size="medium"
              sx={{ fontSize: "12px", borderRadius: 3 }}
              onClick={(e) => handleRegisterButtonClick(e, selectedOption)}
            >
              {t("ACCEPT")}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ListAssistants;
