import Add from "@mui/icons-material/Add";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { reorder } from "../../../../constants/categories";
import { useQueryCategoriesDomain } from "../../../../hooks/queries/useQueryCategoriesDomain";
import { updateCategoriesDomain } from "../../../../services/categoriesServices";
import { CategoryItem } from "../../../premises/categories/CategoryItem";
import CustomDialog from "../../../shared/CustomDialog";
import { toastMessageError } from "../../../shared/toastMessage";
import { useCategoriesContext } from "../categoriesContext/CategoriesContext";
import AddCategoryDialog from "../newCategoryDialog/AddDialog";
import Preview from "../preview/Preview";
import CategoriesStyles from "./CategoriesStyles";

export const Categories = () => {
  const { t } = useTranslation();
  const { domainId } = useParams();
  const [selectCategoryId, setSelectCategoryId] = useState("");
  const [openAddCategory, setOpenAddCategory] = useState(false);
  //Queries
  const [openRemove, setOpenRemove] = useState(false);
  const { categories, setCategories } = useCategoriesContext();

  const handleSuccessCategories = (data) => {
    setCategories(data);
  };

  const { refetch: refetchCategories } = useQueryCategoriesDomain(
    domainId,
    handleSuccessCategories
  );

  //drag and drop
  const handleSetCategories = ({ destination, source }) => {
    if (!destination) return;
    const newItems = reorder(categories, source.index, destination.index);
    setCategories(newItems);
    try {
      updateCategoriesDomain(domainId, { updated_categories: newItems });
    } catch (e) {
      console.error("Error handleSetCategories", e);
      toastMessageError(t("ERROR_UPDATE_CATEGORIES"));
    }
  };
  const handleSetSubcategories = ({ destination, source }) => {
    const newCategories = categories.map((item) => {
      if (item.id !== destination.droppableId) return item;
      const newSubcategories = reorder(item.subcategories, source.index, destination.index);
      return { ...item, subcategories: newSubcategories };
    });
    setCategories(newCategories);
    try {
      updateCategoriesDomain(domainId, { updated_categories: newCategories });
    } catch (e) {
      console.error("Error handleSetSubcategories", e);
      toastMessageError(t("ERROR_UPDATE_SUBCATEGORIES"));
    }
  };

  const handleAddCategory = async (name, description) => {
    const categoryToAdd = {
      name: name,
      description: description,
      position: categories.length,
      domain_id: domainId,
    };
    const newCategories = [...categories, categoryToAdd];
    setCategories(newCategories);
    try {
      await updateCategoriesDomain(domainId, { updated_categories: newCategories });
    } catch (e) {
      console.error("Error handleAddCategory", e);
      toastMessageError(t("ERROR_ADD_CATEGORY"));
    } finally {
      refetchCategories();
    }
  };

  //Remove category from the list
  const handleOpenRemove = (categoryId) => {
    setSelectCategoryId(categoryId);
    setOpenRemove(true);
  };

  const handleRemoveCategory = async () => {
    setOpenRemove(false);
    const newCategories = categories.filter((item) => item.id !== selectCategoryId);
    const updatedCategories = newCategories.map((category, index) => ({
      ...category,
      position: index,
    }));
    setCategories(updatedCategories);
    try {
      await updateCategoriesDomain(domainId, { updated_categories: updatedCategories });
    } catch (e) {
      console.error("Error handleRemoveCategory", e);
    }
  };

  //Edit category
  const handleEditCategory = async (id, name, description) => {
    const newItems = categories.map((category) => {
      if (category.id === id) {
        category.name = name;
        category.description = description;
      }
      return category;
    });
    setCategories(newItems);
    try {
      await updateCategoriesDomain(domainId, { updated_categories: newItems });
    } catch (e) {
      console.error("Error handleSetCategories", e);
      toastMessageError(t("ERROR_UPDATE_CATEGORIES"));
    }
  };
  //SUBCATEGORIES
  const handleAddSubcategory = async (categoryId, newName, description, image) => {
    const newCategories = categories.map((item) => {
      if (item.id !== categoryId) return item;
      if (item.subcategories) {
        item.subcategories.push({
          name: newName,
          position: item.subcategories.length,
          description,
          image_url: image,
        });
      } else {
        item.subcategories = [{ name: newName, position: 0, description, image_url: image }];
      }
      return item;
    });
    setCategories(newCategories);
    try {
      await updateCategoriesDomain(domainId, { updated_categories: newCategories });
    } catch (e) {
      console.error("Error handleAddSubcategory", e);
      toastMessageError(t("ERROR_ADD_SUBCATEGORY"));
    }
  };
  const handleDeleteSubcategory = async (categoryId, index) => {
    const newCategories = categories.map((item) => {
      if (item.id !== categoryId) return item;
      item.subcategories.splice(index, 1);
      return item;
    });
    setCategories(newCategories);
    try {
      await updateCategoriesDomain(domainId, { updated_categories: newCategories });
    } catch (e) {
      console.error("Error handleDeleteSubcategory", e);
      toastMessageError(t("ERROR_DELETE_SUBCATEGORY"));
    }
  };

  const handleEditSubcategory = async (categoryId, index, newName, newDescription, newImage) => {
    const newCategories = categories.map((item) => {
      if (item.id !== categoryId) return item;
      item.subcategories[index].name = newName;
      item.subcategories[index].description = newDescription;
      item.subcategories[index].image_url = newImage;
      return item;
    });
    setCategories(newCategories);
    try {
      await updateCategoriesDomain(domainId, { updated_categories: newCategories });
    } catch (e) {
      console.error("Error handleEditSubcategory", e);
      toastMessageError(t("ERROR_EDIT_SUBCATEGORY"));
    }
  };
  //END SUBCATEGORIES

  const handleOpenAddCategory = () => {
    setOpenAddCategory(true);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Preview />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">{t("CONTENT")}</Typography>
        <Divider />
        <Typography>{t("CATEGORIES_INFO")}</Typography>
        <DragDropContext onDragEnd={handleSetCategories}>
          <Droppable droppableId="droppable-list">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: "flex", flexDirection: "column", gap: 4, width: "100%" }}
              >
                {categories.map((item, index) => (
                  <CategoryItem
                    key={item.id || index}
                    item={item}
                    index={index}
                    handleOpenRemove={handleOpenRemove}
                    handleSetSubcategories={handleSetSubcategories}
                    handleEditCategory={handleEditCategory}
                    handleAddSubcategory={handleAddSubcategory}
                    handleDeleteSubcategory={handleDeleteSubcategory}
                    handleEditSubcategory={handleEditSubcategory}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          onClick={handleOpenAddCategory}
          startIcon={<Add />}
          sx={{
            marginTop: 2,
            border: "1.5px solid var(--oniria-gold)",
            color: "gray",
            textTransform: "capitalize",
            "&:hover": {
              color: "#fff",
              backgroundColor: "var(--oniria-gold)",
            },
          }}
        >
          {t("ADD_CATEGORY")}
        </Button>
        <CategoriesStyles categories={categories} />
      </Grid>
      <AddCategoryDialog
        isOpen={openAddCategory}
        onClose={() => setOpenAddCategory(false)}
        handleAddCategory={handleAddCategory}
      />
      <CustomDialog
        isOpen={openRemove}
        onClose={() => setOpenRemove(false)}
        title={t("CONFIRM_REMOVE_CATEGORY")}
        onAccept={handleRemoveCategory}
      />
    </Grid>
  );
};
