import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CategoriesList } from "./components/premises/categories/CategoriesList";
import { ROLES } from "./constants/variables";
import "./index.css";
import AccountSuccess from "./pages/AccountSuccess";
import Billing from "./pages/Billing/Billing";
import BillingPlus from "./pages/Billing/BillingPlus";
import CalendarEvents from "./pages/CalendarEvents";
import CreateAccount from "./pages/CreateAccount";
import Error404 from "./pages/Error404";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import Statistics from "./pages/Statistics";
import Validate from "./pages/Validate";
import ValidateApp from "./pages/ValidateApp";
import VerifyUop from "./pages/VerifyUop";
import AdminDetail from "./pages/admins/AdminDetail";
import AdminList from "./pages/admins/AdminList";
import CreateAdmin from "./pages/admins/CreateAdmin";
import CreateAssistant from "./pages/events/CreateAssistant";
import EventDetail from "./pages/events/EventDetail";
import Events from "./pages/events/Events";
import CreateEvent from "./pages/events/partys/CreateEvent";
import CreateTrip from "./pages/events/trips/CreateTrip";
import Trips from "./pages/events/trips/Trips";
import CreateOrganization from "./pages/organizations/CreateOrganization";
import OrganizationDetail from "./pages/organizations/OrganizationDetail";
import Organizations from "./pages/organizations/Organizations";
import ValidationOrganizations from "./pages/organizations/ValidationOrganizations";
import CreatePremise from "./pages/premises/CreatePremise";
import MemberCard from "./pages/premises/MemberCard";
import PremiseDetail from "./pages/premises/PremiseDetail";
import PremisePendingUsers from "./pages/premises/PremisePendingUsers";
import Premises from "./pages/premises/Premises";
import ValidationPremises from "./pages/premises/ValidationPremises";
import RefundList from "./pages/refunds/RefundList";
import CreateRrppUser from "./pages/rrpps/CreateRrppUser";
import EditRrpp from "./pages/rrpps/EditRrpp";
import RrppDetail from "./pages/rrpps/RrppDetail";
import RrppsList from "./pages/rrpps/RrppsList";
import AppStyles from "./pages/styles/AppStyles";
import CreateUser from "./pages/users/CreateUser";
import PendingUsers from "./pages/users/PendingUsers";
import UserDetail from "./pages/users/UserDetail";
import UsersList from "./pages/users/UsersList";
import WhiteLabel from "./pages/whiteLabel/WhiteLabel";
import DomainAdmins from "./components/whiteLabel/editorUsers/DomainAdmins";

export const App = () => {
  const userInfo = useSelector((state) => state.userInfo);

  const authToken = userInfo.token;
  const rol = userInfo.roles;
  const canPublishTrips = userInfo.trips;

  const isAuthenticated = () => {
    if (authToken !== null && authToken !== "") {
      return true;
    } else return false;
  };

  const getUserRole = () => {
    if (rol !== null && rol.length > 0) {
      return rol;
    } else return null;
  };

  const PrivateRoute = ({ path, element, requiredRole }) => {
    const userIsAuthenticated = isAuthenticated();
    const userRole = getUserRole();

    if (!userIsAuthenticated) {
      return <Navigate to="/login" />;
    }
    if (requiredRole && !requiredRole.some((rol) => userRole.includes(rol))) {
      return <Navigate to="/404" />;
    }
    if (path === "trips" && !canPublishTrips && !userRole.includes(ROLES.SUPER_ADMIN)) {
      return <Navigate to="/404" />;
    }
    return element;
  };

  const ProtectedLogin = () => {
    const userIsAuthenticated = isAuthenticated();
    if (userIsAuthenticated) {
      return <Navigate to="/home" />;
    }
    return <Login />;
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ zIndex: "100001" }}
      />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<PrivateRoute element={<Home />} />} />
          <Route path="/home" exact element={<PrivateRoute element={<Home />} />} />
          <Route path="/calendar" exact element={<PrivateRoute element={<CalendarEvents />} />} />
          <Route path="/statistics" exact element={<PrivateRoute element={<Statistics />} />} />
          <Route
            path="/organizations"
            exact
            element={<PrivateRoute element={<Organizations />} />}
          />
          <Route
            path="/organizations/validation"
            exact
            element={
              <PrivateRoute
                element={<ValidationOrganizations />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN]}
              />
            }
          />
          <Route
            path="/create_organization"
            exact
            element={
              <PrivateRoute
                element={<CreateOrganization />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN]}
              />
            }
          />
          <Route
            path="/organization/:organizationId"
            exact
            element={<PrivateRoute element={<OrganizationDetail />} />}
          />
          <Route path="/premises" exact element={<PrivateRoute element={<Premises />} />} />
          <Route
            path="/premises/validation"
            exact
            element={
              <PrivateRoute element={<ValidationPremises />} requiredRole={[ROLES.SUPER_ADMIN]} />
            }
          />
          <Route
            path="/create_premise"
            exact
            element={
              <PrivateRoute
                element={<CreatePremise />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path="/premise/:premiseId"
            exact
            element={<PrivateRoute element={<PremiseDetail />} />}
          />
          <Route
            path="/premise/:premiseId/pending_users"
            exact
            element={
              <PrivateRoute
                element={<PremisePendingUsers />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.AFFILIATED]}
              />
            }
          />
          <Route
            path="/premise/:premiseId/member_card"
            exact
            element={
              <PrivateRoute
                element={<MemberCard />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.AFFILIATED]}
              />
            }
          />
          {/* <Route
            path="/premise/:premiseId/categories"
            exact
            element={<PrivateRoute element={<CategoriesList />} />}
          /> */}
          {/* <Route path="/premise/:premiseId/delegates" exact element={<PrivateRoute element={<Delegates />} />} /> */}
          <Route
            path="/admins"
            exact
            element={
              <PrivateRoute
                element={<AdminList />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.MANAGER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.AFFILIATED,
                ]}
              />
            }
          />
          <Route
            path="/admins/:adminId"
            exact
            element={
              <PrivateRoute
                element={<AdminDetail />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.MANAGER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.AFFILIATED,
                ]}
              />
            }
          />
          <Route
            path="/admins/create_admin"
            exact
            element={
              <PrivateRoute
                element={<CreateAdmin />}
                requiredRole={[
                  ROLES.SUPER_ADMIN,
                  ROLES.MANAGER_ADMIN,
                  ROLES.ADMIN,
                  ROLES.AFFILIATED,
                ]}
              />
            }
          />
          <Route
            path="/users"
            exact
            element={
              <PrivateRoute
                element={<UsersList />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.AFFILIATED]}
              />
            }
          />
          <Route
            path="/users/:userId"
            exact
            element={
              <PrivateRoute
                element={<UserDetail />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.AFFILIATED]}
              />
            }
          />
          <Route
            path="/users/create_user"
            exact
            element={
              <PrivateRoute
                element={<CreateUser />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.AFFILIATED]}
              />
            }
          />
          <Route
            path="/users/pending_users"
            exact
            element={
              <PrivateRoute
                element={<PendingUsers />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.AFFILIATED]}
              />
            }
          />
          <Route path="/events" exact element={<PrivateRoute element={<Events />} />} />
          <Route path="/create_event" exact element={<PrivateRoute element={<CreateEvent />} />} />
          <Route
            path="/create_event/:eventId"
            exact
            element={<PrivateRoute element={<CreateEvent />} />}
          />
          <Route path="/trips" exact element={<PrivateRoute element={<Trips />} path="trips" />} />
          <Route
            path="/event/:eventId"
            exact
            element={<PrivateRoute element={<EventDetail />} />}
          />
          <Route
            path="/event/:eventId/add_assistant"
            exact
            element={<PrivateRoute element={<CreateAssistant />} />}
          />
          <Route
            path="/create_trip"
            exact
            element={<PrivateRoute element={<CreateTrip path="trips" />} />}
          />
          <Route
            path="/create_trip/:eventId"
            exact
            element={<PrivateRoute element={<CreateTrip path="trips" />} />}
          />
          <Route
            path="/create_activity"
            exact
            element={<PrivateRoute element={<CreateTrip path="trips" />} />}
          />

          <Route
            path="/sales"
            exact
            element={
              <PrivateRoute
                element={<Billing />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path="/billing"
            exact
            element={
              <PrivateRoute
                element={<BillingPlus />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path="/refunds"
            exact
            element={
              <PrivateRoute
                element={<RefundList />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN]}
              />
            }
          />
          <Route path="/rrpps" exact element={<PrivateRoute element={<RrppsList />} />} />
          <Route path="/rrpps/:userId" exact element={<PrivateRoute element={<RrppDetail />} />} />
          <Route
            path="/rrpps/create_rrpp"
            exact
            element={<PrivateRoute element={<CreateRrppUser />} />}
          />
          <Route
            path="/rrpps/edit_rrpp/:userId"
            exact
            element={<PrivateRoute element={<EditRrpp />} />}
          />
          <Route
            path="/domains"
            element={
              <PrivateRoute
                element={<WhiteLabel />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.MANAGER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
          <Route
            path="/domains/admins/:domainId"
            element={<PrivateRoute element={<DomainAdmins />} requiredRole={[ROLES.SUPER_ADMIN]} />}
          />
          <Route
            path="/styles/:domainId"
            element={
              <PrivateRoute
                element={<AppStyles />}
                requiredRole={[ROLES.SUPER_ADMIN, ROLES.SUPER_ADMIN, ROLES.ADMIN]}
              />
            }
          />
        </Route>

        <Route path="*" element={<PrivateRoute element={<Error404 />} />} />
        <Route path="/login" exact element={<ProtectedLogin />} />
        <Route path="/forgot" exact element={<ForgotPassword />} />
        <Route path="/createaccount" exact element={<CreateAccount />} />
        <Route path="/404" exact element={<Error404 />} />
        <Route path="/account_success" exact element={<AccountSuccess />} />
        <Route path="/reset_password" exact element={<ResetPassword />} />
        <Route path="/verify-dashboard" exact element={<Validate />} />
        <Route path="/verify-app" exact element={<ValidateApp />} />
        <Route path="/verify-uop" exact element={<VerifyUop />} />
      </Routes>
    </>
  );
};
