import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../../shared/textFieldStyle";

const AddCategoryDialog = ({ isOpen, onClose, handleAddCategory }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleAcceptDialog = () => {
    handleAddCategory(name, description);
    setName("");
    setDescription("");
    onClose();
  };

  const handleClose = () => {
    setName("");
    setDescription("");
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)", textAlign: "center" }}>
          {t("ADD_CATEGORY")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "black", textAlign: "center", mb: 2 }}>
            {t("ADD_CATEGORY_EXPLANATION")}
          </DialogContentText>
          <TextField
            id="outlined-basic"
            label={t("NAME")}
            variant="outlined"
            sx={focusColor}
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
          />
          <TextField
            id="outlined-basic"
            label={t("DESCRIPTION")}
            variant="outlined"
            sx={[focusColor, { marginTop: 2 }]}
            value={description || ""}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
        >
          <Button
            type="submit"
            className="oniria-btn-cancel"
            variant="contained"
            size="medium"
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={handleClose}
          >
            {t("CANCEL")}
          </Button>
          <Button
            type="submit"
            className="oniria-btn"
            variant="contained"
            size="medium"
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={handleAcceptDialog}
          >
            {t("ACCEPT")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCategoryDialog;
