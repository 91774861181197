import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { createTrip, createTripErrors } from "../../../classes/tripClass";
import Header from "../../../components/Header";
import Extras from "../../../components/events/Extras";
import Tickets from "../../../components/events/Tickets";
import Details from "../../../components/events/details/Details";
import MoreInformation from "../../../components/events/moreInformation/MoreInformation";
import {
  cleanObjectOfVoidFields,
  transformObjectWithUrls,
} from "../../../components/shared/FormsValidator";
import { Loading } from "../../../components/shared/Loading";
import { toastMessageError, toastMessageSuccess } from "../../../components/shared/toastMessage";
import useEventLogic from "../../../hooks/useEventLogic";
import { createEvent } from "../../../services/eventsServices";

const CreateTrip = () => {
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("TRAVELS"),
      link: "/trips",
    },
    {
      name: t("CREATE_TRAVEL"),
      link: "/create_trip",
    },
  ];
  const steps = [t("DETAILS"), t("MORE_INFO"), t("TICKETS"), t("EXTRAS")];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [formData, setFormData] = useState(JSON.parse(JSON.stringify(createTrip)));
  const [formParamsErrors, setFormParamsErrors] = useState(
    JSON.parse(JSON.stringify(createTripErrors))
  );
  const [buttonClicked, setButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { eventId } = useParams();
  useEventLogic(eventId, formData, setFormData, setFormParamsErrors, setLoading);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const setFormParams = (objectName, field, e, atribute = "value") => {
    let object = formData[objectName];
    object[field] = e.target[atribute];
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormParamsNotEvent = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrors = (objectName, field, value) => {
    let object = formParamsErrors[objectName];
    object[field] = value;
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const setFormErrorsTicketsAndExtras = (objectName, value) => {
    setFormParamsErrors((prev) => ({
      ...prev,
      [objectName]: value,
    }));
  };

  const setFormParamsValue = (objectName, field, value) => {
    let object = formData[objectName];
    object[field] = value;
    setFormData((prev) => ({
      ...prev,
      [objectName]: object,
    }));
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    const { details, moreInformation, tickets, extras, id } = formData;
    //
    try {
      //
      const tripComplete = JSON.parse(JSON.stringify({ ...details, ...moreInformation }));
      tripComplete.description =
        typeof tripComplete.description !== "string"
          ? JSON.stringify(tripComplete.description)
          : tripComplete.description;
      tripComplete.not_included_options = JSON.stringify(tripComplete.not_included_options);
      tripComplete.additional_info = JSON.stringify(tripComplete.additional_info);
      tripComplete.rating = tripComplete.rating ? parseFloat(tripComplete.rating || 0) : null;

      tripComplete.event_type = "trip";
      tripComplete.min_age = parseInt(tripComplete.min_age);
      tripComplete.latitude = parseFloat(tripComplete.latitude);
      tripComplete.longitude = parseFloat(tripComplete.longitude);
      tripComplete.limit_tickets_order = parseInt(tripComplete.limit_tickets_order);
      //Double payment
      tripComplete.has_double_payment =
        tripComplete.has_double_payment === 1 || tripComplete.has_double_payment === true;
      tripComplete.first_payment_percentage = parseFloat(tripComplete.first_payment_percentage);
      tripComplete.countries = parseInt(tripComplete.countries || 0);
      tripComplete.cities = parseInt(tripComplete.cities || 0);
      if (
        typeof tripComplete.slider_urls === "string" ||
        (typeof tripComplete.slider_urls === "object" && tripComplete.slider_urls.mime)
      ) {
        tripComplete.slider_urls = [tripComplete.slider_urls];
      }
      //
      tripComplete.total_capacity = 0;
      for (let i = 0; i < tickets.length; i++) {
        tripComplete.total_capacity += parseInt(tickets[i].initial_stock);
      }
      //
      const optionValues = [];
      for (let i = 0; i < tripComplete.included_options.length; i++) {
        optionValues.push(tripComplete.included_options[i].value);
      }
      tripComplete.included_options = JSON.stringify(optionValues);
      //
      for (let i = 0; i < tripComplete.itineraries.length; i++) {
        tripComplete.itineraries[i].position = i;
        tripComplete.itineraries[i].event_id = "";
        tripComplete.itineraries[i].id = "";
      }
      //
      for (let i = 0; i < tripComplete.blocks.length; i++) {
        if (!tripComplete.blocks[i].event_id) {
          tripComplete.blocks[i].event_id = id;
        }
        if (typeof tripComplete.blocks[i].images_urls === "string") {
          tripComplete.blocks[i].images_urls = [tripComplete.blocks[i].images_urls];
        }
        tripComplete.blocks[i].position = i;
        //tripComplete.blocks[i].description = JSON.stringify(tripComplete.blocks[i].description);
      }
      //
      const tripToSend = await transformObjectWithUrls(tripComplete);
      //
      tripToSend.tickets = [];
      for (let i = 0; i < tickets.length; i++) {
        const ticketCopy = await JSON.parse(JSON.stringify(tickets[i]));
        const ticketToSend = await cleanObjectOfVoidFields(ticketCopy);
        tripComplete.latitude =
          !isNaN(tripComplete.latitude) && tripComplete.latitude !== "" ? tripComplete.latitude : 0;
        tripComplete.longitude =
          !isNaN(tripComplete.longitude) && tripComplete.longitude !== ""
            ? tripComplete.longitude
            : 0;
        // TODO: Revisar como gestionamos estos atributos
        ticketToSend.initial_stock = parseInt(ticketToSend.initial_stock);
        ticketToSend.price = parseFloat(ticketToSend.price);
        ticketToSend.iva = parseInt(ticketToSend.iva);
        ticketToSend.only_international = false;
        ticketToSend.only_in_app = ticketToSend.only_in_app === 1;
        delete ticketToSend.stock;
        //
        if (ticketToSend.international_discount)
          ticketToSend.international_discount = parseFloat(ticketToSend.international_discount);
        if (ticketToSend.early_payment_discount)
          ticketToSend.early_payment_discount = parseFloat(ticketToSend.early_payment_discount);
        if (ticketToSend.promotional_code_discount)
          ticketToSend.promotional_code_discount = parseFloat(
            ticketToSend.promotional_code_discount
          );
        // PROMO CODE DISCOUNTS
        for (let k = 0; k < ticketToSend.promotional_code_discounts.length; k++) {
          ticketToSend.promotional_code_discounts[k].discount = parseFloat(
            ticketToSend.promotional_code_discounts[k].discount
          );
          ticketToSend.promotional_code_discounts[k].is_active = ticketToSend
            .promotional_code_discounts[k].is_active
            ? true
            : false;
          delete ticketToSend.promotional_code_discounts[k].id;
        }
        //
        for (let j = 0; j < ticketToSend.group_discounts.length; j++) {
          ticketToSend.group_discounts[j].min_size = parseInt(
            ticketToSend.group_discounts[j].min_size
          );
          ticketToSend.group_discounts[j].max_size = parseInt(
            ticketToSend.group_discounts[j].max_size
          );
          ticketToSend.group_discounts[j].discount = parseFloat(
            ticketToSend.group_discounts[j].discount
          );
          ticketToSend.group_discounts[j].ticket_id = tickets[i].id;
          delete ticketToSend.group_discounts[j].id;
        }
        //
        tripToSend.tickets.push(ticketToSend);
      }
      //

      //
      tripToSend.extras = [];
      for (let i = 0; i < extras.length; i++) {
        const extraCopy = await JSON.parse(JSON.stringify(extras[i]));
        const extraToSend = await cleanObjectOfVoidFields(extraCopy);
        extraToSend.initial_stock = parseInt(extraToSend.initial_stock);
        extraToSend.price = parseFloat(extraToSend.price);
        extraToSend.stock_by_ticket_max = parseInt(extraToSend.stock_by_ticket_max);
        extraToSend.is_visible = extraToSend.is_visible === 1;
        extraToSend.times_can_be_consumed = parseInt(extraToSend.times_can_be_consumed ?? 1);
        tripToSend.extras.push(extraToSend);
      }
      //
      console.log("Trip to send: ", tripToSend);
      await createEvent(tripToSend);
      //
      toastMessageSuccess(t("EDIT_SUCCESS"));
      setTimeout(() => {
        navigate("/trips");
      }, 1500);
    } catch (error) {
      console.log("Submit error: ", error);
      toastMessageError(error.response.data.error || t("EDIT_ERROR"));
    }
  };

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const isLastStep = () => {
    return activeStep === 3;
  };

  const formController = {
    formData,
    setFormData,
    formParams: setFormParams,
    next: handleNext,
    back: handleBack,
    validation: formParamsErrors,
    isFirstStep,
    isLastStep,
    finish: handleSubmit,
    setFormParamsNotEvent,
    setFormErrors,
    setFormErrorsTicketsAndExtras,
    setFormParamsValue,
  };

  const addComponent = (activeStep) => {
    return activeStep === 0 ? (
      <Details
        formController={formController}
        typeTrip={true}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
      />
    ) : activeStep === 1 ? (
      <MoreInformation
        formController={formController}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
      />
    ) : activeStep === 2 ? (
      <Tickets
        formController={formController}
        eventType={"trip"}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
      />
    ) : (
      <Extras
        formController={formController}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
      />
    );
  };

  if (loading) return <Loading />;

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_CREATE_TRIP")} />
      <Grid
        item
        xs={12}
        sm={10}
        lg={12}
        sx={{ mb: 5, display: "flex", flexDirection: "row", justifyContent: "initial" }}
      >
        <Grid container sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <Grid item xs={12} sm={5} md={9}>
            {!isMobile ? (
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ color: "var(--secondary-color)" }}>
                    <ArrowBackIos fontSize="small" sx={{ color: "var(--secondary-color)" }} />
                  </Button>
                )}
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step
                        sx={{
                          "& .MuiStepLabel-root .Mui-disabled": { color: "var(--secondary-color)" },
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiSvgIcon-root": { color: "var(--secondary-color)" },
                          "& .MuiSvgIcon-root, .Mui-disabled": { opacity: "80%" },
                          "& .MuiStepIcon-text ": { fill: "white" },
                          mx: 2,
                        }}
                        key={label}
                        {...stepProps}
                      >
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ color: "var(--secondary-color)" }}>
                    <ArrowBackIos fontSize="small" sx={{ color: "var(--secondary-color)" }} />
                  </Button>
                )}
                <Stepper connector activeStep={activeStep} sx={{ display: "block" }}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step
                        sx={{
                          "& .MuiStepLabel-root .Mui-disabled": { display: "none" },
                          "& .MuiStepLabel-root .Mui-completed": { display: "none" },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "var(--secondary-color)",
                            opacity: "100%",
                            fontWeight: "bolder",
                          },
                          "& .MuiSvgIcon-root, .Mui-disabled": { opacity: "80%" },
                          "& .MuiStepIcon-text ": { fill: "white" },
                          mx: 4,
                        }}
                        key={label}
                        {...stepProps}
                      >
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep !== 3 && (
                  <Button sx={{ color: "var(--secondary-color)" }} onClick={handleNext}>
                    <ArrowForwardIosIcon />
                  </Button>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>

      {addComponent(activeStep)}
    </>
  );
};

export default CreateTrip;
