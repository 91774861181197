import { Button, Grid, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import Partner from "./Partner";
import CustomDialog from "../../../shared/CustomDialog";
import { newPartner, newPartnerErrors } from "../../../../constants/partner";

const CardPartners = ({ partners, setPartners, partnersErrors, setPartnersErrors }) => {
  const { t } = useTranslation();

  const [openDelete, setOpenDelete] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleAddPartner = () => {
    setPartners([...partners, { ...newPartner }]);
    setPartnersErrors([...partnersErrors, { ...newPartnerErrors }]);
  };

  //For the drag and drop
  const handleSetPartnersPosition = ({ destination, source }) => {
    if (!destination) return;
    const newPartners = [...(partners || [])];
    const [removed] = newPartners.splice(source.index, 1);
    newPartners.splice(destination.index, 0, removed);

    const updatedPartners = newPartners.map((partner, index) => ({
      ...partner,
      position: index,
    }));

    setPartners(updatedPartners);
  };

  const handleOpenDelete = (e, index) => {
    e.stopPropagation();
    setOpenDelete(true);
    setSelectedIndex(index);
  };

  const handleDeletePartner = () => {
    const newPartners = [...partners];
    newPartners.splice(selectedIndex, 1);
    setPartners(newPartners);

    const newErrors = [...partnersErrors];
    newErrors.splice(selectedIndex, 1);
    setPartnersErrors(newErrors);
    setOpenDelete(false);
  };

  const handleEditPartner = (index, key, event, regex, errorMessage) => {
    const newPartners = [...partners];
    newPartners[index][key] = event.target.value;
    setPartners(newPartners);
    if (regex && partnersErrors[index]) {
      const newErrors = [...partnersErrors];
      newErrors[index][key] = regex.test(event.target.value) ? "" : errorMessage;
      setPartnersErrors(newErrors);
    }
  };

  return (
    <Grid item xs={12}>
      <Typography sx={{ fontSize: "18px", color: "black", mb: 2, mt: 2 }}>
        {t("SLIDER_INFORMATION")}
      </Typography>
      <DragDropContext onDragEnd={handleSetPartnersPosition}>
        <Droppable droppableId="droppable-partners-list">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%" }}
            >
              {(partners || []).map((partner, index) => (
                <Fragment key={index}>
                  <Partner
                    partner={partner}
                    index={index}
                    handleOpenDelete={handleOpenDelete}
                    handleEditPartner={handleEditPartner}
                    partnersErrors={partnersErrors}
                  />
                </Fragment>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Grid container justifyContent="center">
        <Button
          className="oniria-border-btn"
          onClick={handleAddPartner}
          sx={{ mt: 2, color: "#fff" }}
        >
          {t("ADD_SWEEP")}
        </Button>
      </Grid>
      <CustomDialog
        isOpen={openDelete}
        title={t("DELETE_PARTNER")}
        content={t("DELETE_PARTNER_MESSAGE")}
        onClose={() => setOpenDelete(false)}
        onAccept={handleDeletePartner}
      />
    </Grid>
  );
};

export default CardPartners;
