import QrCodeIcon from "@mui/icons-material/QrCode";
import { Box, Button, CircularProgress } from "@mui/material";
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 }, rememberLastUsedCamera: true };

const Scanner = ({ onResult, setQrResponse }) => {
  const [scanning, setScanning] = useState(false);
  const [html5QrCode, setHtml5QrCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const newHtml5QrCode = new Html5Qrcode("reader", {
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
    });
    setHtml5QrCode(newHtml5QrCode);
  }, []);

  const toggleScanning = () => {
    setQrResponse("");
    if (scanning) {
      stopScanning();
    } else {
      startScanning();
    }
  };

  const startScanning = async () => {
    setLoading(true);
    try {
      await html5QrCode.start({ facingMode: "environment" }, qrConfig, qrCodeSuccessCallback);
      setScanning(true);
    } catch (err) {
      console.log("Error starting scanner", err);
    } finally {
      setLoading(false);
    }
  };

  const stopScanning = () => {
    if (html5QrCode) {
      html5QrCode.stop().then(() => {
        html5QrCode.clear();
        setScanning(false);
      });
    }
  };

  const qrCodeSuccessCallback = (decodedText, _) => {
    stopScanning();
    //handleClickOpen();
    onResult(decodedText);
    setResult(decodedText);
  };
  // //Dialog
  // const handleClickOpen = () => {
  //     setOpen(true);
  // };
  // const handleClose = () => {
  //     setOpen(false);
  // };

  // const handleAccept = () => {
  //     setOpen(false);
  //     onResult(result);
  // };

  return (
    <div style={{ position: "relative", display: "flex", flexDirection: "column", width: "100%" }}>
      <Button
        className="oniria-btn-qrs"
        onClick={toggleScanning}
        variant="contained"
        sx={{
          width: "fit-content",
          fontSize: "12px",
          borderRadius: 3,
          display: "flex",
          alignItems: "center",
          gap: "4px",
          alignSelf: "flex-end",
          marginBottom: "10px",
        }}
      >
        <QrCodeIcon />
        {scanning ? t("QR_BUTTON_STOP") : t("QR_BUTTON_START")}
      </Button>
      <div id="reader" style={{ width: "100%", marginBottom: "10px" }} />
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="50px">
          <CircularProgress sx={{ color: "var(--secondary-color)" }} />
        </Box>
      )}
      {/* <Dialog
            open={open}
            onClose={handleClose}
          >
            <DialogContent>
              <DialogContentText sx={{color: 'black'}}>{t('VALIDATE_QR')}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{
              justifyContent: 'space-around',
              paddingBottom: "10px"
            }}>
              <Button 
                className='oniria-btn'
                variant="contained"
                size="medium"
                sx={{
                  fontSize: "12px",
                  borderRadius: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px'
                }} 
                onClick={handleClose}>
                  {t('CANCEL')}
              </Button>
              <Button 
                className='oniria-btn'
                variant="contained"
                size="medium"
                sx={{
                  fontSize: "12px",
                  borderRadius: 3,
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px'
                }} 
                onClick={handleAccept}>
                  {t('ACCEPT')}
              </Button>
            </DialogActions>
          </Dialog> */}
    </div>
  );
};

export default Scanner;
