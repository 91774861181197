export const newPartnerErrors = {
  title: "",
  short_description: "",
  image_url: "",
  description: "",
};

export const newPartner = {
  affiliated_card_id: "",
  title: "",
  short_description: "",
  image_url: "",
  description: "",
  position: "",
};
