import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { formatMuiDate } from "../../../constants/utils";
import { createCard, deleteCard, putCard } from "../../../services/premisesServices";
import CustomDialog from "../../shared/CustomDialog";
import ImageUpload from "../../shared/ImageUpload";
import InfoToolTip from "../../shared/InfoToolTip";
import { focusColor } from "../../shared/textFieldStyle";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";
import TextEditor from "../../textEditor/TextEditor";
import { checkRenderEditorJs } from "../../textEditor/tools";
import CardPartners from "./partners/CardPartners";
import { newPartnerErrors } from "../../../constants/partner";

const MemberCardForm = ({ card, isEdit }) => {
  const { t } = useTranslation();
  const { premiseId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: isEdit ? card.id : "",
    duration: isEdit ? card.duration : "forever",
    expirationDate:
      isEdit && card.duration === "expiration" ? formatMuiDate(card.renovation_date) : "",
    durationMonths: isEdit && card.duration === "duration" ? card.renovation_months : "",
    name: isEdit ? card.name : "",
    quantity: isEdit ? card.stock : "",
    price: isEdit ? card.price : "",
    image: isEdit ? card.image_url : "",
    check_list: isEdit && card?.check_list ? card.check_list?.split(";") : [],
    description: isEdit ? card.description : "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [partners, setPartners] = useState(card?.partners || []);
  const [partnersErrors, setPartnersErrors] = useState([]);

  useEffect(() => {
    if (isEdit && card) {
      setFormData({
        id: card.id,
        duration: card.duration,
        expirationDate: card.duration === "expiration" ? formatMuiDate(card.renovation_date) : "",
        durationMonths: card.duration === "duration" ? card.renovation_months : "",
        name: card.name,
        quantity: card.stock,
        price: card.price,
        image: card.image_url,
        check_list: card.check_list?.split(";"),
        description: card.description,
      });
      setPartners(card?.partners || []);

      const initialPartnersErrors = (card?.partners || []).map(() => ({ ...newPartnerErrors }));
      setPartnersErrors(initialPartnersErrors);
    }
  }, [card, isEdit]);

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!/^[A-Za-z\sáéíóúÁÉÍÓÚüÜñÑ]{0,50}$/.test(formData.name)) {
      newErrors.name = t("MAX_50");
    }
    if (formData.quantity < 0) {
      newErrors.quantity = t("STOCK_ERROR");
    }
    if (formData.price < 0) {
      newErrors.price = t("INCORRECT");
    }
    if (formData.duration === "duration" && formData.durationMonths < 0) {
      newErrors.durationMonths = t("INVALID_MONTHS");
    }
    setErrors(newErrors);
    console.log("newErrors", newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData, t]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleImageSelect = useCallback((url) => {
    if (Array.isArray(url)) {
      setFormData((prev) => ({
        ...prev,
        image: url.map((u) => ({ image: u.split(";")[1], mime: u.split(":")[1] })),
      }));
    } else {
      const urlParams = url?.split(";");
      setFormData((prev) => ({
        ...prev,
        image: { image: urlParams[1], mime: urlParams[0].split(":")[1] },
      }));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    const partnersToSend = partners.map((partner, index) => ({
      ...partner,
      affiliated_card_id: card?.id || "",
      position: index,
    }));

    const cardToSend = {
      premise_id: premiseId || card.premise_id,
      name: formData.name,
      stock: parseInt(formData.quantity),
      price: parseInt(formData.price),
      duration: formData.duration,
      initial_stock: parseInt(formData.quantity),
      renovation_months:
        formData.duration === "duration" ? parseInt(formData.durationMonths) : undefined,
      renovation_date: formData.duration === "expiration" ? formData.expirationDate : undefined,
      image_url: formData.image,
      check_list: formData.check_list?.join(";"),
      description:
        typeof formData.description !== "string"
          ? JSON.stringify(formData.description)
          : formData.description,
      partners: partnersToSend,
    };

    try {
      let response;
      if (isEdit) {
        response = await putCard(card.id, cardToSend);
      } else {
        response = await createCard(cardToSend);
      }
      if (response.result === "success") {
        toastMessageSuccess(isEdit ? t("EDIT_SUCCESS") : t("CREATE_SUCCESS"));
        setTimeout(() => navigate(ROUTES.PREMISES), 1500);
      }
    } catch (error) {
      toastMessageError(
        error.response?.data?.error || (isEdit ? t("EDIT_ERROR") : t("CREATE_ERROR"))
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleDeleteCard = async (e) => {
    e.preventDefault();
    try {
      const response = await deleteCard(card.id);
      if (response.result === "success") {
        toastMessageSuccess(t("DELETE_SUCCESS"));
        setTimeout(() => navigate(ROUTES.PREMISES), 1500);
      }
    } catch (error) {
      toastMessageError(error.response?.data?.error || t("DELETE_ERROR"));
    }
  };

  const handleChangeDescription = (data) => {
    setFormData((prev) => ({ ...prev, description: data }));
  };

  const isFormValid = useMemo(() => validateForm(), [validateForm]);

  return (
    <Grid container sx={{ mt: 8, mb: 5, flexDirection: "column", alignItems: "center" }}>
      <Grid item xs={11} md={9}>
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          {isEdit ? t("EDIT_MEMBER_CARD") : t("CREATE_MEMBER_CARD")}
        </Typography>
      </Grid>
      <Grid
        container
        sx={{ mb: 20, px: 1, color: "black", flexDirection: "column", alignItems: "center" }}
      >
        <Grid item xs={11} md={8}>
          <Box
            component={Paper}
            elevation={3}
            sx={{ py: 2, px: 3, borderRadius: "15px", border: "1px solid #E4E4E4" }}
          >
            <Box component="form" sx={{ mt: 2 }} onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    sx={focusColor}
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    label={t("NAME")}
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      sx={focusColor}
                      margin="normal"
                      required
                      type="number"
                      fullWidth
                      id="quantity"
                      name="quantity"
                      value={formData.quantity}
                      onChange={handleChange}
                      autoComplete="quantity"
                      autoFocus
                      label={t("CARD_QUANTITY")}
                      error={!!errors.quantity}
                      helperText={errors.quantity}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      sx={focusColor}
                      margin="normal"
                      required
                      fullWidth
                      id="price"
                      name="price"
                      value={formData.price}
                      onChange={handleChange}
                      error={!!errors.price}
                      helperText={errors.price}
                      autoComplete="price"
                      autoFocus
                      label={t("PRICE")}
                      type="number"
                      step="0.01"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={11}>
                  <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                    {t("CARD_DURATION")}
                    <InfoToolTip
                      text={t("CARD_DURATION_INFO")}
                      styles={{ whiteSpace: "pre-line", textAlign: "left" }}
                    />
                  </InputLabel>
                </Grid>
                <Grid container>
                  <RadioGroup name="duration" value={formData.duration} onChange={handleChange}>
                    <FormControlLabel
                      value="forever"
                      sx={{ "& .Mui-checked": { color: "var(--secondary-color)" } }}
                      control={<Radio sx={{ color: "var(--secondary-color)" }} />}
                      label={t("FOREVER")}
                    />
                    <FormControlLabel
                      value="expiration"
                      sx={{ "& .Mui-checked": { color: "var(--secondary-color)" } }}
                      control={<Radio sx={{ color: "var(--secondary-color)" }} />}
                      label={t("RENEWAL_DATE")}
                    />
                    {formData.duration === "expiration" && (
                      <TextField
                        sx={[focusColor, { mb: 2 }]}
                        type="date"
                        name="expirationDate"
                        value={formData.expirationDate}
                        onChange={handleChange}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                          max: "2100-12-31",
                        }}
                      />
                    )}
                    <FormControlLabel
                      value="duration"
                      sx={{ "& .Mui-checked": { color: "var(--secondary-color)" } }}
                      control={<Radio sx={{ color: "var(--secondary-color)" }} />}
                      label={t("MONTHS_DURATION")}
                    />
                    {formData.duration === "duration" && (
                      <TextField
                        sx={[focusColor, { mb: 2 }]}
                        type="number"
                        name="durationMonths"
                        value={formData.durationMonths}
                        onChange={handleChange}
                        error={!!errors.durationMonths}
                        helperText={errors.durationMonths}
                      />
                    )}
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                    {t("CARD_IMAGE")}
                    <InfoToolTip text={t("CARD_IMAGE_INFO")} />
                  </InputLabel>
                  <ImageUpload
                    onImageSelect={handleImageSelect}
                    multi={false}
                    url={formData.image}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                      {t("CARD_ADVANTAGES")}
                    </InputLabel>
                    {formData.check_list?.map((advantage, index) => (
                      <Grid
                        item
                        xs={12}
                        key={index}
                        container
                        alignItems="center"
                        columnSpacing={2}
                      >
                        <TextField
                          sx={[focusColor, { width: "80%", pl: 2 }]}
                          margin="normal"
                          id={`advantage-${index}`}
                          name={`advantage-${index}`}
                          value={advantage}
                          placeholder={t("ADVANTAGE")}
                          onChange={(e) => {
                            const newAdvantages = [...formData.check_list];
                            newAdvantages[index] = e.target.value;
                            setFormData((prev) => ({ ...prev, check_list: newAdvantages }));
                          }}
                        />
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() => {
                              const newAdvantages = [...formData?.check_list];
                              newAdvantages?.splice(index, 1);
                              setFormData((prev) => ({ ...prev, check_list: newAdvantages }));
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid container justifyContent="center">
                      <Button
                        className="oniria-border-btn"
                        onClick={() => {
                          setFormData((prev) => ({
                            ...prev,
                            check_list: [...(prev?.check_list || []), ""],
                          }));
                        }}
                        sx={{ mt: 2, color: "#fff" }}
                      >
                        {t("ADD_ADVANTAGE")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <CardPartners
                  partners={partners}
                  setPartners={setPartners}
                  partnersErrors={partnersErrors}
                  setPartnersErrors={setPartnersErrors}
                />
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <InputLabel sx={{ fontSize: "18px", color: "black" }}>
                    {t("CARD_DESCRIPTION")}
                  </InputLabel>
                  {checkRenderEditorJs("", formData.description) ? (
                    <TextEditor
                      data={formData.description}
                      setData={handleChangeDescription}
                      id="premise-card"
                    />
                  ) : (
                    <TextField
                      sx={focusColor}
                      margin="normal"
                      fullWidth
                      id="description"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      autoComplete="description"
                      label={t("CARD_DESCRIPTION")}
                      multiline
                      rows={4}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  {isEdit && (
                    <Button
                      className="oniria-btn-delete"
                      sx={{ mt: 3, mb: 2, mr: 2 }}
                      onClick={handleOpenDelete}
                    >
                      {t("DELETE")}
                    </Button>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="oniria-btn"
                    sx={{ mt: 3, mb: 2, color: "#fff" }}
                    disabled={!isFormValid || loading}
                  >
                    {loading ? t("SAVING") : isEdit ? t("SAVE") : t("CREATE")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <CustomDialog
        isOpen={openDelete}
        onClose={() => setOpenDelete(false)}
        title={t("DELETE_MEMBER_CARD")}
        content={t("DELETE_MEMBER_CARD_CONFIRM")}
        onAccept={handleDeleteCard}
      />
    </Grid>
  );
};

export default MemberCardForm;
