import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { useTranslation } from "react-i18next";
import ImageUpload from "../../shared/ImageUpload";
import { focusColor } from "../../shared/textFieldStyle";

function AddCategoryDialog({
  name,
  setName,
  description,
  setDescription,
  isOpen,
  onClose,
  onAccept,
  title,
  isSubcategory,
  image,
  setImage,
}) {
  const { t } = useTranslation();

  const handleSelectImage = (url) => {
    if (Array.isArray(url)) {
      const imageArray = [];
      for (let i = 0; i < url.length; i++) {
        const urlParams = url[i].split(";");
        const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
        imageArray.push(imageObject);
      }
      if (imageArray.length === 0) {
        setImage(null);
      } else {
        setImage(imageArray);
      }
    } else {
      const urlParams = url.split(";");
      const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
      setImage(imageObject);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)" }}>{title}</DialogTitle>
      <DialogContent>
        <TextField
          id="outlined-basic"
          label={t("NAME")}
          variant="outlined"
          sx={[focusColor, { mt: 2 }]}
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
        <TextField
          id="outlined-basic"
          label={t("DESCRIPTION")}
          variant="outlined"
          sx={[focusColor, { marginTop: 2 }]}
          value={description || ""}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
        {isSubcategory && (
          <ImageUpload
            url={image || ""}
            multi={false}
            onImageSelect={(e) => handleSelectImage(e)}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Button
          type="submit"
          className="oniria-btn-cancel"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onClose}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          className="oniria-btn"
          variant="contained"
          size="medium"
          sx={{ fontSize: "12px", borderRadius: 3 }}
          onClick={onAccept}
          disabled={name === ""}
        >
          {t("ACCEPT")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddCategoryDialog;
