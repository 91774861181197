import SearchIcon from "@mui/icons-material/Search";
import { Button, Grid, TextField } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { DataGrid as MuiXDataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { focusColor } from "../components/shared/textFieldStyle";
import useDebounce from "../hooks/useDebouncing";
import { SetRrpps, getAllRrppbyEvent } from "../services/rrppServices";
import { toastMessageError, toastMessageSuccess } from "./shared/toastMessage";
import { useTranslation } from "react-i18next";

function TablaRPP(props) {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [eventId, setEventId] = useState(props.eventId);
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const [total, setTotal] = useState(0);
  const [selectedChanges, setSelectedChanges] = useState([]);
  const [search, setSearch] = useState("");

  const { data } = useQuery({
    queryKey: ["Rrpps", page, search],
    queryFn: () => getAllRrppbyEvent(eventId, page, search),
    onSuccess: (data) => {
      setTotal(data.total);
    },
    retry: false,
  });

  useEffect(() => {
    if (data && data.data) {
      const rrpps = data.data;

      const newRows = rrpps.map((rrpp, index) => {
        const selectedChange = selectedChanges.find((change) => change.id === rrpp.id);
        const isActive = selectedChange !== undefined ? selectedChange.is_active : rrpp.is_active;
        return {
          id: index + 1,
          name: rrpp.name,
          username: rrpp.surname,
          email: rrpp.email,
          idrrpp: rrpp.id,
          selected: isActive,
          disabled: rrpp.disabled,
        };
      });

      setRows(newRows);

      const initialSelection = newRows.filter((row) => row.selected).map((row) => row.id);

      setSelectionModel(initialSelection);
    }
  }, [data]);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "username", headerName: "Username", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
  ];

  const handleGuardarCambios = async () => {
    try {
      await SetRrpps(eventId, selectedChanges);
      toastMessageSuccess("Cambios guardados");
    } catch (error) {
      console.error("Error al guardar cambios:", error);
      toastMessageError("Error al guardar cambios");
    }
  };

  const handleSelectionChange = (newSelection) => {
    const deselectedRowId = selectionModel.find(
      (selectedRowId) => !newSelection.includes(selectedRowId)
    );
    const selectedRowId = newSelection.find((rowId) => !selectionModel.includes(rowId));

    if (deselectedRowId) {
      const deselectedRow = rows.find((row) => row.id === deselectedRowId);
      if (deselectedRow) {
        const existingIndex = selectedChanges.findIndex(
          (change) => change.id === deselectedRow.idrrpp
        );
        if (existingIndex !== -1) {
          const updatedChanges = [...selectedChanges];
          updatedChanges[existingIndex] = { ...updatedChanges[existingIndex], is_active: false };
          setSelectedChanges(updatedChanges);
        } else {
          setSelectedChanges((prevChanges) => [
            ...prevChanges,
            { id: deselectedRow.idrrpp, is_active: false },
          ]);
        }
      }
    }

    if (selectedRowId) {
      const selectedRow = rows.find((row) => row.id === selectedRowId);
      if (selectedRow) {
        const existingIndex = selectedChanges.findIndex(
          (change) => change.id === selectedRow.idrrpp
        );
        if (existingIndex !== -1) {
          const updatedChanges = [...selectedChanges];
          updatedChanges[existingIndex] = { ...updatedChanges[existingIndex], is_active: true };
          setSelectedChanges(updatedChanges);
        } else {
          setSelectedChanges((prevChanges) => [
            ...prevChanges,
            { id: selectedRow.idrrpp, is_active: true },
          ]);
        }
      }
    }

    setSelectionModel(newSelection);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  return (
    <div style={{ width: "100%" }}>
      <div>
        <Grid item sx={{ margin: "20px" }}>
          <SearchIcon
            sx={{
              height: 35,
              width: 35,
              mr: 1,
              color: "var(--secondary-color)",
            }}
          />
          <TextField
            label={"Search"}
            onChange={(event) => debounceSearch(event)}
            size="small"
            sx={[focusColor, { width: "200px" }]}
          />
        </Grid>
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            justifyContent: "right",
            marginRight: "20px",
            marginBottom: "20px",
          }}
        >
          <Pagination
            count={Math.ceil(total / pagesize)}
            page={page}
            onChange={(_, newPage) => handlePageChange(newPage)}
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bolder",
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": {
                  background: "#3D3B38",
                  color: "#fff",
                  fontWeight: "bolder",
                },
                fontWeight: "600",
              },
            }}
          />
        </div>
        <MuiXDataGrid
          rows={rows}
          columns={columns}
          pageSize={pagesize}
          checkboxSelection
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selectionModel}
          showCellRightBorder={false}
          hideFooter={true}
        />
      </div>
      <div style={{ marginTop: "10px", textAlign: "center" }}>
        <span>
          {t("VIEWING_ELEMENTS")} {(page - 1) * pagesize + 1}-{Math.min(page * pagesize, total)} de{" "}
          {total}
        </span>
      </div>
      <div style={{ marginTop: "20px", textAlign: "center", marginBottom: "120px" }}>
        <Button
          type="button"
          onClick={handleGuardarCambios}
          className="oniria-btn"
          fullWidth
          variant="contained"
          sx={{ m: 2, fontSize: "20px", borderRadius: 3, width: "270px" }}
        >
          {"Guardar Cambios"}
        </Button>
      </div>
    </div>
  );
}

export default TablaRPP;
