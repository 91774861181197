import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Paper,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useTranslation } from "react-i18next";
  import { useQuery } from "react-query";
  import { useSelector } from "react-redux";
  import { useNavigate } from "react-router-dom";
  import Header from "../components/Header";
  import HomeStatistics from "../components/home/HomeStatistics";
  import { getHome } from "../services/utilsServices";
  import "./styles.css";

  const Home = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [greet, setGreet] = useState("");
    const actualDate = new Date();
    const actualHour = actualDate.getHours();
    const userInfo = useSelector((state) => state.userInfo);
    const allUserData = userInfo.userData;
    const roles = userInfo.roles;
    const isSuperAdmin = roles.find((element) => element === "super-admin");
    const isManager = roles.find((element) => element === "manager-admin");
    const isAffiliate = roles.find((element) => element === "affiliated");
    const isAdmin = roles.find((element) => element === "admin");
    const [affiliationData, setAffiliationData] = useState([]);
    const [nextEvents, setNextEvents] = useState([]);
    const [statistics, setStatistics] = useState("");
    const breadcrumbs = [
      {
        name: `${greet}, ${allUserData.name} ${allUserData.surname}`,
        link: "/home",
      },
    ];
  
    useEffect(() => {
      if (actualHour >= 5 && actualHour < 14) {
        setGreet(t("GOOD_MORNING"));
      } else if (actualHour >= 14 && actualHour < 21) {
        setGreet(t("GOOD_AFTERNOON"));
      } else {
        setGreet(t("GOOD_EVENING"));
      }
    }, [actualHour]);
    const handleQuerySuccess = (data) => {
      setAffiliationData(data.affiliation_requests);
      const sortedEvents = data.next_events.sort((a, b) => {
        const dateA = new Date(a.start_date);
        const dateB = new Date(b.start_date);
        return dateA - dateB;
      });
      setNextEvents(sortedEvents);
      setStatistics(data.statistics);
    };
    const { isLoading } = useQuery("home", () => getHome(), {
      onSuccess: handleQuerySuccess,
    });
  
    if (isLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress sx={{ color: "var(--secondary-color)" }} />
        </Box>
      );
    }
    return (
      <>
        <Header breadcrumbs={breadcrumbs} />
  
        <HomeStatistics />
      </>
    );
  };
  
  export default Home;
  