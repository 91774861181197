import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { getCategoriesDomainByPremise } from "../../services/categoriesServices";

export function useQueryCategoriesPremiseDomain(premiseId) {
  return useQuery(
    [RQ_KEY.CATEGORIES_DOMAIN, premiseId],
    () => getCategoriesDomainByPremise(premiseId),
    {
      refetchOnWindowFocus: false,
      enabled: !!premiseId,
    }
  );
}
