import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { focusColor } from "../../../shared/textFieldStyle";
import TextEditor from "../../../textEditor/TextEditor";
import ImageUpload from "../../../shared/ImageUpload";

const Partner = ({ partner, index, handleOpenDelete, handleEditPartner, partnersErrors }) => {
  const { t } = useTranslation();

  const { title, short_description, image_url, description } = partner;

  const handleChangeDescription = (data = "") => {
    const newDescription = typeof data !== "string" ? JSON.stringify(data) : data;
    const e = { target: { value: newDescription } };

    handleEditPartner(index, "description", e, /^[\s\S]{0,10000000}$/, t("MAX_5000"));
  };

  const handleImageSelect = (url) => {
    if (Array.isArray(url)) {
      const urlParams = url.map((u) => ({ image: u.split(";")[1], mime: u.split(":")[1] }));
      const e = { target: { value: urlParams } };
      handleEditPartner(index, "image_url", e, "", "");
    } else {
      const urlParams = url?.split(";");
      const e = { target: { value: { image: urlParams[1], mime: urlParams[0].split(":")[1] } } };
      handleEditPartner(index, "image_url", e, "", "");
    }
  };

  return (
    <Draggable draggableId={`draggable-${index}`} index={index}>
      {(provided, snapshot) => (
        <Accordion
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
            display: "flex",
            flexDirection: "column",
            border: "1px solid var(--grey-cancelled)",
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container flexDirection="row" justifyContent="space-between">
              <Grid item xs={6} container alignItems="center">
                <DragIndicatorIcon sx={{ marginRight: 2 }} />
                <Typography>{title ? title : t("TITLE")}</Typography>
              </Grid>
              <Grid item marginRight={2}>
                <IconButton
                  onClick={(e) => handleOpenDelete(e, index)}
                  className="oniria-icon-btn-delete"
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) => handleEditPartner(index, "title", e, /^.{0,255}$/, t("MAX_255"))}
                  margin="normal"
                  fullWidth
                  value={title || ""}
                  id="title"
                  name="title"
                  autoComplete="title"
                  label={t("TITLE")}
                  error={partnersErrors[index]?.title !== ""}
                  helperText={partnersErrors[index]?.title}
                  sx={focusColor}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  onChange={(e) =>
                    handleEditPartner(index, "short_description", e, /^.{0,255}$/, t("MAX_255"))
                  }
                  margin="normal"
                  fullWidth
                  value={short_description || ""}
                  id="short_description"
                  name="short_description"
                  autoComplete="short_description"
                  label={t("SHORT_DESCRIPTION")}
                  error={partnersErrors[index]?.short_description !== ""}
                  helperText={partnersErrors[index]?.short_description}
                  sx={focusColor}
                />
              </Grid>
              <Grid item xs={12}>
                <ImageUpload url={image_url} onImageSelect={handleImageSelect} multi={false} />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>
                  {t("DESCRIPTION")}
                </InputLabel>
                <TextEditor
                  data={description || ""}
                  setData={handleChangeDescription}
                  id={`block-${index}-description`}
                />
                {partnersErrors[index]?.description !== "" && (
                  <Typography variant="caption" sx={{ color: "red" }}>
                    {partnersErrors[index]?.description}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
};

export default Partner;
