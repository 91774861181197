import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Header from "../../components/Header";
import { Loading } from "../../components/shared/Loading";
import SelectPerPage from "../../components/shared/SelectPerPage";
import { focusColor } from "../../components/shared/textFieldStyle";
import { isValidCardDate } from "../../constants/utils";
import { USER_STATUS } from "../../constants/variables";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import useDebounce from "../../hooks/useDebouncing";
import usePagination from "../../hooks/usePagination";
import { getUsersAffiliate } from "../../services/usersServices";

const UserList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedPremise, setSelectedPremise] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedDomain, setSelectedDomain] = useState("all");
  const [selectedCity, setSelectedCity] = useState("all");
  const { page, perPage, changePage, changePerPage } = usePagination();

  const [searchParam] = useSearchParams();
  const [grouping, setGrouping] = useState("all");
  const [organization, setOrganization] = useState("");
  const userInfo = useSelector((state) => state.userInfo);

  const { data: domains } = useQueryDomains();

  const breadcrumbs = [
    {
      name: t("USER_MANAGER"),
      link: "/users",
    },
  ];

  useEffect(() => {
    if (userInfo.grouping_id) {
      setGrouping(userInfo.grouping_id);
    } else if (userInfo.organization_id) {
      setOrganization(userInfo.organization_id);
    }
  }, []);

  //Sedes
  const { data: premises } = useQueryPremisesList(organization, grouping);

  const handleQuerySuccess = (data) => {
    setUsers(data.data);
  };

  const {
    isLoading,
    isRefetching,
    data: usersData,
  } = useQuery(
    ["user", page, perPage, search, selectedPremise, selectedCity, selectedStatus, selectedDomain],
    () =>
      getUsersAffiliate(
        page,
        perPage,
        search,
        selectedPremise,
        selectedCity,
        selectedStatus,
        selectedDomain
      ),
    {
      onSuccess: handleQuerySuccess,
      refetchOnWindowFocus: false,
    }
  );

  const handleSearchChange = (value) => {
    setSearch(value);
    changePage(null, 1);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handlePremiseChange = (event) => {
    if (event && event.target) {
      setSelectedPremise(event.target.value);
      changePage(null, 1);
    }
  };
  const handleStatusChange = (event) => {
    if (event && event.target) {
      setSelectedStatus(event.target.value);
      changePage(null, 1);
    }
  };
  const handleDomainChange = (event) => {
    if (event && event.target) {
      setSelectedDomain(event.target.value);
      changePage(null, 1);
    }
  };

  const getTranslatedStatus = (status) => {
    switch (status) {
      case "pending_validate":
        return t("REQUESTED");
      case "pending_payment":
        return t("VALIDATED");
      case "affiliated":
        return t("PAID");
      case "no_affiliated":
        return t("NOT_REQUESTED");
      default:
        return status;
    }
  };
  const handleEventClick = (id) => {
    navigate(`/users/${id}`);
  };
  // navigate from home status change
  useEffect(() => {
    const status = searchParam.get("status");
    if (status === "affiliated") {
      setSelectedStatus("affiliated");
    } else if (status === "pending_payment") {
      setSelectedStatus("pending_payment");
    }
  }, []);

  //download options
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenPopover = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };
  const handleClosePopover = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  const tableHeaders = [t("NAME"), t("SURNAME"), t("EMAIL"), t("DOMAIN"), t("SITE"), t("STATUS")];
  const tableData = users?.map((user) => [
    user.user_name,
    user.user_surname,
    user.user_email,
    user.domain,
    user.premise_name,
    getTranslatedStatus(user.affiliated_status),
  ]);
  const generatePDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
    });
    doc.save("userList.pdf");
  };

  //Pagination
  const totalPages = Math.ceil(Number(usersData?.total ?? 0) / Number(usersData?.perPage ?? 10));

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_USERS")} />
      <Grid
        container
        spacing={2}
        mb={2}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
      >
        {/* <Grid item>
          <Button
            type="submit"
            onClick={() => navigate("/users/pending_users")}
            className="oniria-btn"
            variant="contained"
            sx={{
              fontSize: "12px",
              borderRadius: 3,
            }}
          >
            {t("PENDING_USERS")}
          </Button>
        </Grid> */}
        <Grid item>
          <Button
            type="submit"
            onClick={() => navigate("/users/create_user")}
            className="oniria-btn"
            variant="contained"
            sx={{
              fontSize: "12px",
              borderRadius: 3,
            }}
          >
            {t("CREATE_USER")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            className="oniria-btn"
            variant="contained"
            sx={{
              fontSize: "12px",
              borderRadius: 3,
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
            onClick={handleOpenPopover}
          >
            <CloudDownloadIcon fontSize="small" />
            {t("DOWNLOAD_LIST")}
          </Button>
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            id={popoverId}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <ButtonGroup
              variant="contained"
              orientation="vertical"
              aria-label="vertical button group"
            >
              <Button
                disableElevation
                fullWidth
                variant="contained"
                className="popover-btn"
                aria-label="download csv"
              >
                <CSVLink
                  data={tableData}
                  filename={"assistants.csv"}
                  target="_blank"
                  separator={";"}
                  headers={tableHeaders}
                  className="csv-link"
                >
                  {t("CSV_FORMAT")}
                </CSVLink>
              </Button>
              <Button
                disableElevation
                fullWidth
                variant="contained"
                className="popover-btn"
                aria-label="download pdf"
                onClick={generatePDF}
              >
                {t("PDF_FORMAT")}
              </Button>
            </ButtonGroup>
          </Popover>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
      >
        <Grid item xs={11} sm={7} md={6} sx={{ mb: 2 }}>
          <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
          <TextField
            label={t("SEARCH")}
            onChange={(event) => debounceSearch(event?.target?.value)}
            size="small"
            sx={[focusColor, { width: "75%" }]}
          />
        </Grid>
        <Grid item xs={11} sm={11} md={6}>
          <Grid
            container
            columnSpacing={2}
            mt={1}
            justifyContent={{ xs: "flex-start", md: "flex-end" }}
          >
            {/* <Grid item mb={1}>
              <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
                <Select
                  value={selectedPremise}
                  onChange={handlePremiseChange}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                >
                  <MenuItem value="all">{t("ALL_SITES")}</MenuItem>
                  {premises?.map((premise) => (
                    <MenuItem key={premise.id} value={premise.id}>
                      {premise.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item mb={1}>
              <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
                <Select
                  value={selectedDomain}
                  onChange={handleDomainChange}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                  defaultValue="all"
                >
                  <MenuItem value="all">{t("ALL_DOMAINS")}</MenuItem>
                  {domains?.map((domain) => (
                    <MenuItem key={domain.domain_id} value={domain.domain_id}>
                      {domain.domain_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item mb={1}>
              <FormControl sx={[focusColor, { minWidth: 120 }]} size="small">
                <Select
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  sx={{ fontSize: "12px", borderRadius: 3 }}
                  defaultValue="all"
                >
                  <MenuItem value="all">{t("STATUS_ALL")}</MenuItem>
                  {Object.keys(USER_STATUS)?.map((status) => (
                    <MenuItem key={status} value={USER_STATUS[status]}>
                      {getTranslatedStatus(USER_STATUS[status])}
                    </MenuItem>
                  ))}
                  <MenuItem value="expired">{t("EXPIRED")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2, mt: 2 }} container justifyContent="center">
        <Grid item xs={12} md={4} container alignItems="center">
          <Typography>
            {t("TOTAL_USERS")} {usersData?.total ?? 0}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} container justifyContent="flex-end">
          <Grid item>
            <SelectPerPage text={t("USERS")} change={changePerPage} value={perPage} />
          </Grid>
          <Pagination
            count={totalPages}
            page={page}
            onChange={changePage}
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bolder",
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": {
                  background: "#3D3B38",
                  color: "#fff",
                  fontWeight: "bolder",
                },
                fontWeight: "600",
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={11} md={12} mb={25}>
        {isLoading || isRefetching ? (
          <Loading />
        ) : users && users.length === 0 ? (
          <Typography>{t("NO_USERS")}</Typography>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                    <TableCell>{t("USER_AVATAR")}</TableCell>
                    <TableCell>{t("NAME")}</TableCell>
                    <TableCell>{t("SURNAME")}</TableCell>
                    <TableCell>{t("EMAIL")}</TableCell>
                    <TableCell>{t("DOMAIN")}</TableCell>
                    <TableCell>{t("STATUS")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users?.map((user, index) => (
                    <TableRow
                      key={index}
                      onClick={() => handleEventClick(user.user_id)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>
                        <Avatar src={user.user_avatar_url}></Avatar>
                      </TableCell>
                      <TableCell>{user.user_name}</TableCell>
                      <TableCell>{user.user_surname}</TableCell>
                      <TableCell>{user.user_email}</TableCell>
                      <TableCell>{user.domain_name}</TableCell>
                      <TableCell>
                        {isValidCardDate(user.erasmus_expires_at)
                          ? getTranslatedStatus(user.affiliated_status)
                          : t("EXPIRED")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid mt={3}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={changePage}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bolder",
                  "& .MuiPaginationItem-root": {
                    "&.Mui-selected": {
                      background: "#3D3B38",
                      color: "#fff",
                      fontWeight: "bolder",
                    },
                    fontWeight: "600",
                  },
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default UserList;
