import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkIcon from "@mui/icons-material/Link";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOGOS, ROLES } from "../../constants/variables";
import WebBarItem from "./WebBarItem";

const DrawerHeaderSideBar = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const WebSideBar = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isAffiliate = roles.find((element) => element === ROLES.AFFILIATED);
  const isSuperAdmin = roles.find((element) => element === ROLES.SUPER_ADMIN);
  const isAdmin = roles.find((element) => element === ROLES.ADMIN);
  const isManager = roles.find((element) => element === ROLES.MANAGER_ADMIN);
  const canPublishTrips = userInfo.trips;

  return (
    <>
      <DrawerHeaderSideBar
        sx={{
          p: 3,
          mr: 2,
          color: "white",
          maxHeight: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            mr: 2,
            color: "white",
            maxHeight: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            imageResolution: "from-image",
            maxWidth: "80%",
          }}
          component="img"
          alt="Logo"
          src={LOGOS.PRINCIPAL_WHITE}
        />
        <IconButton onClick={() => props.setOpen(!props.open)}>
          {props.open ? (
            <ChevronLeftIcon sx={{ color: "white" }} />
          ) : (
            <ChevronRightIcon sx={{ color: "white" }} />
          )}
        </IconButton>
      </DrawerHeaderSideBar>
      <Divider />
      <List>
        {/* HOME */}
        <ListItem disablePadding sx={{ display: "block", color: "white" }}>
          <ListItemButton
            onClick={() => {
              navigate("/home");
            }}
            sx={{
              minHeight: 48,
              justifyContent: props.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: props.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                alt={t("HOME")}
                src={"/media/sidebarIcons/home.svg"}
                sx={{ height: 25, width: 25 }}
              />
            </ListItemIcon>
            <ListItemText primary={t("HOME")} sx={{ opacity: props.open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        {/* STATISTICS */}
        <ListItem disablePadding sx={{ display: "block", color: "white" }}>
          <ListItemButton
            onClick={() => {
              navigate("/statistics");
            }}
            sx={{
              minHeight: 48,
              justifyContent: props.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: props.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                alt={t("STATISTICS")}
                src={"/media/sidebarIcons/statistics.svg"}
                sx={{ height: 25, width: 25 }}
              />
            </ListItemIcon>
            <ListItemText primary={t("STATISTICS")} sx={{ opacity: props.open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        {/* CALENDAR */}
        <ListItem disablePadding sx={{ display: "block", color: "white" }}>
          <ListItemButton
            onClick={() => {
              navigate("/calendar");
            }}
            sx={{
              minHeight: 48,
              justifyContent: props.open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: props.open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                alt={t("CALENDAR")}
                src={"/media/sidebarIcons/calendar.svg"}
                sx={{ height: 25, width: 25 }}
              />
            </ListItemIcon>
            <ListItemText
              primary={t("CALENDAR")}
              sx={{ opacity: props.open ? 1 : 0, fontSize: "8px" }}
            />
          </ListItemButton>
        </ListItem>
        {/* GESTOR EVENTOS */}
        <Accordion
          sx={{
            backgroundColor: "var(--secondary-color)",
            color: "#fff",
            border: 0,
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}>
            <EventAvailableIcon />
            {props.open ? <Typography ml={2}>{t("PRODUCTS")}</Typography> : null}
          </AccordionSummary>
          <AccordionDetails>
            <WebBarItem
              icon={
                <Box
                  component="img"
                  alt={t("EVENT_MANAGER")}
                  src={"/media/sidebarIcons/events.svg"}
                  sx={{ height: 25, width: 25 }}
                />
              }
              text={t("EVENT_MANAGER")}
              onClick={() => {
                navigate("/events");
              }}
              open={props.open}
            />
            {(canPublishTrips || isSuperAdmin) && (
              <WebBarItem
                icon={
                  <Box
                    component="img"
                    alt={t("TRAVEL_MANAGER")}
                    src={"/media/sidebarIcons/travels.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                }
                text={t("TRAVEL_MANAGER")}
                onClick={() => {
                  navigate("/trips");
                }}
                open={props.open}
              />
            )}
          </AccordionDetails>
        </Accordion>
        {/* TRANSACTIONS */}
        <Accordion
          sx={{
            backgroundColor: "var(--secondary-color)",
            color: "#fff",
            border: 0,
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}>
            <AccountBalanceIcon />
            {props.open ? <Typography ml={2}>{t("TRANSACTIONS")}</Typography> : null}
          </AccordionSummary>
          <AccordionDetails>
            {(isSuperAdmin || isManager || isAdmin) && (
              <WebBarItem
                icon={
                  <Box
                    component="img"
                    alt={t("SALES")}
                    src={"/media/sidebarIcons/bills.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                }
                text={t("SALES")}
                onClick={() => {
                  navigate("/sales");
                }}
                open={props.open}
              />
            )}
            {(isSuperAdmin || isManager || isAdmin) && (
              <WebBarItem
                icon={
                  <Box
                    component="img"
                    alt={t("BILLING_PLUS")}
                    src={"/media/sidebarIcons/bill_plus.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                }
                text={t("BILLING_PLUS")}
                onClick={() => {
                  navigate("/billing");
                }}
                open={props.open}
              />
            )}
            {(isSuperAdmin || isManager) && (
              <WebBarItem
                icon={
                  <Box
                    component="img"
                    alt={t("REFUNDS")}
                    src={"/media/sidebarIcons/refund.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                }
                text={t("REFUNDS")}
                onClick={() => {
                  navigate("/refunds");
                }}
                open={props.open}
              />
            )}
          </AccordionDetails>
        </Accordion>
        {/* GESTOR ACCESOS */}
        <Accordion
          sx={{
            backgroundColor: "var(--secondary-color)",
            color: "#fff",
            border: 0,
            boxShadow: "none",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}>
            <PersonOutlineIcon />
            {props.open ? <Typography ml={2}>{t("ADMINISTRATION")}</Typography> : null}
          </AccordionSummary>
          <AccordionDetails>
            {(isSuperAdmin || isAdmin || isManager) && (
              <WebBarItem
                icon={
                  <Box
                    component="img"
                    alt={t("ENTITIES")}
                    src={"/media/sidebarIcons/organizations.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                }
                text={t("ENTITIES")}
                onClick={() => {
                  navigate("/organizations");
                }}
                open={props.open}
              />
            )}
            <WebBarItem
              icon={
                <Box
                  component="img"
                  alt={t("VENUES")}
                  src={"/media/sidebarIcons/premise.svg"}
                  sx={{ height: 25, width: 25 }}
                />
              }
              text={t("VENUES")}
              onClick={() => {
                navigate("/premises");
              }}
              open={props.open}
            />
            {(isSuperAdmin || isManager || isAdmin || isAffiliate) && (
              <WebBarItem
                icon={
                  <Box
                    component="img"
                    alt={t("ADMINISTRATOR_MANAGER")}
                    src={"/media/sidebarIcons/admin.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                }
                text={t("ADMINISTRATOR_MANAGER")}
                onClick={() => {
                  navigate("/admins");
                }}
                open={props.open}
              />
            )}
            {(isSuperAdmin || isAffiliate) && (
              <WebBarItem
                icon={
                  <Box
                    component="img"
                    alt={t("USER_MANAGER")}
                    src={"/media/sidebarIcons/users.svg"}
                    sx={{ height: 25, width: 25 }}
                  />
                }
                text={t("USER_MANAGER")}
                onClick={() => {
                  navigate("/users");
                }}
                open={props.open}
              />
            )}
            <WebBarItem
              icon={
                <Box
                  component="img"
                  alt={t("RRPP_MANAGER")}
                  src={"/media/sidebarIcons/rrpp.svg"}
                  sx={{ height: 25, width: 25 }}
                />
              }
              text={t("RRPP_MANAGER")}
              onClick={() => {
                navigate("/rrpps");
              }}
              open={props.open}
            />
            {(isSuperAdmin || isAdmin || isManager) && (
              <WebBarItem
                icon={<LinkIcon sx={{ height: "25px", width: "25px", color: "#fff" }} />}
                text={t("STYLES_MANAGER_APP")}
                onClick={() => {
                  navigate("/domains");
                }}
                open={props.open}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </List>
    </>
  );
};

export default WebSideBar;
