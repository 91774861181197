import Add from "@mui/icons-material/Add";
import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import { Loading } from "../../components/shared/Loading";
import DomainsTable from "../../components/whiteLabel/DomainsTable";
import AddDomain from "../../components/whiteLabel/domainDialogs/AddDomain";
import { useQueryDomains } from "../../hooks/queries/useQueryDomains";

const WhiteLabel = () => {
  const { t } = useTranslation();
  const [openAddDomain, setOpenAddDomain] = useState(false);

  const breadcrumbs = [
    {
      name: t("DOMAINS_MANAGER"),
      link: "/domains",
    },
  ];

  const { data, isLoading, isRefetching, refetch } = useQueryDomains();

  const handleOpenAddDomain = () => {
    setOpenAddDomain(true);
  };

  const handleCloseDomain = () => {
    setOpenAddDomain(false);
  };

  return (
    <Grid mb={20}>
      <Header breadcrumbs={breadcrumbs} />
      <Grid container justifyContent="flex-end">
        <Button className="oniria-btn" sx={{ color: "#fff" }} onClick={handleOpenAddDomain}>
          <Add />
          {t("ADD_DOMAIN")}
        </Button>
      </Grid>
      {isLoading || isRefetching ? (
        <Loading />
      ) : data && data.length !== 0 ? (
        <DomainsTable domains={data} refetch={refetch} />
      ) : (
        <Typography>{t("NO_DOMAINS")}</Typography>
      )}
      <AddDomain isOpen={openAddDomain} onClose={handleCloseDomain} refetch={refetch} />
    </Grid>
  );
};
export default WhiteLabel;
