import { transformObjectWithUrls } from "../components/shared/FormsValidator";
import { SERVICE } from "../constants/api";
import http from "../constants/axios";
import { changeNullToEsNul } from "../constants/utils";

export async function findAllDomains() {
  const { data } = await http.get(`${SERVICE.DOMAINS}`);
  return data;
}

export async function findDomainById(domainId) {
  const { data } = await http.get(`${SERVICE.DOMAINS}/${domainId}`);
  return data;
}

export async function createDomain(body) {
  const { data } = await http.post(`${SERVICE.DOMAINS}`, body);
  return data;
}

export async function deleteDomain(domainId) {
  const { data } = await http.delete(`${SERVICE.DOMAINS}/${domainId}`);
  return data;
}

export async function updateDomain(domainId, body) {
  const style = await transformObjectWithUrls(body.style);
  const sweeps = [];
  for (let i = 0; i < body.sweeps.length; i++) {
    const newSweep = await transformObjectWithUrls(body.sweeps[i]);
    newSweep.domain_id = domainId;
    newSweep.position = i;
    sweeps.push(newSweep);
  }
  const nullStyle = changeNullToEsNul(style);
  const bodyToSend = { ...body, style: nullStyle, sweeps };
  delete bodyToSend?.style?.created_at;
  bodyToSend.style.search_bar = bodyToSend?.style?.search_bar === 1;
  bodyToSend.style.category_styles = bodyToSend?.style?.category_styles?.map((category) => {
    category.style_id = bodyToSend.style.id;
    return category;
  });

  if (Array.isArray(bodyToSend?.style?.reel_images_url)) {
    bodyToSend.style.reel_images_url = bodyToSend?.style.reel_images_url.join(",");
  }

  const { data } = await http.put(`${SERVICE.DOMAINS}/${domainId}`, bodyToSend);
  return data;
}

export async function getPremiseDomain(premiseId) {
  const { data } = await http.get(`${SERVICE.PREMISES}domain/${premiseId}`);
  return data;
}

export async function getSponsorsByDomain(domainId) {
  const { data } = await http.get(`${SERVICE.DOMAINS}/sponsors/${domainId}`);
  return data;
}

export async function postSponsorDomain(body) {
  const bodyToSend = await transformObjectWithUrls(body);
  const { data } = await http.post(`${SERVICE.DOMAINS}/sponsors`, bodyToSend);
  return data;
}

export async function deleteSponsorDomain(sponsorId) {
  const { data } = await http.delete(`${SERVICE.DOMAINS}/sponsors/${sponsorId}`);
  return data;
}

export async function updateSponsorDomain(sponsorId, body) {
  const bodyToSend = await transformObjectWithUrls(body);
  delete bodyToSend.created_at;
  const { data } = await http.put(`${SERVICE.DOMAINS}/sponsors/${sponsorId}`, bodyToSend);
  return data;
}

export async function getCardsByDomain(domainId) {
  const { data } = await http.get(`${SERVICE.DOMAINS}/cards/${domainId}`);
  return data;
}

export async function getActiveCardsByDomain(domainId) {
  const { data } = await http.get(`${SERVICE.CARDS}domain/${domainId}`);
  return data;
}

export async function updateCardDomain(id, body) {
  const { data } = await http.put(`${SERVICE.CARDS}domain/${id}`, body);
  return data;
}

export async function getDomainEditors(domainId) {
  const { data } = await http.get(`${SERVICE.DOMAINS}/admins/${domainId}`);
  return data;
}

export async function givePermissions(userId, domainId) {
  const body = {
    user_id: userId,
    domain_id: domainId,
  };
  const { data } = await http.post(`${SERVICE.DOMAINS}/admins`, body);
  return data;
}

export async function removePermissions(userId, domainId) {
  const body = {
    user_id: userId,
    domain_id: domainId,
  };
  const { data } = await http.delete(`${SERVICE.DOMAINS}/admins`, { data: body });
  return data;
}
