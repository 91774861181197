export function formatDate(date) {
  const dateObj = new Date(date);
  const day = String(dateObj.getUTCDate()).padStart(2, "0");
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
  const year = dateObj.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export function formatHours(date) {
  const dateObj = new Date(date);
  const hours = String(dateObj.getUTCHours()).padStart(2, "0");
  const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
  const formattedTime = `${hours}:${minutes}`;
  return formattedTime;
}

export function formatMuiDate(date) {
  if (!date) return "";
  var fecha = new Date(date);
  var año = fecha.getFullYear();
  var mes = fecha.getMonth() + 1;
  var dia = fecha.getDate();

  mes = mes < 10 ? "0" + mes : mes;
  dia = dia < 10 ? "0" + dia : dia;
  return `${año}-${mes}-${dia}`;
}

export function compareDates(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d1.getTime() === d2.getTime();
}

export function getAgeByBirth(date) {
  const birthDate = new Date(date);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export const EURO = new Intl.NumberFormat("es", {
  style: "currency",
  currency: "EUR",
});

//check if a erasmus card is expired
export const isValidCardDate = (date) => {
  if (date === null) return true;
  const today = new Date();
  const cardDate = new Date(date);
  return today < cardDate;
};

export function changeEmptyStringNull(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === "") {
        obj[key] = null;
      } else if (typeof obj[key] === "object") {
        changeEmptyStringNull(obj[key]);
      }
    }
  }
  return obj;
}
export function convertDate(isoDate) {
  const date = new Date(isoDate);
  const options = { weekday: "short", day: "numeric", month: "short" };
  const formattedDate = date.toLocaleDateString("es-ES", options);

  // Extract day, month, and weekday
  const [weekday, day, month] = formattedDate.split(" ");

  // Map month abbreviations to uppercase
  const monthMap = {
    ene: "ene",
    feb: "feb",
    mar: "mar",
    abr: "abr",
    may: "may",
    jun: "jun",
    jul: "jul",
    ago: "ago",
    sep: "sept",
    oct: "oct",
    nov: "nov",
    dic: "dic",
  };

  return `${weekday.toLowerCase()} ${day} ${monthMap[month.toLowerCase()]}`;
}

export function formatCurrency(number) {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(number);
}

export const getOneWeekFromToday = () => {
  const today = new Date();
  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);
  return formatMuiDate(oneWeekAgo);
};

export const changeNullToEsNul = (obj) => {
  // Verificar si obj no es un objeto o es null
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  for (let key in obj) {
    if (
      key === "organization_id" ||
      key === "premise_id" ||
      key === "grouping_id" ||
      key === "card_premise_id" ||
      key === "category_styles"
    ) {
      continue;
    }
    if (obj[key] === null || obj[key] === "") {
      obj[key] = "ESNULO";
    }
    if (typeof obj[key] === "object") {
      changeNullToEsNul(obj[key]);
    }
  }

  return obj;
};
