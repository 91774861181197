import { Grid, Pagination, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import CardEvents from "../../components/events/CardEvents";
import EventFilters from "../../components/events/filters/EventFilters";
import { getEventConfigurations } from "../../constants/variables";
import { getAllEventsFiltered } from "../../services/eventsServices";
import useDebounce from "../../hooks/useDebouncing";

let perPage = "10";
let numberEvents = "";
let totalEvents = 0;

const Events = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [dateFilter, setFilterDates] = useState("upcoming");
  const [cityFilter, setCityFilter] = useState("all");
  const [eventsFiltered, setEventsFiltered] = useState([]);
  const [cityNoEvents, setCityNoEvents] = useState(false);
  const [page, setPage] = useState(1);
  const [grouping, setGrouping] = useState("all");
  const [premise, setPremise] = useState("all");
  const [eventType, setEventType] = useState("no-trip");
  const [eventSubcategory, setEventSubcategory] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [description, setDescription] = useState("");
  const [organization, setOrganization] = useState("all");
  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (userInfo.grouping_id) {
      setGrouping(userInfo.grouping_id);
    } else if (userInfo.organization_id) {
      setOrganization(userInfo.organization_id);
    }
  }, []);

  useEffect(() => {
    const eventConfig = getEventConfigurations(t);
    if (eventConfig[eventType]) {
      setBreadcrumbs(eventConfig[eventType].breadcrumbs);
      setDescription(eventConfig[eventType].description);
    } else {
      setBreadcrumbs(eventConfig.event.breadcrumbs);
      setDescription(eventConfig.event.description);
    }
  }, [eventType]);

  const handleQuerySuccessFiltered = (data) => {
    totalEvents = data.total;
    const activities = data.data;
    perPage = data.perpage;
    numberEvents = data.total;
    setEventsFiltered(activities);
    setCityNoEvents(activities?.length === 0);
  };

  const handleCityFilter = (data) => {
    setCityFilter(data);
    setPage(1);
  };
  const handleDateFilter = (data) => {
    setFilterDates(data);
    setPage(1);
  };

  const { refetch, isLoading, isRefetching } = useQuery(
    [
      "activitiesFilter",
      cityFilter,
      search,
      dateFilter,
      page,
      grouping,
      premise,
      eventType,
      eventSubcategory,
    ],
    () =>
      getAllEventsFiltered(
        cityFilter,
        search,
        dateFilter,
        page,
        perPage,
        grouping,
        premise,
        eventType,
        eventSubcategory
      ),
    {
      onSuccess: handleQuerySuccessFiltered,
      refetchOnWindowFocus: false,
    }
  );

  const handleSearchChange = (event) => {
    const search = event.target.value;
    setSearch(search);
    setPage(1);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const handleChangeEventType = (e) => {
    setEventType(e);
    setPage(1);
  };

  //Change page
  const handleChangePage = (_, value) => {
    setPage(value);
  };

  const numberPages = Math.ceil(parseFloat(numberEvents) / parseFloat(perPage));

  const handleGroupingFilter = (data) => {
    setGrouping(data);
    setPage(1);
    setOrganization("all");
  };
  const handlePremiseFilter = (data) => {
    setPremise(data);
    setPage(1);
  };
  const handleChangeOrganization = (data) => {
    setOrganization(data);
    setPage(1);
  };

  const renderLoading = () => (
    <Grid container justifyContent="center" alignItems="center">
      <CircularProgress sx={{ color: "var(--secondary-color)" }} />
    </Grid>
  );
  const renderNoEventsFound = () => <Grid ml={3}>{t("EVENTS_NO_FOUND")}</Grid>;

  const renderEvents = () => (
    <Grid
      container
      rowSpacing={2}
      sx={{ display: "flex", flexDirection: "row", justifyContent: "center", mb: 20 }}
    >
      {eventsFiltered?.map((trip, i) => (
        <CardEvents event={trip} key={i} refetch={refetch} />
      ))}
    </Grid>
  );

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={description} />
      <Grid
        item
        xs={11}
        sm={12}
        sx={{ mb: 5, display: "flex", flexDirection: "row", justifyContent: "initial" }}
      >
        <EventFilters
          onSearchChange={debounceSearch}
          grouping={grouping}
          onGroupingFilter={handleGroupingFilter}
          premise={premise}
          onPremiseFilter={handlePremiseFilter}
          cityFilter={cityFilter}
          onCityFilter={handleCityFilter}
          dateFilter={dateFilter}
          onDateFilter={handleDateFilter}
          eventType={eventType}
          onEventFilter={handleChangeEventType}
          eventSubcategory={eventSubcategory}
          setEventSubcategory={setEventSubcategory}
          organization={organization}
          onOrganizationFilter={handleChangeOrganization}
        />
      </Grid>
      {totalEvents > 0 && (
        <Grid
          item
          xs={11}
          sm={12}
          container
          justifyContent={{ xs: "center", sm: "space-between" }}
          alignItems={"center"}
          pb={2}
          gap={{ xs: 2, sm: 0 }}
        >
          <Typography>
            {breadcrumbs[0]?.name} : {totalEvents}
          </Typography>
          {numberPages > 1 && (
            <Pagination
              count={numberPages}
              mr={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bolder",
                "& .MuiPaginationItem-root": {
                  "&.Mui-selected": {
                    background: "#481e5f",
                    color: "#fff",
                    fontWeight: "bolder",
                  },
                  fontWeight: "600",
                },
              }}
              page={page}
              onChange={handleChangePage}
            />
          )}
        </Grid>
      )}
      {cityNoEvents
        ? renderNoEventsFound()
        : isRefetching | isLoading
        ? renderLoading(true)
        : renderEvents()}
      {numberPages > 1 && (
        <Pagination
          count={numberPages}
          sx={{
            mb: 20,
            display: "flex",
            justifyContent: "center",
            fontWeight: "bolder",
            "& .MuiPaginationItem-root": {
              "&.Mui-selected": { background: "#481e5f", color: "#fff", fontWeight: "bolder" },
              fontWeight: "600",
            },
          }}
          page={page}
          onChange={handleChangePage}
        />
      )}
    </>
  );
};

export default Events;
