import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { Loading } from "../../components/shared/Loading";
import SelectPerPage from "../../components/shared/SelectPerPage";
import { focusColor } from "../../components/shared/textFieldStyle";
import { toastMessageSuccess } from "../../components/shared/toastMessage";
import { ROLES } from "../../constants/variables";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import useDebounce from "../../hooks/useDebouncing";
import usePagination from "../../hooks/usePagination";
import { getUsersAdmins } from "../../services/adminsServices";
import { deleteUser } from "../../services/utilsServices";

const AdminList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [admins, setAdmins] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedPremise, setSelectedPremise] = useState("all");
  const [selectedAdmin, setSelectedAdmin] = useState("");
  const { page, perPage, changePage, changePerPage } = usePagination();
  const [grouping, setGrouping] = useState("all");
  const [organization, setOrganization] = useState("");

  const userInfo = useSelector((state) => state.userInfo);
  const roles = userInfo.roles;
  const isDelegate = roles.find((element) => element === ROLES.DELEGATE);
  const isSuperAdmin = roles.find((element) => element === ROLES.SUPER_ADMIN);
  const isAdmin = roles.find((element) => element === ROLES.ADMIN);
  const isManager = roles.find((element) => element === ROLES.MANAGER_ADMIN);

  const breadcrumbs = [
    {
      name: t("ADMINISTRATOR_MANAGER"),
      link: "/admins",
    },
  ];

  useEffect(() => {
    if (userInfo.grouping_id) {
      setGrouping(userInfo.grouping_id);
    } else if (userInfo.organization_id) {
      setOrganization(userInfo.organization_id);
    }
  }, []);

  //Sedes
  const { data: premises } = useQueryPremisesList(organization, grouping);

  const handleQuerySuccess = (data) => {
    setAdmins(data.data);
  };

  const {
    isLoading,
    isRefetching,
    refetch: refetchAdmins,
    data: adminsData,
  } = useQuery(
    ["admin", page, perPage, search, selectedPremise],
    () => getUsersAdmins(page, perPage, search, selectedPremise),
    {
      onSuccess: handleQuerySuccess,
      refetchOnWindowFocus: false,
    }
  );

  const handleClickOpenDelete = (e, id) => {
    setSelectedAdmin(id);
    e.stopPropagation();
    setOpenDelete(true);
  };
  const handleCloseDelete = (e) => {
    e.stopPropagation();
    setOpenDelete(false);
  };
  const handleDeleteButtonClick = async () => {
    await deleteUser(selectedAdmin);
    toastMessageSuccess(t("ADMIN_DELETED"));
    setOpenDelete(false);
    refetchAdmins();
  };
  const getTranslatedRoles = (rol) => {
    switch (rol) {
      case "admin":
        return t("ADMIN");
      case "affiliated":
        return t("ADMIN_AFFILIATED");
      default:
        return rol;
    }
  };
  const handleEventClick = (id) => {
    navigate(`/admins/${id}`);
  };

  const handlePremiseChange = (event) => {
    if (event && event.target) {
      setSelectedPremise(event.target.value);
      changePage(null, 1);
    }
  };

  const handleSearchChange = (value) => {
    setSearch(value);
    changePage(null, 1);
  };

  const debounceSearch = useDebounce(handleSearchChange, 300);

  const totalPages = Math.ceil(Number(adminsData?.total ?? 0) / Number(adminsData?.perPage ?? 10));

  return (
    <>
      <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_ADMINS")} />
      <Grid
        container
        spacing={1}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
      >
        <Grid item xs={11} sm={7} md={6} sx={{ mb: 2, display: "flex", flexDirection: "row" }}>
          <SearchIcon sx={{ height: 35, width: 35, mr: 1, color: "var(--secondary-color)" }} />
          <TextField
            label={t("SEARCH")}
            onChange={(event) => debounceSearch(event?.target?.value)}
            size="small"
            sx={[{ width: "75%" }, focusColor]}
          />
        </Grid>
        <Grid
          item
          container
          xs={11}
          sm={11}
          md={6}
          justifyContent={{ xs: "flex-start", md: "flex-end" }}
          mb={1}
        >
          <FormControl sx={[focusColor, { minWidth: 120, mr: 2 }]} size="small">
            <Select
              value={selectedPremise}
              onChange={handlePremiseChange}
              sx={{ fontSize: "12px", borderRadius: 3 }}
            >
              <MenuItem value="all">{t("ALL_SITES")}</MenuItem>
              {premises?.map((premise) => (
                <MenuItem key={premise.id} value={premise.id}>
                  {premise.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!isDelegate && (
            <Button
              type="submit"
              onClick={() => navigate("/admins/create_admin")}
              className="oniria-btn"
              variant="contained"
              sx={{ fontSize: "12px", borderRadius: 3, height: "fit-content" }}
            >
              {t("CREATE_ADMIN")}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2, mt: 2 }} container justifyContent="center">
        <Grid item xs={12} md={4} container alignItems="center">
          <Typography>
            {t("TOTAL_ADMINS")}: {adminsData?.total ?? 0}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} container justifyContent="flex-end">
          <Grid item>
            <SelectPerPage text={t("USERS")} change={changePerPage} value={perPage} />
          </Grid>
          <Pagination
            count={totalPages}
            page={page}
            onChange={changePage}
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bolder",
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": {
                  background: "#3D3B38",
                  color: "#fff",
                  fontWeight: "bolder",
                },
                fontWeight: "600",
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} mb={25}>
        {isLoading || isRefetching ? (
          <Loading />
        ) : adminsData && adminsData.data.length > 0 ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                    <TableCell>{t("ROL")}</TableCell>
                    <TableCell>{t("NAME")}</TableCell>
                    <TableCell>{t("SURNAME")}</TableCell>
                    <TableCell>{t("EMAIL")}</TableCell>
                    <TableCell>{t("SITE")}</TableCell>
                    <TableCell>{t("DELETE")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {admins?.map((admin, index) => (
                    <TableRow
                      key={index}
                      onClick={() => handleEventClick(admin.user_id)}
                      sx={{
                        cursor: "pointer",
                        background: admin?.is_active === 0 && "#a2a2a234",
                      }}
                    >
                      <TableCell>
                        {admin.roles?.map((rol, index) => (
                          <Typography key={index}>{getTranslatedRoles(rol)}</Typography>
                        ))}
                      </TableCell>
                      <TableCell>{admin.user_name}</TableCell>
                      <TableCell>{admin.user_surname}</TableCell>
                      <TableCell>{admin.user_email}</TableCell>
                      <TableCell>{admin.premise_name}</TableCell>
                      <TableCell>
                        {admin?.is_active === 0 ? (
                          <Typography sx={{ color: "var(--oniria-red)", fontWeight: 500 }}>
                            {t("INACTIVE")}
                          </Typography>
                        ) : isSuperAdmin ||
                          isManager ||
                          (isAdmin && !admin.roles.includes("admin")) ? (
                          <IconButton
                            aria-label="delete"
                            className="oniria-btn"
                            onClick={(e) => handleClickOpenDelete(e, admin.user_id)}
                          >
                            <DeleteIcon sx={{ color: "white" }} />
                          </IconButton>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid mt={3}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={changePage}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bolder",
                  "& .MuiPaginationItem-root": {
                    "&.Mui-selected": {
                      background: "#3D3B38",
                      color: "#fff",
                      fontWeight: "bolder",
                    },
                    fontWeight: "600",
                  },
                }}
              />
            </Grid>
          </>
        ) : (
          <Typography sx={{ textAlign: "center", fontWeight: "bolder", fontSize: "20px" }}>
            {t("NO_ADMINS")}
          </Typography>
        )}
      </Grid>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ fontWeight: "bolder", color: "var(--oniria-red)" }}>
          {t("DENY_DELEGATE")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "black" }}>
            {t("CONFIRM_DENY_DELEGATE")}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
        >
          <Button
            type="submit"
            className="oniria-btn-cancel"
            variant="contained"
            size="medium"
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={handleCloseDelete}
          >
            {t("CANCEL")}
          </Button>
          <Button
            type="submit"
            className="oniria-btn"
            variant="contained"
            size="medium"
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={() => handleDeleteButtonClick()}
          >
            {t("ACCEPT")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminList;
