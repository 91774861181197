import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import MemberCardForm from "../../components/premises/membersCard.jsx/MemberCardForm";
import { Loading } from "../../components/shared/Loading";
import { ROUTES } from "../../constants/routes";
import { getPremiseById, getPremiseCards } from "../../services/premisesServices";

const MemberCard = () => {
  const { t } = useTranslation();
  const { premiseId } = useParams();
  const [premiseName, setPremiseName] = useState("");

  const { isLoading: isPremiseLoading } = useQuery(
    ["premise", premiseId],
    () => getPremiseById(premiseId),
    {
      onSuccess: (data) => setPremiseName(data[0].name),
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: isCardLoading, data: premiseCard } = useQuery(
    ["cardpremise", premiseId],
    () => getPremiseCards(premiseId),
    {
      refetchOnWindowFocus: false,
      enabled: !!premiseId,
    }
  );

  const breadcrumbs = useMemo(
    () => [
      {
        name: t("VENUES"),
        link: ROUTES.PREMISES,
      },
      {
        name: premiseName || "",
        link: `${ROUTES.PREMISE}/${premiseId}`,
      },
      {
        name: t("MEMBER_CARD"),
        link: `${ROUTES.PREMISE}/${premiseId}/member_card`,
      },
    ],
    [t, premiseName, premiseId]
  );

  if (isCardLoading || isPremiseLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header breadcrumbs={breadcrumbs} />
      {premiseCard && premiseCard.length > 0 ? (
        <MemberCardForm card={premiseCard[0]} isEdit={true} />
      ) : (
        <MemberCardForm />
      )}
    </>
  );
};

export default MemberCard;
