import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import CustomDialog from "../../shared/CustomDialog";
import AddCategoryDialog from "./AddCategoryDialog";

export const Subcategories = ({
  id,
  subcategories,
  handleSetSubcategories,
  handleDeleteSubcategory,
  handleEditSubcategory,
}) => {
  const { t } = useTranslation();
  const [openRemove, setOpenRemove] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [openEditSubcategory, setOpenEditSubcategory] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  const handleOpenDelete = (index) => {
    setOpenRemove(true);
    setSelectedIndex(index);
  };

  const handleConfirmDelete = () => {
    handleDeleteSubcategory(id, selectedIndex);
    setOpenRemove(false);
  };

  const handleOpenEdit = (index) => {
    setName(subcategories[index].name);
    setDescription(subcategories[index].description);
    setImage(subcategories[index].image_url);
    setOpenEditSubcategory(true);
    setSelectedIndex(index);
  };

  const handleConfirmEdit = () => {
    handleEditSubcategory(id, selectedIndex, name, description, image);
    setOpenEditSubcategory(false);
  };

  return (
    <>
      <DragDropContext onDragEnd={handleSetSubcategories}>
        <Typography variant="h7" sx={{ color: "var(--secondary-color)", width: "100%" }}>
          {t("SUBCATEGORIES")}:
        </Typography>
        <Droppable droppableId={id}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ display: "flex", flexDirection: "column", gap: 4, width: "100%" }}
            >
              {subcategories.map((item, index) => (
                <Fragment key={index}>
                  <Draggable draggableId={index + "hola"} index={index}>
                    {(provided, snapshot) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        component={Paper}
                        sx={{
                          backgroundColor: snapshot.isDragging ? "var(--grey-cancelled)" : "",
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          padding: 1,
                          paddingLeft: 2,
                        }}
                      >
                        <Grid container>
                          <Grid item xs={6} container alignItems="center">
                            <DragIndicatorIcon sx={{ marginRight: 2 }} />
                            <ListItemText
                              primary={item.name}
                              sx={{ margin: 0, alignItems: "center", display: "flex" }}
                            />
                          </Grid>
                          <Grid item xs={6} container justifyContent="flex-end">
                            <Tooltip title={t("EDIT_SUBCATEGORY")}>
                              <IconButton
                                onClick={() => handleOpenEdit(index)}
                                sx={{
                                  color: "var(--secondary-color)",
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t("REMOVE_SUBCATEGORY")}>
                              <IconButton
                                onClick={() => handleOpenDelete(index)}
                                sx={{
                                  color: "var(--secondary-color)",
                                }}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )}
                  </Draggable>
                </Fragment>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <CustomDialog
        title={t("DELETE_SUBCATEGORY")}
        isOpen={openRemove}
        onClose={() => setOpenRemove(false)}
        onAccept={handleConfirmDelete}
      />
      <AddCategoryDialog
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        isOpen={openEditSubcategory}
        onClose={() => setOpenEditSubcategory(false)}
        onAccept={handleConfirmEdit}
        title={t("EDIT_SUBCATEGORY")}
        isSubcategory
        image={image}
        setImage={setImage}
      />
    </>
  );
};
