import { Grid, InputLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatMuiDate } from "../../constants/utils";
import InfoToolTip from "../shared/InfoToolTip";
import { focusColor } from "../shared/textFieldStyle";

const DoublePaymentInfo = ({
  isDisabled,
  data,
  checkField,
  checkDateField,
  formParams,
  setFormErrors,
  detailsErrors,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <InputLabel sx={{ fontSize: "18px", color: "black", display: "flex", alignItems: "center" }}>
        <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", display: "flex" }}>
          {t("FIRST_PAYMENT_PERCENT")}
        </div>
        <InfoToolTip text={t("FIRST_PAYMENT_PERCENT_INFO")} />
      </InputLabel>
      <TextField
        onChange={(e) => {
          checkField(
            "details",
            "first_payment_percentage",
            e,
            /^(?:(100(?:\.0+)?|[0-9]{1,2}(?:\.\d+)?)?)$/,
            t("INCORRECT"),
            true,
            formParams,
            setFormErrors
          );
        }}
        type="number"
        margin="normal"
        required
        fullWidth
        id="first_payment_percentage"
        name="first_payment_percentage"
        value={data.first_payment_percentage}
        autoComplete=""
        error={detailsErrors.first_payment_percentage !== ""}
        helperText={
          detailsErrors.first_payment_percentage !== "empty"
            ? detailsErrors.first_payment_percentage
            : ""
        }
        disabled={isDisabled()}
        sx={focusColor}
        inputProps={{ min: 0, max: 100 }}
        placeholder="Ej.-20%"
      />
      {/* END DATE & HOUR*/}
      <Grid container columnSpacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <InputLabel sx={{ fontSize: "18px", color: "black" }}>
            <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", display: "flex" }}>
              {t("END_SECOND_SALE_DATE")}
            </div>
          </InputLabel>
          <TextField
            onChange={(e) => {
              checkDateField(
                "details",
                "second_payment_end_date",
                e,
                /^(\d{4})-(\d{2})-(\d{2})$/,
                t("CHOOSE_OPTION"),
                false,
                formParams,
                setFormErrors
              );
            }}
            type="date"
            margin="normal"
            required
            fullWidth
            id="second_payment_end_date"
            name="second_payment_end_date"
            value={formatMuiDate(data.second_payment_end_date)}
            autoComplete="second_payment_end_date"
            error={detailsErrors.second_payment_end_date !== ""}
            helperText={
              detailsErrors.second_payment_end_date !== "empty"
                ? detailsErrors.second_payment_end_date
                : ""
            }
            disabled={isDisabled()}
            sx={focusColor}
            inputProps={{ min: new Date().toISOString().split("T")[0], max: "2100-12-31" }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel sx={{ fontSize: "18px", color: "black" }}>
            {t("SECOND_PAYMENT_END_TIME")}
          </InputLabel>
          <TextField
            onChange={(e) =>
              checkField(
                "details",
                "second_payment_end_date_time",
                e,
                /^([01]\d|2[0-3]):([0-5]\d)$/,
                t("CHOOSE_OPTION"),
                false,
                formParams,
                setFormErrors
              )
            }
            type="time"
            margin="normal"
            required
            fullWidth
            id="second_payment_end_date_time"
            name="second_payment_end_date_time"
            value={data.second_payment_end_date_time}
            autoComplete=""
            disabled={isDisabled()}
            sx={focusColor}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DoublePaymentInfo;
