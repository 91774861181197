import { Box, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import BillingHome from "../components/home/BillingHome";
import EventHomeCard from "../components/home/EventHomeCard";
import { formatCurrency } from "../constants/utils";
import { ROLES } from "../constants/variables";
import { getHome } from "../services/utilsServices";
import "./styles.css";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [greet, setGreet] = useState("");
  const actualDate = new Date();
  const actualHour = actualDate.getHours();
  const userInfo = useSelector((state) => state.userInfo);
  const allUserData = userInfo.userData;
  const roles = userInfo.roles;
  const isSuperAdmin = roles.find((element) => element === ROLES.SUPER_ADMIN);
  const isManager = roles.find((element) => element === ROLES.MANAGER_ADMIN);
  const isAffiliate = roles.find((element) => element === ROLES.AFFILIATED);
  const isAdmin = roles.find((element) => element === ROLES.ADMIN);
  const [affiliationData, setAffiliationData] = useState([]);
  const [nextEvents, setNextEvents] = useState([]);
  const [statistics, setStatistics] = useState("");
  const breadcrumbs = [
    {
      name: `${greet}, ${allUserData.name} ${allUserData.surname}`,
      link: "/home",
    },
  ];

  useEffect(() => {
    if (actualHour >= 5 && actualHour < 14) {
      setGreet(t("GOOD_MORNING"));
    } else if (actualHour >= 14 && actualHour < 21) {
      setGreet(t("GOOD_AFTERNOON"));
    } else {
      setGreet(t("GOOD_EVENING"));
    }
  }, [actualHour]);
  const handleQuerySuccess = (data) => {
    setAffiliationData(data.affiliation_requests);
    const sortedEvents = data.next_events.sort((a, b) => {
      const dateA = new Date(a.start_date);
      const dateB = new Date(b.start_date);
      return dateA - dateB;
    });
    setNextEvents(sortedEvents);
    setStatistics(data.statistics);
  };
  const { isLoading } = useQuery("home", () => getHome(), {
    onSuccess: handleQuerySuccess,
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress sx={{ color: "var(--secondary-color)" }} />
      </Box>
    );
  }
  return (
    <>
      <Header breadcrumbs={breadcrumbs} />
      <Grid container columns={16}>
        {/* ICONS HEADER */}
        <Grid container item xs={16}>
          <Grid container>
            {(isSuperAdmin || isManager || isAdmin) && (
              <div className="card-item" onClick={() => navigate("/organizations")}>
                <div className="card-title">
                  <div className="card-icon" style={{ background: "#d7fade" }}>
                    <img src="/media/homeIcons/organization.svg" alt="Organizations" />
                  </div>
                  <p>{t("ORGANIZATIONS")}</p>
                </div>
                <div className="card-content">
                  <p>{statistics.num_organizations}</p>
                </div>
              </div>
            )}
            <div className="card-item" onClick={() => navigate("/premises")}>
              <div className="card-title">
                <div className="card-icon" style={{ background: "#d7fade" }}>
                  <img src="/media/homeIcons/premise.svg" alt="Premises" />
                </div>
                <p>{t("PREMISES")}</p>
              </div>
              <div className="card-content">
                <p>{statistics.num_premises}</p>
              </div>
            </div>
            {(isSuperAdmin || isManager) && (
              <div className="card-item">
                <div className="card-title">
                  <div className="card-icon" style={{ background: "#d7fade" }}>
                    <img src="/media/homeIcons/dollar.svg" alt="Commissions" />
                  </div>
                  <p>{t("TOTAL_COMMISSIONS")}</p>
                </div>

                <div className="card-content">
                  <p>{formatCurrency(statistics.total_commissions)}</p>
                </div>
              </div>
            )}
            {(isSuperAdmin || isManager) && (
              <div className="card-item">
                <div className="card-title">
                  <div className="card-icon" style={{ background: "#d8fad7" }}>
                    <img src="/media/homeIcons/dollar.svg" alt="Sales" />
                  </div>
                  <p>{t("TOTAL_SALES")}</p>
                </div>
                <div className="card-content">
                  <p>{formatCurrency(statistics.total_sells)}</p>
                </div>
              </div>
            )}
            {(isSuperAdmin || isAffiliate) && (
              <>
                {/* <div className="card-item" onClick={() => navigate("/users?status=affiliated")}>
                  <div className="card-title">
                    <div className="card-icon" style={{ background: "#d7f9fa" }}>
                      <img src="/media/homeIcons/affiliated.svg" alt="Users" />
                    </div>
                    <p>Total afiliados</p>
                  </div>
                  <div className="card-content">
                    <p>{statistics.num_users_affiliated}</p>
                  </div>
                </div> */}
                <div className="card-item" onClick={() => navigate("/users?status=validated")}>
                  <div className="card-title">
                    <div className="card-icon" style={{ background: "antiquewhite" }}>
                      <img src="/media/homeIcons/users.svg" alt="Validated Users" />
                    </div>
                    <p>{t("TOTAL_USERS")}</p>
                  </div>
                  <div className="card-content">
                    <p>{statistics.num_users_pending_payment}</p>
                  </div>
                </div>
              </>
            )}
          </Grid>
        </Grid>
        {/* FIN ICONS HEADER */}

        {/* PROXIMOS EVENTOS */}
        <Grid item container spacing={3} columns={16}>
          <Grid
            item
            xs={16}
            md={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Paper elevation={2} sx={{ padding: "10px", minHeight: "50vh" }}>
              <Typography
                className="oniria-colorText"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  textAlign: "left",
                  fontSize: { xs: "1rem", md: "1rem" },
                }}
                variant="h5"
                mt={2}
              >
                {t("UPCOMING_EVENTS")}
              </Typography>
              <Grid item sx={{ maxHeight: "calc(60vh - 85px)", overflowY: "auto" }}>
                {nextEvents?.map((event, index) => (
                  <EventHomeCard event={event} key={index} />
                ))}
              </Grid>
              <Button
                onClick={() => navigate("/events")}
                variant="outline"
                color="primary"
                sx={{ color: "#dda448" }}
              >
                {t("VIEW_MORE")}
              </Button>
            </Paper>
          </Grid>
          <Grid
            item
            xs={16}
            md={8}
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Paper elevation={2} sx={{ padding: "10px" }}>
              <Typography
                className="oniria-colorText"
                sx={{
                  fontWeight: "bold",
                  mb: 1,
                  fontSize: { xs: "1rem", md: "1rem" },
                }}
                variant="h5"
                mt={2}
              >
                {t("LAST_ORDERS")}
              </Typography>
              <Grid item sx={{ maxHeight: "calc(60vh - 85px)", overflowY: "auto" }}>
                <BillingHome />
              </Grid>
              <Button
                onClick={() => navigate("/sales")}
                variant="outline"
                color="primary"
                sx={{ color: "#dda448" }}
              >
                {t("VIEW_MORE")}
              </Button>
            </Paper>
          </Grid>
        </Grid>
        {/* FIN PROXIMOS EVENTOS */}
      </Grid>
    </>
  );
};

export default Home;
